import { Store } from 'set-state-is-great';
import { AppState } from '../types/state_types';
import router from './router';
import { getInstallActionModalState } from '../install_helpers';
import Paycheck from '../models/Paycheck';
import BonusBuck from '../models/BonusBuck';
import { getTicketResolutionModalState } from '../helpers/ticket_helpers';
import { getProfileFormState, getFullProfileFormState } from '../helpers/profile_helpers';
import { getDropoffModalState } from '../helpers/dropoff_helpers';
import { getBlankPayscaleModalState } from '../helpers/payscale_helpers';
import FieldNote from '../models/FieldNote';
import { getBarcodeScanningModalState } from '../helpers/barcode_helpers';
import { getFridgeCleaningModalState } from '../helpers/fridge_cleaning_helpers';

const appState: AppState = {
  top: {
    colormode: localStorage.getItem('light_mode') ? 'light' : 'dark',
  },
  main: {
    currentUserId: undefined,
    viewShown: undefined,
    isOnline: navigator.onLine,
  },
  app_bar: {
    title: null,
    subtitle: null,
    subsubtitle: null,
    drill_href: null,
  },
  home: {
    msg: 'Warld',
  },
  drawer: {
    open: false,
  },
  install_action_modal: {
    open: false,
    install: null,
    working: false,
  },
  install_actions: getInstallActionModalState(),
  signupForm: {
    serverErrors: [],
    alertOpen: false,
  },
  job_detail: {
    room: null,
    locked: null,
    occupied: false,
  },
  building_detail: {
    building: null,
  },
  stats_page: {
    tab: 0,
  },
  user_work_page: {
    tab: 0,
    earned_tab: 0,
    user: null,
    date: null,
  },
  ticket_modal: {
    id: null,
    open: false,
    working: false,
    text: '',
  },
  field_note_modal: FieldNote.getModalState(null, false),
  filter_modal: {
    open: false,
    school_id: null,
    building_ids: [],
    product_ids: [],
    statuses: [],
    only_show_available_jobs: false,
  },
  paycheck_modal: Paycheck.getModalState(null, false, null),
  bonus_buck_modal: BonusBuck.getModalState(null, false),
  bonus_bucks_page: {
    nada: false,
  },
  ticket_resolution_modal: getTicketResolutionModalState(null),
  profile_form: getProfileFormState(null),
  full_profile_form: getFullProfileFormState(null),
  dropoff_modal: getDropoffModalState(null, false),
  dropoff_detail: {
    dropoff: null,
    working: false,
  },
  payscale_modal: getBlankPayscaleModalState(),
  payscale_detail: {
    payscale: null,
  },
  export: {
    shorts: [],
    fields: [],
    school_id: null,
    export_type: 'table',
  },
  barcode_scanning_modal: getBarcodeScanningModalState(false),
  fridge_cleaning_modal: getFridgeCleaningModalState(null, false, null),
  fridge_cleaning_detail: {
    fridge_cleaning: null,
    working: false,
  },
  fridge_cleaning_group_detail: {
    fridge_cleaning: null,
  },
  workers_page: {
    worker_tab: 0,
  },
};

const store = new Store<AppState>(appState);

export { store, router };
