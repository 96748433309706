import Install from './models/Install';
import { InstallActionsState } from './types/state_types';
import sortBy from 'lodash/sortBy';
import { shorts } from './constants';
import { InstallStatus } from './types/types';

export const install_statuses: InstallStatus[] = ['pending_removal', 'uninstalled', 'removed', 'set_missing'];

export const getInstallActionModalState = (install?: Install): InstallActionsState => {
  return {
    uninstalled_by_id: install?.uninstalled_action?.actor_id || null,
    uninstalled_by_splitter_ids: install?.uninstalled_action?.splitter_ids || [],
    removed_by_id: install?.removed_action?.actor_id || null,
    removed_by_splitter_ids: install?.removed_action?.splitter_ids || [],
    set_missing_by_id: install?.set_missing_action?.actor_id || null,
  };
};

export const sortInstallsByShort = (installs: Install[]) => {
  return sortBy(installs, i => {
    return shorts.indexOf(i.product.short);
  });
};

export const sortStatuses = (statuses: InstallStatus[]) => {
  return sortBy(statuses, s => {
    return install_statuses.indexOf(s);
  });
};
