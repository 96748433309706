import React from 'react';
import Button from '@material-ui/core/Button';
import { store, getModels } from '../../constants';
import { FieldNoteJSON } from '../../types/json_types';
import FieldNote from '../../models/FieldNote';
import { findModelById } from '../../app_util';

const { getState, setState } = store.getHelpers('field_note_modal');

function clickSave(id: number, working: boolean) {
  const { note, image_file_name } = getState();
  if (working) {
    return;
  }

  const room = store.getState('job_detail').room;

  if (!room) {
    throw new Error('no room, wtf');
  }

  const formData = new FormData();

  const fileInput = document.querySelector('input#image') as HTMLInputElement;
  if (fileInput?.files?.length) {
    formData.append('image', fileInput.files[0]);
  }

  formData.append('note', note);
  formData.append('image_file_name', image_file_name || 'null');

  setState({ working: true });
  fetch(`/api/field_notes/${id}`, {
    method: 'PUT',
    body: formData,
  })
    .then(response => response.json())
    .then((json: unknown) => {
      const allFieldNotes = getModels('field_notes');
      const field_note = findModelById(allFieldNotes, id);

      const nextFieldNote = new FieldNote(json as FieldNoteJSON);

      const index = allFieldNotes.indexOf(field_note);
      allFieldNotes[index] = nextFieldNote;

      store.forceUpdate('main');
      setState(FieldNote.getModalState(null, false));
    })
    .catch(() => {
      setState({ working: false });
    });
}

interface FieldNoteSaveButtonProps {
  id: number;
  working: boolean;
}

const FieldNoteSaveButton = (props: FieldNoteSaveButtonProps) => {
  const { id, working } = props;
  return (
    <Button onClick={() => clickSave(id, working)} variant="outlined" size="large" disabled={working}>
      Save
    </Button>
  );
};

export default FieldNoteSaveButton;
