import React from 'react';
import Button from '@material-ui/core/Button';
import { store } from '../../constants';
import { getImageUrl } from '../../helpers/paperclip_helpers';
import Box from '@material-ui/core/Box';

const { setState } = store.getHelpers('field_note_modal');

type FieldNoteModalPhotoRowProps = {
  image_file_name: string;
  id: number;
  working: boolean;
};

const FieldNoteModalPhotoRow = (props: FieldNoteModalPhotoRowProps) => {
  const { working, id, image_file_name } = props;
  const thumb = getImageUrl(id, 'field_notes', 'thumb', image_file_name);

  return (
    <Box display="flex" className="mt-3 mb-1">
      <img alt="" src={thumb} width="50" style={{ marginRight: 10 }} />
      <Button size="small" onClick={() => setState({ image_file_name: null })} variant="outlined" disabled={working}>
        Clear photo
      </Button>
    </Box>
  );
};

export default FieldNoteModalPhotoRow;
