import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { FridgeStatus, fridge_statuses } from '../../models/FridgeCleaning';

const renderOption = (status: FridgeStatus) => {
  const key = `fridge_status_${status}_option`;

  return (
    <option value={status} key={key}>
      {status.replace(/_/g, ' ')}
    </option>
  );
};

type FridgeStatusSelectProps = {
  onChange: (status: FridgeStatus) => void;
  status: FridgeStatus;
};

let cnt = 0;

const FridgeStatusSelect = (props: FridgeStatusSelectProps) => {
  const id = `fridge-status-select-null-${cnt++}`;

  const handleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const value = e.target.value as string;
    const status = value as FridgeStatus;

    props.onChange(status);
  };

  const { status } = props;

  return (
    <FormControl variant="outlined">
      <InputLabel htmlFor={id}>Item Status</InputLabel>
      <Select id={id} native value={status} onChange={handleChange}>
        {fridge_statuses.map(renderOption)}
      </Select>
    </FormControl>
  );
};

export default FridgeStatusSelect;
