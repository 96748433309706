import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { store, getModels } from '../../constants';
import { findModelById } from '../../app_util';
import orange from '@material-ui/core/colors/orange';
import blue from '@material-ui/core/colors/blue';
import purple from '@material-ui/core/colors/purple';
import Divider from '@material-ui/core/Divider';
import UserSelect from '../UserSelect';
import MultiUserSelect from '../MultiUserSelect';
import { andJoin } from '../../util';
import Install from '../../models/Install';
import User from '../../models/User';

const { getState, setState, useStoreState } = store.getHelpers('install_actions');

const OrangeCheckbox = withStyles({
  root: {
    color: orange[400],
    '&$checked': {
      color: orange[600],
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const BlueCheckbox = withStyles({
  root: {
    color: blue[400],
    '&$checked': {
      color: blue[600],
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const PurpleCheckbox = withStyles({
  root: {
    color: purple[400],
    '&$checked': {
      color: purple[600],
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const handleUninstalledChanged = () => {
  const nextUninstalled = !getState().uninstalled_by_id;
  if (nextUninstalled) {
    setState({
      uninstalled_by_id: window.currentUser.id,
      uninstalled_by_splitter_ids: [],
    });
  } else {
    setState({
      uninstalled_by_id: null,
      uninstalled_by_splitter_ids: [],
    });
  }
};

const handleRemovedChanged = () => {
  const nextRemoved = !getState().removed_by_id;

  if (nextRemoved) {
    setState({
      removed_by_id: window.currentUser.id,
      removed_by_splitter_ids: [],
    });
  } else {
    setState({
      removed_by_id: null,
      removed_by_splitter_ids: [],
      set_missing_by_id: null,
    });
  }
};

const handleMissingChanged = () => {
  const nextSetMissing = !getState().set_missing_by_id;

  if (nextSetMissing) {
    setState({
      uninstalled_by_id: null,
      uninstalled_by_splitter_ids: [],
      removed_by_id: null,
      removed_by_splitter_ids: [],
      set_missing_by_id: window.currentUser.id,
    });
  } else {
    setState({
      set_missing_by_id: null,
    });
  }
};

const getLabel = (user_id: number | null, label: string) => {
  if (user_id) {
    const user = findModelById(getModels('users'), user_id);
    return `${label} by ${user.username}`;
  } else {
    return label;
  }
};

const onChangeUninstaller = (user_id: number | null) => {
  setState({ uninstalled_by_id: user_id });
};

const onChangeUninstallerSplits = (user_ids: number[]) => {
  setState({ uninstalled_by_splitter_ids: user_ids });
};

const onChangeRemover = (user_id: number | null) => {
  setState({ removed_by_id: user_id });
};

const onChangeRemoverSplits = (user_ids: number[]) => {
  setState({ removed_by_splitter_ids: user_ids });
};

const onChangeWhoSetMissing = (user_id: number | null) => {
  setState({ set_missing_by_id: user_id });
};

const checkboxEnabled = (user: User, creator_id?: number) => {
  return user.admin || !creator_id || creator_id === user.id;
};

interface InstallActionsModalSectionProps {
  install: Install;
}

const InstallActionsModalSection = (props: InstallActionsModalSectionProps) => {
  const state = useStoreState();

  const uninstalledLabel = getLabel(state.uninstalled_by_id, 'Uninstalled');
  const removedLabel = getLabel(state.removed_by_id, 'Removed');
  const missingLabel = getLabel(state.set_missing_by_id, 'Set item as missing');

  const allUsers = getModels('users');
  const uninstalled_usernames = andJoin(
    state.uninstalled_by_splitter_ids.map(id => {
      return findModelById(allUsers, id).username;
    }),
  );

  const removed_usernames = andJoin(
    state.removed_by_splitter_ids.map(id => {
      return findModelById(allUsers, id).username;
    }),
  );

  const currentUser = window.currentUser;

  const install = props.install;

  const uninstalledEnabled = checkboxEnabled(currentUser, install.uninstalled_action?.creator_id);
  const removedEnabled = checkboxEnabled(currentUser, install.removed_action?.creator_id);

  return (
    <div>
      <div className="d-flex">
        <FormControlLabel
          disabled={!uninstalledEnabled}
          control={
            <OrangeCheckbox
              checked={!!state.uninstalled_by_id}
              onChange={handleUninstalledChanged}
              value="Uninstalled"
            />
          }
          label={uninstalledLabel}
        />
        {uninstalled_usernames.length > 0 && <div style={{ maxWidth: '50%' }}>+ {uninstalled_usernames}</div>}
      </div>

      {state.uninstalled_by_id && (
        <div className="d-flex justify-content-between mb-4">
          {currentUser.admin && (
            <UserSelect user_id={state.uninstalled_by_id} label="Change uninstaller" onChange={onChangeUninstaller} />
          )}
          {uninstalledEnabled && (
            <MultiUserSelect
              user_ids={state.uninstalled_by_splitter_ids}
              label="Split task with"
              onChange={onChangeUninstallerSplits}
            />
          )}
        </div>
      )}

      <div className="d-flex">
        <FormControlLabel
          disabled={!removedEnabled}
          control={<BlueCheckbox checked={!!state.removed_by_id} onChange={handleRemovedChanged} value="Removed" />}
          label={removedLabel}
        />
        {removed_usernames.length > 0 && <div style={{ maxWidth: '50%' }}>+ {removed_usernames}</div>}
      </div>

      {state.removed_by_id && (
        <div className="d-flex justify-content-between mb-4">
          {currentUser.admin && (
            <UserSelect user_id={state.removed_by_id} label="Change remover" onChange={onChangeRemover} />
          )}
          {removedEnabled && (
            <MultiUserSelect
              user_ids={state.removed_by_splitter_ids}
              label="Split task with"
              onChange={onChangeRemoverSplits}
            />
          )}
        </div>
      )}

      <Divider className="my-2" />

      <div>
        <FormControlLabel
          control={
            <PurpleCheckbox checked={!!state.set_missing_by_id} onChange={handleMissingChanged} value="missing" />
          }
          label={missingLabel}
        />
      </div>

      {currentUser.admin && state.set_missing_by_id && (
        <div className="d-flex justify-content-between mb-4">
          <UserSelect
            user_id={state.set_missing_by_id}
            label="Change who set missing"
            onChange={onChangeWhoSetMissing}
          />
        </div>
      )}
    </div>
  );
};

export default InstallActionsModalSection;
