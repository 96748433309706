import { TableName } from '../types/json_types';

export type Environment = 'production' | 'development';
const host = window.location.host;
export const getEnvironment = (): Environment | null => {
  if (host === 'dd3.dormsdirect.com') {
    return 'production';
  } else if (/^localhost/.test(host)) {
    return 'development';
  }

  return null;
};

const env = getEnvironment();

const bucket = env === 'production' ? 'dd-paperclip-prod' : 'dd-paperclip-dev';

// https://stackoverflow.com/a/1772978/548170
function chunk(str: string, n: number, join = '/'): string {
  const ret: string[] = [];
  let i: number;
  let len: number;

  for (i = 0, len = str.length; i < len; i += n) {
    ret.push(str.substr(i, n));
  }

  return ret.join(join);
}

const getS3Id = (id: number): string => {
  return chunk(id.toString().padStart(9, '0'), 3);
};

type ImageSize = 'medium' | 'original' | 'thumb';

export const getImageUrl = (id: number, table: TableName, size: ImageSize, filename: string) => {
  const s3_id = getS3Id(id);

  return `
    https://${bucket}.s3.amazonaws.com/${table}/images/${s3_id}/${size}/${filename}
  `;
};
