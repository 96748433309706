import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { FullProfileFormState } from '../../types/state_types';
import { store, getModels } from '../../constants';
import ApiRequest, { ApiRequestCallbacks } from '../../ApiRequest';
import { isErrorResponse, UserJSON } from '../../types/json_types';
import User from '../../models/User';
import ErrorAlert from '../ErrorAlert';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { findModelById } from '../../app_util';

const { getState, setState, useStoreState } = store.getHelpers('full_profile_form');

const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
  event.preventDefault();

  const state = getState();

  if (state.working) {
    return;
  }

  setState({ working: true });

  const callbacks: ApiRequestCallbacks = {
    onSuccess: (resp: unknown) => {
      const user_json = resp as UserJSON;
      const heldUser = new User(user_json);
      const user = findModelById(getModels('users'), user_json.id);

      Object.assign(user, heldUser);

      store.forceUpdateEverything();
    },
    onError: json => {
      if (isErrorResponse(json)) {
        setState({ errors: json.errors });
      } else {
        alert('an error occurred');
      }
    },
    onDone: () => {
      setState({ working: false });
    },
  };

  const data = {
    user_id: state.user_id,
    profile: state,
  };

  const request = new ApiRequest(`/api/full_profile_update.json`, 'PATCH', callbacks, data);
  request.perform();

  console.log('onSubmit');
};

const onChange = <Key extends keyof FullProfileFormState>(attr: Key, value: FullProfileFormState[Key]) => {
  setState({ [attr]: value });
};

const onCloseErrorAlert = () => {
  setState({ errors: [] });
};

const renderSsn = (admin: boolean, isUser: boolean, ssn: string) => {
  if (admin || isUser) {
    return (
      <TextField
        value={ssn}
        onChange={e => onChange('ssn', e.target.value)}
        label="SSN"
        variant="outlined"
        className="mt-2"
      />
    );
  }

  return null;
};

const FullProfileForm = () => {
  const state = useStoreState();

  const currentUser = window.currentUser;

  const admin = currentUser.admin;
  const isUser = currentUser.id === state.user_id;

  return (
    <div>
      <ErrorAlert errors={state.errors} closeAlert={onCloseErrorAlert} />
      <form onSubmit={onSubmit}>
        <Box display="flex" flexDirection="column">
          <TextField
            value={state.email}
            onChange={e => onChange('email', e.target.value)}
            label="Email"
            variant="outlined"
            disabled
            className="mt-2"
          />
          <TextField
            value={state.first_name || ''}
            onChange={e => onChange('first_name', e.target.value)}
            label="First name"
            variant="outlined"
            className="mt-2"
          />
          <TextField
            value={state.last_name || ''}
            onChange={e => onChange('last_name', e.target.value)}
            label="Last name"
            variant="outlined"
            className="mt-2"
          />
          <TextField
            value={state.username || ''}
            onChange={e => onChange('username', e.target.value)}
            label="Username"
            variant="outlined"
            className="mt-2"
          />
          <TextField
            value={state.phone || ''}
            onChange={e => onChange('phone', e.target.value)}
            label="Phone number"
            variant="outlined"
            className="mt-2"
          />
          <TextField
            value={state.address1 || ''}
            onChange={e => onChange('address1', e.target.value)}
            label="Street Address"
            variant="outlined"
            className="mt-2"
          />
          <TextField
            value={state.city || ''}
            onChange={e => onChange('city', e.target.value)}
            label="City"
            variant="outlined"
            className="mt-2"
          />

          {renderSsn(admin, isUser, state.ssn || '')}

          {admin && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.active}
                  onChange={e => setState({ active: e.target.checked })}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Active"
            />
          )}

          <TextField
            value={state.state || ''}
            onChange={e => onChange('state', e.target.value)}
            label="State"
            variant="outlined"
            className="mt-2"
          />
          <Button
            disabled={state.working}
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            className="mt-2"
            disableElevation
          >
            Save
          </Button>
        </Box>
      </form>
    </div>
  );
};

export default FullProfileForm;
