import React from 'react';
import Button from '@material-ui/core/Button';
import { store } from '../../constants';
import { getImageUrl } from '../../helpers/paperclip_helpers';
import Box from '@material-ui/core/Box';

const { setState } = store.getHelpers('fridge_cleaning_modal');

type FridgeCleaningModalPhotoRowProps = {
  image_file_name: string | null;
  id: number | null;
  working: boolean;
};

const FridgeCleaningModalPhotoRow = (props: FridgeCleaningModalPhotoRowProps) => {
  const { working, id, image_file_name } = props;

  if (image_file_name && id) {
    const thumb = getImageUrl(id, 'fridge_cleanings', 'thumb', image_file_name);

    return (
      <Box display="flex" className="mt-3 mb-1">
        <img alt="" src={thumb} width="50" style={{ marginRight: 10 }} />
        <Button onClick={() => setState({ image_file_name: null })} size="small" variant="outlined" disabled={working}>
          Clear photo
        </Button>
      </Box>
    );
  } else {
    return (
      <div style={{ position: 'relative' }} className="mt-3">
        <label
          htmlFor="fc_image"
          style={{ position: 'absolute', left: 0, top: -3 }}
          className="file-cover-btn MuiButton-root MuiButton-outlined MuiButtonBase-root MuiButton-outlinedSizeSmall"
        >
          Attach a photo
        </label>

        <input
          type="file"
          id="fc_image"
          name="fc_image"
          accept="image/png, image/jpeg"
          style={{ marginLeft: 60, outline: 'none' }}
        />
      </div>
    );
  }
};

export default FridgeCleaningModalPhotoRow;
