import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { FridgeProduct, product_types } from '../../models/FridgeCleaning';

const renderOption = (product_type: FridgeProduct) => {
  const key = `fridge_pt_${product_type}_option`;

  return (
    <option value={product_type} key={key}>
      {product_type}
    </option>
  );
};

type FridgeProductSelectProps = {
  onChange: (product_type: FridgeProduct) => void;
  product_type: FridgeProduct;
};

let cnt = 0;

const FridgeCleaningProductSelect = (props: FridgeProductSelectProps) => {
  const id = `fridge-status-select-null-${cnt++}`;

  const handleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const value = e.target.value as string;
    const status = value as FridgeProduct;

    props.onChange(status);
  };

  const { product_type } = props;

  return (
    <FormControl variant="outlined">
      <InputLabel htmlFor={id}>Item Category</InputLabel>
      <Select id={id} native value={product_type} onChange={handleChange}>
        {product_types.map(renderOption)}
      </Select>
    </FormControl>
  );
};

export default FridgeCleaningProductSelect;
