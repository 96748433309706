import Dropoff from '../models/Dropoff';
import { DropoffModalState } from '../types/state_types';
import { store } from '../constants';

export const getDropoffModalState = (dropoff: Dropoff | null, open: boolean): DropoffModalState => {
  return {
    open: open,
    working: false,
    errors: [],

    id: dropoff?.id || null,
    bl_count: dropoff?.bl_count || 0,
    mf_count: dropoff?.mf_count || 0,
    sr_count: dropoff?.sr_count || 0,
    ft_count: dropoff?.ft_count || 0,
    dft_count: dropoff?.dft_count || 0,
    bs_count: dropoff?.bs_count || 0,
    es_count: dropoff?.es_count || 0,
    tv32_count: dropoff?.tv32_count || 0,
    tv40_count: dropoff?.tv40_count || 0,
    notes: dropoff?.notes || '',
    building_ids: dropoff?.building_ids || [],
    worker_ids: dropoff?.worker_ids || [],
    driver_id: dropoff?.driver_id || null,
    warehouse_id: dropoff?.warehouse_id || null,
    type: dropoff?.type || 'dropoff',
    performed_at: dropoff?.performed_at || new Date(),
  };
};

export const setDropoffModalState = (dropoff: Dropoff | null, open: boolean) => {
  const state = getDropoffModalState(dropoff, open);
  store.setState('dropoff_modal', state);
};
