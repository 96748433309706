import { store, getModels, router } from '../constants';
import FridgeCleaning from '../models/FridgeCleaning';
import { getFridgeCleaningModalState } from '../helpers/fridge_cleaning_helpers';
import { isErrorResponse, FridgeCleaningRespJSON } from '../types/json_types';
import { findModelById } from '../app_util';

const { getState, setState } = store.getHelpers('fridge_cleaning_modal');

export const createFridgeCleaning = () => {
  const state = getState();

  if (state.working) {
    return;
  }

  const formData = new FormData();

  const fileInput = document.querySelector('input#fc_image') as HTMLInputElement;
  if (fileInput?.files?.length) {
    formData.append('image', fileInput.files[0]);
  }

  const data = { ...state, errors: [] };
  formData.append('json', JSON.stringify(data));

  setState({ working: true });
  const url = `/api/fridge_cleanings`;

  fetch(url, {
    method: 'POST',
    body: formData,
  })
    .then(response => response.json())
    .then((resp: unknown) => {
      if (isErrorResponse(resp)) {
        setState({ errors: resp.errors, working: false });
      } else {
        const json = resp as FridgeCleaningRespJSON;
        const fridge_cleaning = new FridgeCleaning(json.fridge_cleaning);
        getModels('fridge_cleanings').unshift(fridge_cleaning);
        const appState = store.getFullStore();

        appState.fridge_cleaning_modal = getFridgeCleaningModalState(null, false, null);

        router.go(`/fridge_cleanings/${fridge_cleaning.id}`);
      }
    })
    .catch(() => {
      alert('an error occurred');
      setState({ working: false });
    });
};

export const updateFridgeCleaning = () => {
  const state = getState();
  if (state.working) {
    return;
  }

  const formData = new FormData();

  const fileInput = document.querySelector('input#fc_image') as HTMLInputElement;
  if (fileInput?.files?.length) {
    formData.append('image', fileInput.files[0]);
  }

  const data = { ...state, errors: [] };
  formData.append('json', JSON.stringify(data));

  setState({ working: true });
  const url = `/api/fridge_cleanings/${state.id}`;

  fetch(url, {
    method: 'PUT',
    body: formData,
  })
    .then(response => response.json())
    .then((resp: unknown) => {
      if (isErrorResponse(resp)) {
        setState({ errors: resp.errors, working: false });
      } else {
        const json = resp as FridgeCleaningRespJSON;

        const allFridgeCleanings = getModels('fridge_cleanings');
        const fridge_cleaning = findModelById(allFridgeCleanings, json.fridge_cleaning.id);

        const nextFridgeCleaning = new FridgeCleaning(json.fridge_cleaning);

        Object.assign(fridge_cleaning, nextFridgeCleaning);

        const appState = store.getFullStore();
        appState.fridge_cleaning_modal = getFridgeCleaningModalState(null, false, window.App.filter);

        store.forceUpdate('main');
      }
    })
    .catch(() => {
      alert('an error occurred');
      setState({ working: false });
    });
};
