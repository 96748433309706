import ApplicationModel from './ApplicationModel';
import { InstallJSON } from '../types/json_types';
import { ClassName, InstallStatus, ProductOption } from '../types/types';
import { findModelById } from '../app_util';
import Room from './Room';
import School from './School';
import Building from './Building';
import Product from './Product';
import InstallAction from './InstallAction';
import compact from 'lodash/compact';
import { getModels } from '../constants';

const available_statuses = ['pending_removal', 'uninstalled'];

class Install extends ApplicationModel {
  futon_new: boolean;
  has_mesh: boolean;
  tv_hookup: boolean;
  futon_type: string;
  room_id: number;
  student_id: number;
  product_id: number;
  room: Room;
  building: Building;
  school: School;
  status: InstallStatus;
  product: Product;
  uninstalled_action?: InstallAction;
  removed_action?: InstallAction;
  set_missing_action?: InstallAction;

  constructor(json: InstallJSON) {
    super(json);

    this.room_id = json.room_id;
    this.student_id = json.student_id;
    this.has_mesh = json.has_mesh;
    this.tv_hookup = json.tv_hookup;
    this.futon_type = json.futon_type;
    this.futon_new = json.futon_new;
    this.status = json.status;
    this.product_id = json.product_id;

    this.product = findModelById(getModels('products'), json.product_id);
    this.room = findModelById(getModels('rooms'), json.room_id);
    this.building = this.room.building;
    this.school = this.room.building.school;
  }

  setInstallActions(install_actions: Array<InstallAction>) {
    this.uninstalled_action = install_actions.find(ia => ia.type === 'uninstalled');
    this.removed_action = install_actions.find(ia => ia.type === 'removed');
    this.set_missing_action = install_actions.find(ia => ia.type === 'set_missing');
  }

  getClassName() {
    const className: ClassName = 'Install';
    return className;
  }

  isAvailable() {
    return available_statuses.includes(this.status);
  }

  getInstallActions() {
    return compact([this.uninstalled_action, this.removed_action, this.set_missing_action]);
  }

  getOptions() {
    const arr: Array<ProductOption> = [];
    const short = this.product.short;

    if (short === 'SR') {
      if (this.has_mesh) arr.push('mesh');
    }

    return arr;
  }
}

export default Install;
