import React from 'react';
import { centsToDollarString } from '../../util';

interface TotalsTableProps {
  total: number;
  bonus_buck_total: number;
  room_total: number;
  dropoff_total: number;
  driving_total: number;
  fridge_cleaning_total: number;
}

function TotalsTable(props: TotalsTableProps) {
  const { total, driving_total, dropoff_total, fridge_cleaning_total, room_total, bonus_buck_total } = props;
  return (
    <table className="muhtable mb-2">
      <thead>
        <tr>
          <th>rooms</th>
          {driving_total > 0 && <th>driving</th>}
          {fridge_cleaning_total > 0 && <th>clean/repair</th>}
          <th>truck</th>
          <th>bonus</th>
          <th>total</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{centsToDollarString(room_total)}</td>
          {driving_total > 0 && <td>{centsToDollarString(driving_total)}</td>}
          {fridge_cleaning_total > 0 && <td>{centsToDollarString(fridge_cleaning_total)}</td>}
          <td>{centsToDollarString(dropoff_total)}</td>
          <td>${bonus_buck_total}</td>
          <td>{centsToDollarString(total)}</td>
        </tr>
      </tbody>
    </table>
  );
}

export default React.memo(TotalsTable);
