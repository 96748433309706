import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { fridge_types, FridgeType } from '../../models/FridgeCleaning';

const renderOption = (type: FridgeType) => {
  const key = `fridge_type_${type}_option`;

  return (
    <option value={type} key={key}>
      {type.replace(/_/g, ' ')}
    </option>
  );
};

type FridgeTypeSelectProps = {
  onChange: (type: FridgeType) => void;
  type: FridgeType;
};

let cnt = 0;

const FridgeCleaningTypeSelect = (props: FridgeTypeSelectProps) => {
  const id = `fridge-type-select-${cnt++}`;

  const handleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const value = e.target.value as string;
    const type = value as FridgeType;

    props.onChange(type);
  };

  const { type } = props;

  return (
    <FormControl variant="outlined" id="fridge_product_select_hold">
      <InputLabel htmlFor={id}>Clean or Repair?</InputLabel>
      <Select id={id} native value={type} onChange={handleChange}>
        {fridge_types.map(renderOption)}
      </Select>
    </FormControl>
  );
};

export default FridgeCleaningTypeSelect;
