import React from 'react';
import SchoolStatsTable from './SchoolStatsTable';
import CenterAll from '../CenterAll';
import { getStatsForFilter } from '../../stats_helper';
import SchoolSelectMeh from '../job_list/SchoolSelectMeh';
import Filter from '../../models/Filter';
import Typography from '@material-ui/core/Typography';
import { andJoin } from '../../util';

const renderStatsCriteria = (filter: Filter) => {
  const school = filter.getSchool();
  const buildings = filter.getBuildings();

  return (
    <div className="mt-1 mb-3">
      <Typography variant="h6">Stats filter criteria</Typography>

      <div>
        <b>school: </b> {school.name}
      </div>

      {buildings.length > 0 && (
        <div>
          <div>AND</div>
          <b>buildings: </b>
          {andJoin(
            buildings.map(b => b.name),
            'OR',
          )}
        </div>
      )}
    </div>
  );
};

const FilteredStats = () => {
  const filter = window.App.filter;

  if (filter) {
    const school = filter.getSchool();
    const installs = school.getInstalls();

    const stats = getStatsForFilter(school, filter.building_ids, installs);

    return (
      <div>
        {renderStatsCriteria(filter)}
        {SchoolStatsTable(stats.prods, school)}
      </div>
    );
  } else {
    return (
      <CenterAll className="mt-4">
        <SchoolSelectMeh />
      </CenterAll>
    );
  }
};

export default FilteredStats;
