import React from 'react';
import sortBy from 'lodash/sortBy';
import { router, getModels, store } from '../../constants';
import User from '../../models/User';
import PhoneIcon from '@material-ui/icons/Phone';
import Link from '@material-ui/core/Link';

import { workerName } from '../../helpers/user_helpers';
import { getActiveUsers } from '../../app_util';
import WorkerTabs from './WorkerTabs';
import groupBy from 'lodash/groupBy';

const clickRow = (user: User) => {
  if (window.currentUser.admin) {
    router.go(`/workers/${user.id}`);
  }
};

const renderWorker = (user: User) => {
  const key = 'workuh_row' + user.id;

  return (
    <div
      key={key}
      style={{ height: 50 }}
      onClick={() => {
        clickRow(user);
      }}
      className="pl-2 d-flex align-items-center justify-content-between list-group-item"
    >
      <div className="d-flex">{workerName(user)}</div>

      <Link href={`tel:${user.phone}`} className="mx-3">
        <PhoneIcon />
      </Link>
    </div>
  );
};

const WorkersPage = () => {
  const { worker_tab } = store.useStore('workers_page');
  const currentUser = window.currentUser;

  if (currentUser.admin) {
    const groupedWorkers = groupBy(getModels('users'), u => (u.active ? 'active' : 'inactive'));

    let workers: User[];

    const active_workers_count = groupedWorkers.active.length;
    const inactive_workers_count = groupedWorkers.inactive.length;

    const props = { worker_tab, active_workers_count, inactive_workers_count };

    if (worker_tab === 0) {
      workers = groupedWorkers.active;
    } else {
      workers = groupedWorkers.inactive;
    }

    workers = sortBy(workers, w => w.username.toLowerCase());

    return (
      <div className="fit">
        <WorkerTabs {...props} />
        {workers.map(renderWorker)}
      </div>
    );
  } else {
    const workers = sortBy(getActiveUsers(), w => w.username.toLowerCase());

    return <div className="fit">{workers.map(renderWorker)}</div>;
  }
};

export default WorkersPage;
