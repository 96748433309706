import School from '../../models/School';
import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { getModels } from '../../constants';

const renderOption = (school: School) => {
  const key = `school_${school.id}_option`;

  return (
    <option value={school.id} key={key}>
      {school.name}
    </option>
  );
};

export interface SchoolSelectProps {
  onChange: (school_id: number) => void;
  school_id: number;
}

let cnt = 1;

const SchoolSelect = (props: SchoolSelectProps) => {
  const id = `school-select-${cnt++}`;

  const handleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    props.onChange(parseInt(e.target.value as string));
  };

  return (
    <FormControl variant="outlined">
      <InputLabel htmlFor={id}>Select School</InputLabel>
      <Select id={id} native value={props.school_id} onChange={handleChange}>
        {getModels('schools').map(renderOption)}
      </Select>
    </FormControl>
  );
};

export default React.memo(SchoolSelect);
