import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ApiRequest, { ApiRequestCallbacks } from '../ApiRequest';
import MuiDrawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import BrightnessIcon from '@material-ui/icons/SettingsBrightness';
import PeopleIcon from '@material-ui/icons/People';
import WarningIcon from '@material-ui/icons/Warning';
import HomeIcon from '@material-ui/icons/Home';
import MoneyIcon from '@material-ui/icons/Money';
import FridgeIcon from '@material-ui/icons/Kitchen';
import BuildIcon from '@material-ui/icons/Build';
import BarChartIcon from '@material-ui/icons/BarChart';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PiggyBankIcon from './icons/piggy_bank_icon';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { store, router } from '../constants';
import { toggleDarkmode } from '../app_util';

const { setState, useStoreState } = store.getHelpers('drawer');

const useStyles = makeStyles({
  list: {
    width: 250,
  },
});

const closeDrawer = () => {
  setState({ open: false });
};

const go = (route: string) => () => {
  router.go(route);
};

function logout(e: React.MouseEvent<HTMLElement>) {
  e.preventDefault();

  const callbacks: ApiRequestCallbacks = {
    onSuccess: () => {
      store.setState('main', { currentUserId: null });
    },
    onError: () => {
      alert("couldn't log out: an error occurred");
    },
  };

  const request = new ApiRequest('/users/sign_out.json', 'DELETE', callbacks);
  request.perform();
}

export default function Drawer() {
  const classes = useStyles();

  const { open } = useStoreState();

  const currentUser = window.currentUser;

  return (
    <MuiDrawer open={open} onClose={closeDrawer}>
      <div className={classes.list} role="presentation" onClick={closeDrawer} onKeyDown={closeDrawer}>
        <List>
          <ListItem button key={'home'} onClick={go('/')}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={'Home'} />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button key={'jobs'} onClick={go('/jobs')}>
            <ListItemIcon>
              <BuildIcon />
            </ListItemIcon>
            <ListItemText primary={'Jobs'} />
          </ListItem>
          <ListItem button key={'fridge_cleaning'} onClick={go('/fridge_cleanings')}>
            <ListItemIcon>
              <FridgeIcon />
            </ListItemIcon>
            <ListItemText primary={'Clean/Repair'} />
          </ListItem>
          <ListItem button key={'stats'} onClick={go('/stats')}>
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary={'Stats'} />
          </ListItem>
          <ListItem button key={'piggy_bank'} onClick={go('/your_work')}>
            <ListItemIcon>
              <PiggyBankIcon />
            </ListItemIcon>
            <ListItemText primary={'Piggy Bank'} />
          </ListItem>
          <ListItem button key={'workers'} onClick={go('/workers')}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary={'Workers'} />
          </ListItem>
          <ListItem button key={'bonus_bucks'} onClick={go('/bonus_bucks')}>
            <ListItemIcon>
              <AttachMoneyIcon />
            </ListItemIcon>
            <ListItemText primary={'Bonus Bucks'} />
          </ListItem>
          <ListItem button key={'needs_attention'} onClick={go('/needs_attention_tickets')}>
            <ListItemIcon>
              <WarningIcon />
            </ListItemIcon>
            <ListItemText primary={'Needs Attention'} />
          </ListItem>
          <ListItem button key={'profile'} onClick={go('/profile')}>
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText primary={'Profile'} />
          </ListItem>
          <ListItem button key={'dropoffs'} onClick={go('/dropoffs')}>
            <ListItemIcon>
              <AirportShuttleIcon />
            </ListItemIcon>
            <ListItemText primary={'Truck Loads'} />
          </ListItem>
          <ListItem button key={'payscales'} onClick={go('/payscales')}>
            <ListItemIcon>
              <MoneyIcon />
            </ListItemIcon>
            <ListItemText primary={'Payscales'} />
          </ListItem>
          {currentUser.admin && (
            <ListItem button key={'Export'} onClick={go('/export')}>
              <ListItemIcon>
                <ImportExportIcon />
              </ListItemIcon>
              <ListItemText primary={'Export'} />
            </ListItem>
          )}
        </List>

        <Divider />
        <List>
          <ListItem button key={'darkmode_toggle'} onClick={toggleDarkmode}>
            <ListItemIcon>
              <BrightnessIcon />
            </ListItemIcon>
            <ListItemText primary={'Toggle dark/light mode'} />
          </ListItem>
        </List>
        <Divider />

        <List>
          <ListItem button key="logout" onClick={logout}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </div>
    </MuiDrawer>
  );
}
