import React from 'react';
import { store, getModels } from '../../constants';
import Dropoff from '../../models/Dropoff';
import { isPresent, spliceFromArray, andJoin } from '../../util';
import Button from '@material-ui/core/Button';
import DropoffCountsTable from './DropoffCountsTable';
import Payscale from '../../models/Payscale';
import { setDropoffModalState } from '../../helpers/dropoff_helpers';
import ApiRequest, { ApiRequestCallbacks } from '../../ApiRequest';
import { isErrorResponse } from '../../types/json_types';
import { router } from '../../setup/store';
import strftime from 'strftime';

const { setState, useStoreState } = store.getHelpers('dropoff_detail');

const clickEdit = (dropoff: Dropoff) => {
  setDropoffModalState(dropoff, true);
};

const clickDestroy = (dropoff: Dropoff) => {
  if (window.confirm('are you sure?')) {
    const callbacks: ApiRequestCallbacks = {
      onLoading: () => setState({ working: true }),
      onSuccess: () => {
        const allDropoffs = getModels('dropoffs');
        spliceFromArray(allDropoffs, dropoff);

        router.go('/dropoffs');
      },
      onError: (json: unknown) => {
        if (isErrorResponse(json)) {
          alert(andJoin(json.errors));
        } else {
          alert('some funky error occurred');
        }
      },
      onDone: () => setState({ working: false }),
    };

    const request = new ApiRequest(`/api/dropoffs/${dropoff.id}.json`, 'DELETE', callbacks);
    request.perform();
  }
};

const renderDriver = (dropoff: Dropoff) => {
  if (!dropoff.driver) {
    return null;
  }

  const type = dropoff.type === 'dropoff' || dropoff.type === 'pickup' ? dropoff.type : null;

  if (!type) {
    throw new Error('wrong kinda dropoff yo');
  }

  const payscale = Payscale.find(type, dropoff.driver);

  return (
    <div className="mt-4">
      <div className="fw-500">Driver:</div>
      <DropoffCountsTable dropoff={dropoff} payscale={payscale} />
    </div>
  );
};

const DropoffDetail = () => {
  const { dropoff, working } = useStoreState();

  if (!dropoff) {
    throw new Error('no dropoff dudetown');
  }

  const payscale = Payscale.find('dropoff', dropoff.creator);

  const workers = dropoff.getWorkers();

  const buildings = dropoff.getBuildings();

  const currentUser = window.currentUser;

  const showDriver = currentUser.admin || currentUser.id === dropoff.driver_id;

  const date = strftime('%-m/%-d/%y ~ %l:%M %p', dropoff.performed_at);

  return (
    <div className="p-1 mt-2 mainfit">
      {(currentUser.admin || dropoff.creator_id === currentUser.id) && (
        <div className="my-2 d-flex justify-content-between align-items-center">
          <Button onClick={() => clickEdit(dropoff)} size="small" variant="outlined">
            Edit
          </Button>
          <Button
            onClick={() => (working ? null : clickDestroy(dropoff))}
            size="small"
            variant="outlined"
            disabled={working}
          >
            Destroy
          </Button>
        </div>
      )}

      <div>
        <span className="fw-500 mr-2">Type:</span>
        {dropoff.type}
      </div>

      <div>
        <span className="fw-500 mr-2">Date:</span>
        {date}
      </div>

      <div>
        <span className="fw-500 mr-2">Creator:</span>
        {dropoff.creator.username}
      </div>

      <div>
        <span className="fw-500 mr-2">Driver:</span>
        {dropoff.driver ? dropoff.driver.username : 'none'}
      </div>

      <div>
        <span className="fw-500 mr-2">Warehouse:</span>
        {dropoff.warehouse ? dropoff.warehouse.name : 'none'}
      </div>

      <div>
        <span className="fw-500 mr-2">School:</span>
        {dropoff.school.name}
      </div>

      {buildings.length > 0 && (
        <div>
          <span className="fw-500 mr-2">Buildings:</span>
          {buildings.map(b => b.name).join(', ')}
        </div>
      )}

      {workers.length > 0 && (
        <div>
          <span className="fw-500 mr-2">Workers:</span>
          {workers.map(w => w.username).join(', ')}
        </div>
      )}

      <DropoffCountsTable dropoff={dropoff} payscale={payscale} workers_length={workers.length} />

      {isPresent(dropoff.notes) && (
        <div className="mt-3">
          <span className="fw-500 mr-2">Notes:</span>
          {dropoff.notes}
        </div>
      )}

      {showDriver && renderDriver(dropoff)}
    </div>
  );
};

export default DropoffDetail;
