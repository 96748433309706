import intersection from 'lodash/intersection';

export const doesIntersect = function(arr1: Array<unknown>, arr2: Array<unknown>) {
  return !!intersection(arr1, arr2).length;
};

export const getServer = () => {
  return window.location.host === 'dd3.dormsdirect.com' ? 'https://dd3.dormsdirect.com' : 'http://localhost:3000';
};

export const isPresent = function(str: string | null) {
  return str && typeof str === 'string' && str.trim() !== '';
};

export const spliceFromArray = <T>(arr: Array<T>, item: T) => {
  const index = arr.indexOf(item);

  if (index > -1) {
    arr.splice(index, 1);
  } else {
    throw new Error(`item not in array`);
  }
};

// andJoin(['a', 'b', 'c'])  => 'a, b & c'
export const andJoin = function(arr: string[], delimiter = '&', w_html = false) {
  const len = arr.length;
  if (len === 0) return '';
  if (len === 1) return arr[0];
  if (len === 2) return `${arr[0]} ${delimiter} ${arr[1]}`;

  delimiter = w_html ? `<span class='and-join-delim'>${delimiter}</span>` : delimiter;

  return arr
    .map((a, i) => {
      return i === len - 2 ? `${a} ${delimiter} ` : i < len - 1 ? `${a}, ` : a;
    })
    .join('');
};

export const round = (num: number) => {
  return Math.round(num * 100) / 100;
};

export const parseBoolStr = (str: string) => {
  return str === 'true' ? true : str === 'false' ? false : null;
};

export const centsToDollarString = function(cents: number) {
  const dollars = cents / 100;
  return dollars.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};

export const areSameDay = function(date1: Date, date2: Date) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth()
  );
};

export const pad = (n: number) => {
  return n < 10 ? '0' + n : n;
};

export const getFirstMatch = (match: RegExpMatchArray) => {
  return match ? match[0] : null;
};
