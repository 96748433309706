import React from 'react';
import { getAllStats } from '../../stats_helper';
import SchoolStatsTable from './SchoolStatsTable';

const AllStats = () => {
  const stats = getAllStats();

  return (
    <div className="fit" style={{ top: 100 }}>
      {stats.stats.map(s => SchoolStatsTable(s.prods, s.school))}
    </div>
  );
};

export default AllStats;
