import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { store } from '../../constants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

const changeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
  store.setState('user_work_page', { earned_tab: newValue });
};

interface EarningsTabsProps {
  earned_tab: number;
}

function EarningsTabs(props: EarningsTabsProps) {
  const { earned_tab } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs value={earned_tab} onChange={changeTab} indicatorColor="primary" textColor="primary">
          <Tab label="All-time earnings" />
          <Tab label="Daily earnings" />
        </Tabs>
      </AppBar>
    </div>
  );
}

export default React.memo(EarningsTabs);
