import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { sortProductsByShort } from '../../helpers/product_helpers';
import Product from '../../models/Product';
import { getModels } from '../../constants';

const renderProductOption = (product: Product) => {
  const key = `prod_option_${product.id}`;

  return (
    <option key={key} value={product.id}>
      {product.name} ({product.short})
    </option>
  );
};

export interface ProductsSelectProps {
  product_ids: number[] | null;
  onChange: (product_ids: number[]) => void;
  label: string;
}

const handleChangeMultiple = (
  event: React.ChangeEvent<{ value: unknown }>,
  onChange: (product_ids: number[]) => void,
) => {
  const { options } = event.target as HTMLSelectElement;
  const product_ids: number[] = [];
  for (let i = 0, l = options.length; i < l; i += 1) {
    if (options[i].selected) {
      product_ids.push(parseInt(options[i].value));
    }
  }

  onChange(product_ids);
};

export default function ProductsSelect(props: ProductsSelectProps) {
  const { product_ids, label, onChange } = props;

  const products = sortProductsByShort(getModels('products'));

  return (
    <div>
      <FormControl style={{ minWidth: 120 }}>
        <InputLabel shrink htmlFor="select-multiple-native">
          {label}
        </InputLabel>
        <Select multiple native value={product_ids} onChange={e => handleChangeMultiple(e, onChange)}>
          {products.map(renderProductOption)}
        </Select>
      </FormControl>
    </div>
  );
}
