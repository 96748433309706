import React, { useState } from 'react';
import { Formik } from 'formik';
import { TextField, Button, Typography } from '@material-ui/core';
import BrightnessIcon from '@material-ui/icons/SettingsBrightness';
import Box from '@material-ui/core/Box';
import * as Yup from 'yup';
import { UserJSONResponse } from '../types/json_types';
import { store, router } from '../constants';
import CloseableAlert from './CloseableAlert';
import ApiRequest, { ApiRequestCallbacks } from '../ApiRequest';
import SlackSignInButton from './SlackSigninButton';
import User from '../models/User';
import { toggleDarkmode } from '../app_util';
import GoogleOauthButton from './buttons/google_oauth_button';
import CenterAll from './CenterAll';

const { setState } = store.getHelpers('main');

const clickGoSignup = () => {
  router.go('/signup');
};

const LoginForm: React.FC = () => {
  const [hasServerError, setHasErrors] = useState(false);

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      onSubmit={values => {
        const callbacks: ApiRequestCallbacks = {
          onSuccess: (json: unknown) => {
            const { currentUser } = json as UserJSONResponse;

            window.currentUser = new User(currentUser);
            setState({ currentUserId: currentUser.id });
          },
          onError: () => {
            setHasErrors(true);
          },
        };

        const request = new ApiRequest('/users/sign_in.json', 'POST', callbacks, { user: values });
        request.perform();
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email()
          .required('Required'),
      })}
    >
      {props => {
        const { errors, values, handleSubmit, handleChange } = props;
        return (
          <div className="p-2">
            {hasServerError ? (
              <CloseableAlert
                open={hasServerError}
                closeAlert={() => setHasErrors(false)}
                variant="outlined"
                severity="error"
              >
                Invalid email/password combo
              </CloseableAlert>
            ) : null}

            <Typography variant="h4">Login</Typography>

            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column">
                <TextField
                  value={values.email}
                  onChange={handleChange}
                  name="email"
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  className="mt-2"
                  error={!!errors?.email?.length}
                  helperText={errors.email ? errors.email : null}
                />

                <TextField
                  value={values.password}
                  onChange={handleChange}
                  name="password"
                  label="Password"
                  type="password"
                  variant="outlined"
                  className="mt-2"
                />

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  className="mt-2"
                  disableElevation
                >
                  login
                </Button>
              </Box>
            </form>

            <Box display="flex" flexDirection="column">
              <Typography className="mt-2" variant="h6" style={{ textAlign: 'center' }}>
                OR
              </Typography>

              <Button onClick={clickGoSignup} variant="outlined" color="secondary" size="large" className="mt-2">
                Go to sign up page
              </Button>

              <Typography className="mt-2" variant="h6" style={{ textAlign: 'center' }}>
                OR
              </Typography>

              <CenterAll>
                <GoogleOauthButton />
              </CenterAll>

              <Typography className="mt-2" variant="h6" style={{ textAlign: 'center' }}>
                OR
              </Typography>

              <SlackSignInButton />

              <Typography className="mt-2" variant="h6" style={{ textAlign: 'center' }}>
                OR
              </Typography>

              <Button onClick={toggleDarkmode} variant="outlined" color="secondary" size="large" className="mt-2">
                <BrightnessIcon className="mr-2" />
                Toggle light/dark mode
              </Button>
            </Box>
          </div>
        );
      }}
    </Formik>
  );
};

export default LoginForm;
