import React from 'react';
import '../setup/setup';
import '../detectOnline';
import Main from './Main';
import '../App.scss';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { router, colors, store } from '../constants';
import clsx from 'clsx';

const { useStoreState } = store.getHelpers('top');

const TopLevel = () => {
  const { colormode } = useStoreState();

  const theme = createMuiTheme({
    palette: {
      type: colormode,
      primary: {
        main: colors.primary,
      },
      secondary: {
        main: colors.secondary,
      },
    },
  });

  router.start();

  const classes = clsx({
    darkmode: colormode === 'dark',
    lightmode: colormode === 'light',
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div id="top" className={classes}>
        <Main />
      </div>
    </ThemeProvider>
  );
};

export default TopLevel;
