import Building from './models/Building';
import Room from './models/Room';
import { getModels, shorts, short_shorts } from './constants';
import School from './models/School';
import Install from './models/Install';
import User from './models/User';
import InstallAction from './models/InstallAction';
import Payscale from './models/Payscale';
import { areSameDay } from './util';
import { filterModelsByAttrInclusion, findModelByAttr, filterModelsByAttr } from './app_util';
import flatten from 'lodash/flatten';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';
import sum from 'lodash/sum';
import compact from 'lodash/compact';
import concat from 'lodash/concat';
import groupBy from 'lodash/groupBy';

export interface RoomTotals {
  total: number;
  pending_removal: number;
  uninstalled: number;
  removed: number;
  set_missing: number;
}

export interface InstallStats {
  [short: string]: RoomTotals;
}

export type ShortShortInstallStats = {
  [short_short: string]: RoomTotals;
};

export interface MoneyNumbers {
  total: number;
  uninstalled: number;
  removed: number;
}

export interface ShortShortMoneyNumbers {
  [short_short: string]: MoneyNumbers;
}

export interface MoneyAmounts {
  uninstalled_amount: number;
  removed_amount: number;
  total_amount: number;
}

export type ShortShortMoneyAmounts = {
  [short_short: string]: RoomTotals;
};

export type ShortShortMoneyStats = {
  [short_short: string]: MoneyAmounts;
};

export const getDropoffTotalForUser = (user: User) => {
  const payscale = Payscale.find('dropoff', user);
  return sum(getModels('dropoffs').map(d => (d.loadedTruck(user) ? d.individualCents(payscale) : 0)));
};

export const getDropoffTotalForUserAndDate = (user: User, date: Date) => {
  const payscale = Payscale.find('dropoff', user);
  return sum(
    getModels('dropoffs').map(d =>
      d.loadedTruck(user) && areSameDay(d.performed_at, date) ? d.individualCents(payscale) : 0,
    ),
  );
};

export const getStatsForBuilding = (building: Building, rooms: Room[]) => {
  rooms = rooms.filter(r => r.building.id === building.id);
  const installs = flatten(rooms.map(r => r.installs));

  const stats: InstallStats = {};
  let short, install, room_stats: RoomTotals;

  for (let i = 0; i < installs.length; i++) {
    install = installs[i];
    short = install.product.short;

    if (stats[short]) {
      room_stats = stats[short];
    } else {
      room_stats = {
        total: 0,
        pending_removal: 0,
        uninstalled: 0,
        removed: 0,
        set_missing: 0,
      };
    }
    stats[short] = room_stats;
    stats[short].total += 1;
    stats[short][install.status] += 1;
  }

  return stats;
};

const calc_statuses = ['pending_removal', 'uninstalled', 'removed'];
export const getStatsForSchool = function(school: School, installs: Install[]) {
  const prods: InstallStats = {};
  const stats = {
    school: school,
    prods: prods,
  };

  shorts.forEach(
    s =>
      (prods[s] = {
        total: 0,
        pending_removal: 0,
        uninstalled: 0,
        removed: 0,
        set_missing: 0,
      }),
  );

  installs = installs.filter(i => i.school.id === school.id);

  let install: Install, short: string;

  for (let i = 0; i < installs.length; i++) {
    install = installs[i];

    short = install.product.short;

    if (calc_statuses.includes(install.status)) {
      prods[short].total += 1;
    }

    prods[short][install.status] += 1;
  }

  return stats;
};

export const getStatsForFilter = function(school: School, building_ids: number[], installs: Install[]) {
  const prods: InstallStats = {};
  const stats = {
    school: school,
    prods: prods,
  };

  shorts.forEach(
    s =>
      (prods[s] = {
        total: 0,
        pending_removal: 0,
        uninstalled: 0,
        removed: 0,
        set_missing: 0,
      }),
  );

  installs = installs.filter(i => i.school.id === school.id && building_ids.includes(i.building.id));

  let install: Install, short: string;

  for (let i = 0; i < installs.length; i++) {
    install = installs[i];

    short = install.product.short;

    if (calc_statuses.includes(install.status)) {
      prods[short].total += 1;
    }

    prods[short][install.status] += 1;
  }

  return stats;
};

export const getAllStats = function() {
  const schools = getModels('schools');
  const installs = getModels('installs');

  const stats = schools.map(s => getStatsForSchool(s, installs));
  const all_stats: InstallStats = {};

  shorts.forEach(
    s =>
      (all_stats[s] = {
        total: 0,
        pending_removal: 0,
        uninstalled: 0,
        removed: 0,
        set_missing: 0,
      }),
  );

  stats.forEach(stat => {
    shorts.forEach(short => {
      const counts = stat.prods[short];

      all_stats[short].total += counts.total;
      all_stats[short].pending_removal += counts.pending_removal;
      all_stats[short].uninstalled += counts.uninstalled;
      all_stats[short].removed += counts.removed;
    });
  });

  return {
    all_stats,
    stats,
  };
};

export const actionsForUser = (user: User) => {
  const actions: InstallAction[] = [];

  const install_actions = getModels('install_actions');
  const len = install_actions.length;

  let ia: InstallAction;

  for (let i = 0; i < len; i++) {
    ia = install_actions[i];
    if (ia.touchedByUser(user)) {
      actions.push(ia);
    }
  }

  return actions;
};

export const getStatsForUser = function(user: User) {
  const stats: ShortShortMoneyNumbers = {};

  short_shorts.forEach(s => (stats[s] = { total: 0, uninstalled: 0, removed: 0 }));

  let action: InstallAction, short_short: string, cnt: number;

  const actions = actionsForUser(user);

  for (let i = 0; i < actions.length; i++) {
    action = actions[i];

    if (!action.set) continue;
    if (!action.money_type) continue;

    short_short = action.install.product.short_short;

    cnt = action.individualCount();

    stats[short_short].total += cnt;

    stats[short_short][action.money_type] += cnt;
  }

  return stats;
};

const dayMonth = (date: Date) => {
  return `${date.getMonth() + 1}/${date.getDate()}`;
};

type DateAction = {
  str: string;
  ts: number;
};

export const getDaysWorked = (user: User): DateAction[] => {
  const actionDays: DateAction[] = actionsForUser(user).map(a => ({
    str: dayMonth(a.created_at),
    ts: a.created_at_ts,
  }));

  const dropoffDays: DateAction[] = compact(
    getModels('dropoffs').map(d =>
      d.workedDropoff(user) ? { str: dayMonth(d.performed_at), ts: d.performed_at_ts } : null,
    ),
  );

  const taskDays: DateAction[] = compact(
    getModels('fridge_cleanings').map(fc =>
      fc.worker_id === user.id ? { str: dayMonth(fc.performed_at), ts: fc.performed_at_ts } : null,
    ),
  );

  const bbDays: DateAction[] = compact(
    getModels('bonus_bucks').map(bb =>
      bb.worker_ids.includes(user.id) ? { str: dayMonth(bb.performed_at), ts: bb.performed_at_ts } : null,
    ),
  );

  let days = concat(actionDays, dropoffDays, taskDays, bbDays);

  days = sortBy(
    uniqBy(days, d => d.str),
    d => d.ts,
  );

  return days;
};

export const getStatsForUserOnDay = function(user: User, date: Date) {
  const stats: ShortShortMoneyNumbers = {};

  short_shorts.forEach(s => (stats[s] = { total: 0, uninstalled: 0, removed: 0 }));

  let action: InstallAction, short_short: string, cnt: number;

  const actions = actionsForUser(user);

  for (let i = 0; i < actions.length; i++) {
    action = actions[i];

    if (!action.set) continue;
    if (!action.money_type) continue;
    if (!areSameDay(action.created_at, date)) continue;

    short_short = action.install.product.short_short;

    cnt = action.individualCount();

    stats[short_short].total += cnt;

    stats[short_short][action.money_type] += cnt;
  }

  return stats;
};

export const getMoneyForStats = function(stats: ShortShortMoneyNumbers, user: User) {
  const uninstall_payscale = Payscale.find('uninstalled', user);
  const removal_payscale = Payscale.find('removed', user);

  const money: Partial<ShortShortMoneyStats> = {};

  let s: MoneyAmounts, room_stats: MoneyNumbers;

  short_shorts.forEach(short => {
    room_stats = stats[short];
    const uninstalled_cents = uninstall_payscale.short_cents[short];
    const removed_cents = removal_payscale.short_cents[short];

    const uninstalled_amount = uninstalled_cents * room_stats.uninstalled;
    const removed_amount = removed_cents * room_stats.removed;

    s = {
      uninstalled_amount: uninstalled_amount,
      removed_amount: removed_cents,
      total_amount: uninstalled_amount + removed_amount,
    };

    money[short] = s;
  });

  return money as ShortShortMoneyStats;
};

export const computeFridgeCleaningTotalCents = (user: User, payscale: Payscale) => {
  const fridge_cleanings = groupBy(
    filterModelsByAttr(getModels('fridge_cleanings'), 'worker_id', user.id),
    fc => fc.product_type,
  );

  const mf_count = (fridge_cleanings['Fridge'] || []).length;
  const ft_count = (fridge_cleanings['Futon'] || []).length;
  const es_count = (fridge_cleanings['LockBox'] || []).length;

  const mf_total = payscale.getCents('MF') * mf_count;
  const es_total = payscale.getCents('ES') * es_count;
  const ft_total = payscale.getCents('FT') * ft_count;

  return mf_total + ft_total + es_total;
};

export const computeFridgeCleaningTotalCentsForDay = (user: User, date: Date, payscale: Payscale) => {
  const fridge_cleanings = groupBy(
    getModels('fridge_cleanings').filter(fc => fc.worker_id === user.id && areSameDay(fc.performed_at, date)),
    fc => fc.product_type,
  );

  const mf_count = (fridge_cleanings['Fridge'] || []).length;
  const ft_count = (fridge_cleanings['Futon'] || []).length;
  const es_count = (fridge_cleanings['LockBox'] || []).length;

  const mf_total = payscale.getCents('MF') * mf_count;
  const es_total = payscale.getCents('ES') * es_count;
  const ft_total = payscale.getCents('FT') * ft_count;

  return mf_total + ft_total + es_total;
};

export const computeBonusBuckTotal = (user: User) => {
  const bonus_bucks = filterModelsByAttrInclusion(getModels('bonus_bucks'), 'worker_ids', user.id);
  return sumBy(bonus_bucks, bb => bb.individualDollars());
};

export const computeBonusBuckTotalOnDay = (user: User, date: Date) => {
  const bonus_bucks = getModels('bonus_bucks').filter(
    bb => bb.worker_ids.includes(user.id) && areSameDay(bb.performed_at, date),
  );

  return sumBy(bonus_bucks, bb => bb.individualDollars());
};

export const computeRoomTotal = (money: ShortShortMoneyStats) => {
  return sum(map(money, nums => nums.total_amount));
};

export const getDrivingTotalForUser = (user: User) => {
  const dropoffs = getModels('dropoffs');
  const dropoff_drivings = dropoffs.filter(d => d.type === 'dropoff' && d.driver_id === user.id);
  const pickup_drivings = dropoffs.filter(d => d.type === 'pickup' && d.driver_id === user.id);

  const dropoffPayscale = findModelByAttr(getModels('payscales'), 'type', 'dropoff');
  const dropoff_cents = sumBy(dropoff_drivings, d => d.totalCents(dropoffPayscale));

  const pickupPayscale = findModelByAttr(getModels('payscales'), 'type', 'pickup');
  const pickup_cents = sumBy(pickup_drivings, d => d.totalCents(pickupPayscale));

  return dropoff_cents + pickup_cents;
};

export const getDrivingTotalForUserAndDate = (user: User, date: Date) => {
  const dropoffs = getModels('dropoffs');
  const dropoff_drivings = dropoffs.filter(
    d => d.type === 'dropoff' && d.driver_id === user.id && areSameDay(d.performed_at, date),
  );
  const pickup_drivings = dropoffs.filter(
    d => d.type === 'pickup' && d.driver_id === user.id && areSameDay(d.performed_at, date),
  );

  const dropoffPayscale = Payscale.find('dropoff', user);
  const dropoff_cents = sumBy(dropoff_drivings, d => d.totalCents(dropoffPayscale));

  const pickupPayscale = Payscale.find('pickup', user);
  const pickup_cents = sumBy(pickup_drivings, d => d.totalCents(pickupPayscale));

  return dropoff_cents + pickup_cents;
};
