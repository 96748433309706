import ApplicationModel from './ApplicationModel';
import { StudentJSON, TableName } from '../types/json_types';
import { ClassName } from '../types/types';

class Student extends ApplicationModel {
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  installs_count: number;
  school_id: number;

  constructor(json: StudentJSON) {
    super(json);

    this.email = json.email;
    this.school_id = json.school_id;
    this.first_name = json.first_name;
    this.last_name = json.last_name;
    this.phone_number = json.phone_number;
    this.installs_count = json.installs_count;
  }

  fullName() {
    return `${this.first_name} ${this.last_name}`;
  }

  getClassName() {
    const className: ClassName = 'Student';
    return className;
  }

  static getTableName(): TableName {
    return 'students';
  }
}

export default Student;
