import React from 'react';
import Button from '@material-ui/core/Button';
import { store, getModels } from '../../constants';
import ApiRequest, { ApiRequestCallbacks } from '../../ApiRequest';
import { BonusBuckJSON, isErrorResponse, SentBonusBuckJSON } from '../../types/json_types';
import BonusBuck from '../../models/BonusBuck';
import { findModelById } from '../../app_util';

const { getState, setState } = store.getHelpers('bonus_buck_modal');

const update = (bonus_buck_id: number) => {
  const state = getState();

  if (state.working) {
    return;
  }

  const callbacks: ApiRequestCallbacks = {
    onLoading: () => setState({ working: true }),
    onSuccess: (json: unknown) => {
      const allBonusBucks = getModels('bonus_bucks');
      const bonus_buck = findModelById(allBonusBucks, bonus_buck_id);

      const nextBonusBuck = new BonusBuck(json as BonusBuckJSON);

      const index = allBonusBucks.indexOf(bonus_buck);
      allBonusBucks[index] = nextBonusBuck;

      store.forceUpdate('main');
      setState(BonusBuck.getModalState(null, false));
    },
    onError: (json: unknown) => {
      if (isErrorResponse(json)) {
        setState({ errors: json.errors });
      } else {
        alert('some funky error occurred');
      }
    },
    onDone: () => setState({ working: false }),
  };

  const data: SentBonusBuckJSON = {
    worker_ids: state.worker_ids,
    reason: state.reason,
    dollars: state.dollars,
    performed_at: state.performed_at,
  };

  const request = new ApiRequest(`/api/bonus_bucks/${bonus_buck_id}.json`, 'PUT', callbacks, { bonus_buck: data });
  request.perform();
};

const create = () => {
  const state = getState();

  if (state.working) {
    return;
  }

  const callbacks: ApiRequestCallbacks = {
    onLoading: () => setState({ working: true }),
    onSuccess: (json: unknown) => {
      const bonus_buck = new BonusBuck(json as BonusBuckJSON);
      getModels('bonus_bucks').unshift(bonus_buck);

      store.forceUpdate('bonus_bucks_page');
      setState(BonusBuck.getModalState(null, false));
    },
    onError: (json: unknown) => {
      if (isErrorResponse(json)) {
        setState({ errors: json.errors });
      } else {
        alert('some funky error occurred');
      }
    },
    onDone: () => setState({ working: false }),
  };

  const data: SentBonusBuckJSON = {
    reason: state.reason,
    dollars: state.dollars,
    worker_ids: state.worker_ids,
    performed_at: state.performed_at,
  };

  const request = new ApiRequest('/api/bonus_bucks.json', 'POST', callbacks, { bonus_buck: data });
  request.perform();
};

interface BonusBuckSaveButtonProps {
  id: number | null;
  working: boolean;
}

const BonusBuckSaveButton = (props: BonusBuckSaveButtonProps) => {
  const { id, working } = props;
  return (
    <Button onClick={() => (id ? update(id) : create())} variant="outlined" size="large" disabled={working}>
      Save
    </Button>
  );
};

export default BonusBuckSaveButton;
