import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { store, getModels } from '../../constants';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ApiRequest, { ApiRequestCallbacks } from '../../ApiRequest';
import { TicketRespJSON } from '../../types/json_types';
import { findModelById } from '../../app_util';
import { getTicketResolutionModalState } from '../../helpers/ticket_helpers';
import Ticket from '../../models/Ticket';

const { setState: setModalState, useStoreState } = store.getHelpers('ticket_resolution_modal');

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: '0',
    width: '100%',
    border: 'none',
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: 0,
    },
  }),
);

function clickSubmit(ticket_id: number, note: string, working: boolean) {
  if (working) {
    return;
  }

  const callbacks: ApiRequestCallbacks = {
    onLoading: () => setModalState({ working: true }),
    onSuccess: (resp: unknown) => {
      const json = resp as TicketRespJSON;

      const ticket = findModelById(getModels('tickets'), ticket_id);
      const heldTicket = new Ticket(json.ticket);
      Object.assign(ticket, heldTicket);

      const room = findModelById(getModels('rooms'), ticket.room_id);
      room.tickets_count = json.room.tickets_count;
      room.unresolved_tickets_count = json.room.unresolved_tickets_count;

      const appState = store.getFullStore();
      appState.ticket_resolution_modal = getTicketResolutionModalState(null);

      store.forceUpdate('job_detail');
    },
    onError: () => {
      alert('an error occurred');
    },
    onDone: () => setModalState({ working: false }),
  };

  const data = {
    resolution_notes: note,
  };

  const request = new ApiRequest(`/api/tickets/${ticket_id}/mark_resolved.json`, 'POST', callbacks, data);
  request.perform();
}

const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setModalState({ resolution_notes: event.target.value });
};

const handleClose = () => {
  setModalState({ open: false, resolution_notes: '', working: false });
};

const TicketResolutionModal = () => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const { ticket_id, open, working, resolution_notes } = useStoreState();

  if (!ticket_id) {
    return null;
  }

  const ticket = findModelById(getModels('tickets'), ticket_id);

  return (
    <Modal open={open} onClose={handleClose}>
      <div style={modalStyle} className={classes.paper}>
        <div className="px-4 my-2">
          <Typography variant="h6" className="d-flex">
            Add a resolution note for:
          </Typography>
          <div>{ticket.text}</div>

          <TextField
            style={{ width: '100%' }}
            className="mt-2"
            id="outlined-multiline-static"
            multiline
            rows="4"
            value={resolution_notes}
            onChange={onChange}
            variant="outlined"
          />
        </div>
        <div className="d-flex justify-content-between align-items-center mx-4 my-3">
          <Button onClick={handleClose} variant="outlined" disabled={working}>
            Cancel
          </Button>

          <Button
            onClick={() => clickSubmit(ticket_id, resolution_notes, working)}
            variant="outlined"
            disabled={working}
          >
            Resolve
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(TicketResolutionModal);
