import React from 'react';
import Button from '@material-ui/core/Button';
import { store, getModels, setModels } from '../../constants';
import ApiRequest, { ApiRequestCallbacks } from '../../ApiRequest';
import { isErrorResponse, RoomOccupationJSON } from '../../types/json_types';
import RoomOccupation from '../../models/RoomOccupation';
import Room from '../../models/Room';
import reject from 'lodash/reject';

const { setState } = store.getHelpers('job_detail');

type fn = React.Dispatch<React.SetStateAction<boolean>>;

const toggleOccupied = (occupied: boolean, room: Room, working: boolean, setWorking: fn) => {
  if (working) {
    return;
  }

  setWorking(true);

  setState({
    occupied: !occupied,
  });

  const callbacks: ApiRequestCallbacks = {
    onSuccess: (json: unknown) => {
      const roomOccupation = new RoomOccupation(json as RoomOccupationJSON);

      const room_occupations = reject(getModels('room_occupations'), ro => ro.room_id === room.id);
      room_occupations.unshift(roomOccupation);
      setModels('room_occupations', room_occupations);

      room.occupied = roomOccupation.occupied;
      setState({ occupied: room.occupied });
    },
    onError: (json: unknown) => {
      setState({ occupied: occupied });
      if (isErrorResponse(json)) {
        alert(json.errors.join('. '));
      } else {
        alert('an error occurred while changing occupied status');
      }
    },
    onDone: () => {
      setWorking(false);
    },
  };

  const request = new ApiRequest('/api/room_occupations.json', 'POST', callbacks, {
    room_id: room.id,
    occupied: !occupied,
  });

  request.perform();
};

interface OccupiedButtonProps {
  occupied: boolean;
  room: Room;
}

const OccupiedButton = (props: OccupiedButtonProps) => {
  const { occupied, room } = props;
  const [working, setWorking] = React.useState(false);

  return (
    <Button
      variant="outlined"
      disabled={working}
      color="default"
      onClick={() => toggleOccupied(occupied, room, working, setWorking)}
    >
      {occupied ? 'set unoccupied' : 'set room occupied'}
    </Button>
  );
};

export default React.memo(OccupiedButton);
