import Filter from '../models/Filter';
import { getModels } from '../constants';
import Room from '../models/Room';
import { filterModelsByInclusion } from '../app_util';

export const getFilteredRooms = (filter: Filter) => {
  const school = filter.getSchool();

  const { product_ids, statuses } = filter;

  let rooms: Room[];
  rooms = getModels('rooms').filter(r => r.building.school_id === school.id);

  if (filter.building_ids.length) {
    rooms = filterModelsByInclusion(rooms, 'building_id', filter.building_ids);
  }

  if (product_ids.length && statuses.length) {
    rooms = rooms.filter(r => r.hasProductsAndStatuses(product_ids, statuses));
  } else if (product_ids.length) {
    rooms = rooms.filter(r => r.hasProducts(product_ids));
  } else if (statuses.length) {
    rooms = rooms.filter(r => r.hasStatuses(statuses));
  }

  if (filter.only_available_jobs) {
    rooms = rooms.filter(r => r.hasAvailableJobs());
  }

  return rooms;
};
