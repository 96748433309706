import React from 'react';
import AppBar from './app_bar/AppBar';
import { store, router, getAllData } from '../constants';
import { AllJSONResponse, CurrentUserJSONResponse } from '../types/json_types';
import ApiRequest, { ApiRequestCallbacks } from '../ApiRequest';
import Drawer from './Drawer';
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';
import JobsList from './job_list/JobsList';
import { handleData } from '../app_util';
import { View } from '../types/state_types';
import Home from './Home';
import JobDetail from './job_detail/JobDetail';
import { handleRoute } from '../setup/router';
import FilterModal from './filter_modal/FilterModal';
import BuildingDetail from './BuildingDetail';
import StatsPage from './stats/StatsPage';
import UserWorkPage from './user_work/UserWork';
import BonusBucks from './bonus_bucks/BonusBucks';
import BasicProfileForm from './profile/BasicProfileForm';
import { getProfileFormState } from '../helpers/profile_helpers';
import User from '../models/User';
import Typography from '@material-ui/core/Typography';
import ProfilePage from './profile/ProfilePage';
import WorkersPage from './workers/WorkersPage';
import TicketsPage from './TicketsPage';
import DropoffList from './dropoffs/DropoffList';
import Export from './admin/Export';
import DropoffDetail from './dropoffs/DropoffDetail';
import DropoffModal from './dropoffs/DropoffModal';
import PayscaleList from './payscales/PayscaleList';
import PayscaleDetail from './payscales/PayscaleDetail';
import FridgeCleaningList from './fridge_cleaning/FridgeCleaningList';
import FridgeCleaningDetail from './fridge_cleaning/FridgeCleaningDetail';
import FridgeCleaningModal from './fridge_cleaning/FridgeCleaningModal';
import FridgeCleaningGroupDetail from './fridge_cleaning/FridgeCleaningGroupDetail';

const { setState, useStoreState } = store.getHelpers('main');

const userCallbacks: ApiRequestCallbacks = {
  onSuccess: (json: unknown) => {
    const { currentUser } = json as CurrentUserJSONResponse;

    if (currentUser) {
      window.currentUser = new User(currentUser);
      setState({ currentUserId: currentUser.id });
    } else {
      setState({ currentUserId: null });
    }
  },
  onError: () => {
    console.log('couldnt get current user');
  },
};

const request = new ApiRequest('/api/get_current_user', 'GET', userCallbacks);

function fetchCurrentUser() {
  if (request.status > 0) {
    return;
  }

  request.perform();
}

const dataCallbacks: ApiRequestCallbacks = {
  onSuccess: (json: unknown) => {
    handleData(json as AllJSONResponse);
    window.D = getAllData();
    handleRoute(window.location.pathname);
  },
  onError: () => {
    alert('an error occurred while loading the data');
  },
};

const dataRequest = new ApiRequest('/api/all_json.json', 'GET', dataCallbacks);

function fetchData() {
  if (dataRequest.status > 0) {
    return;
  }

  dataRequest.perform();
}

const getView = (viewShown?: View | null) => {
  if (window.D) {
    switch (viewShown) {
      case 'Jobs':
        return (
          <div>
            <JobsList viewShown={viewShown} />
          </div>
        );
      case 'JobDetail':
        return (
          <div>
            <JobsList viewShown={viewShown} />
            <JobDetail />
          </div>
        );
      case 'BuildingDetail':
        return <BuildingDetail />;
      case 'Home':
        return <Home />;
      case 'Stats':
        return <StatsPage />;
      case 'UserWork':
        return <UserWorkPage />;
      case 'BonusBucks':
        return <BonusBucks />;
      case 'Profile':
        return <ProfilePage />;
      case 'Workers':
        return <WorkersPage />;
      case 'Tickets':
        return <TicketsPage />;
      case 'Dropoffs':
        return (
          <div>
            <DropoffModal />
            <DropoffList />
          </div>
        );
      case 'DropoffDetail':
        return (
          <div>
            <DropoffModal />
            <DropoffList />
            <DropoffDetail />
          </div>
        );
      case 'Export':
        return <Export />;
      case 'Payscales':
        return <PayscaleList />;
      case 'PayscaleDetail':
        return <PayscaleDetail />;
      case 'FridgeCleaningGroupDetail':
        return <FridgeCleaningGroupDetail />;
      case 'Cleaning':
        return (
          <div>
            <FridgeCleaningModal />
            <FridgeCleaningList />
          </div>
        );
      case 'FridgeCleaningDetail':
        return (
          <div>
            <FridgeCleaningModal />
            <FridgeCleaningDetail />
          </div>
        );
      default:
        return <div> page not found</div>;
    }
  } else {
    return <h5>loading...</h5>;
  }
};

const authRegex = /^\/(login|signup)/;
const onAuthPage = () => {
  return authRegex.test(window.location.pathname);
};

const Main = () => {
  const { currentUserId, viewShown } = useStoreState();

  fetchCurrentUser();

  if (request.isLoading()) {
    return <div className="App"></div>;
  }

  const authed = !!currentUserId;

  if (authed) {
    if (onAuthPage()) {
      router.go('/');
      return <div className="App"></div>;
    }

    if (window.currentUser.canPassGo()) {
      fetchData();

      return (
        <div className="App">
          <AppBar />
          {getView(viewShown)}
          <Drawer />
          <FilterModal />
        </div>
      );
    } else {
      store.getFullStore().profile_form = getProfileFormState(window.currentUser);

      return (
        <div className="App">
          <Typography variant="h4">Fill-in Your Profile</Typography>
          <BasicProfileForm />
        </div>
      );
    }
  } else {
    if (onAuthPage()) {
      const authView = router.stateFromPath(window.location.pathname)?.viewShown;

      if (authView === 'Login') {
        return <LoginForm />;
      } else if (authView === 'Signup') {
        return <SignupForm />;
      } else {
        throw new Error("authView should be 'Login' or 'Signup' here...");
      }
    } else {
      setTimeout(() => {
        router.go('/login');
      }, 1);
      return <div className="App"></div>;
    }
  }
};

export default Main;
