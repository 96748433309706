import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';

import Modal from '@material-ui/core/Modal';
import { store, getModels } from '../../constants';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ErrorAlert from '../ErrorAlert';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import strftime from 'strftime';
import DatePicker from '../DatePicker';
import BuildingSelectNull from '../selects/BuildingSelectNull';
import UserSelect from '../UserSelect';
import { getBarcodeScanningModalState } from '../../helpers/barcode_helpers';
import FridgeStatusSelect from './FridgeStatusSelect';
import { FridgeStatus, FridgeType, FridgeProduct, FridgeBrand, fridge_brands } from '../../models/FridgeCleaning';
import { createFridgeCleaning, updateFridgeCleaning } from '../../requests/fridge_cleaning_requests';
import BarcodeScanningModal from './BarcodeScanningModal';
import LocationSelect from './LocationSelect';
import FridgeCleaningTypeSelect from './FridgeCleaningTypeSelect';
import FridgeCleaningProductSelect from './FridgeCleaningProductSelect';
import FridgeCleaningModalPhotoRow from './FridgeCleaningModalPhotoRow';
import GenericStringSelectNull from '../selects/GenericStringSelectNull';

const { getState, setState, useStoreState } = store.getHelpers('fridge_cleaning_modal');

function getModalStyle() {
  return {
    width: '100%',
    top: '0',
    left: '0',
    bottom: '0',
    outline: '0',
    border: 'none',
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: 0,
    },
  }),
);

const handleClose = () => {
  setState({ open: false });
};

const clearErrors = () => {
  store.setState('fridge_cleaning_modal', { errors: [] });
};

const setBuilding = (building_id: number | null) => {
  setState({ building_id });
};

const renderBuilding = () => {
  const { school_id, building_id } = getState();
  if (school_id) {
    return (
      <div className="mt-4">
        <BuildingSelectNull
          school_id={school_id}
          building_id={building_id}
          onChange={setBuilding}
          nullLabel="no building"
        />
      </div>
    );
  } else {
    return null;
  }
};

const renderRoom = () => {
  const { school_id, building_id, room_str } = getState();
  if (school_id && building_id) {
    return (
      <div className="mt-3">
        <TextField
          value={room_str || ''}
          onChange={e => setState({ room_str: e.target.value })}
          label="Room # or other location in building"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          style={{ width: '95%' }}
        />
      </div>
    );
  } else {
    return null;
  }
};

const scanNumber = () => {
  const state = getBarcodeScanningModalState(true);
  store.setState('barcode_scanning_modal', state);
};

const setSchool = (school_id: number) => {
  setState({ school_id, warehouse_id: null, building_id: null, room_str: null });
};

const setWarehouse = (warehouse_id: number) => {
  setState({ warehouse_id, school_id: null, building_id: null, room_str: null });
};

const FridgeCleaningModal = () => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const state = useStoreState();

  const { id, working, image_file_name } = state;

  const title = id ? 'Edit clean/repair' : 'New clean/repair';

  const performed_at = state.performed_at || new Date();

  let object_id = state.warehouse_id || state.school_id;
  let object_class: 'Warehouse' | 'School' = state.warehouse_id ? 'Warehouse' : 'School';

  if (!object_id) {
    state.school_id = object_id = getModels('schools')[0].id;
    object_class = 'School';
  }

  return (
    <Modal open={state.open} onClose={handleClose}>
      <div style={modalStyle} className={classes.paper}>
        <div>
          <Typography variant="h6" className="d-flex align-center" style={{ justifyContent: 'center' }}>
            {title}
          </Typography>

          <ErrorAlert errors={state.errors} closeAlert={clearErrors} />
        </div>

        <Divider className="mb-2" />

        <Box flexGrow="1" style={{ overflow: 'scroll' }} className="pl-1">
          <UserSelect
            user_id={state.worker_id}
            label="cleaner"
            onChange={(worker_id: number | null) => setState({ worker_id })}
          />

          <div className="mt-4">
            <FridgeCleaningTypeSelect type={state.type} onChange={(type: FridgeType) => setState({ type })} />
          </div>

          <div className="mt-4">
            <FridgeCleaningProductSelect
              product_type={state.product_type}
              onChange={(product_type: FridgeProduct) => setState({ product_type })}
            />
          </div>

          <div className="mt-4">
            <GenericStringSelectNull<FridgeBrand>
              label="Brand"
              options={fridge_brands}
              value={state.brand}
              nullLabel="Select a Brand"
              onChange={(value: FridgeBrand | null): void => setState({ brand: value })}
            />
          </div>

          <div className="mt-4">
            <FridgeStatusSelect status={state.status} onChange={(status: FridgeStatus) => setState({ status })} />
          </div>

          <div className="mt-4">
            <LocationSelect
              itemId={object_id}
              itemClass={object_class}
              onChangeSchool={setSchool}
              onChangeWarehouse={setWarehouse}
            />
          </div>

          {renderBuilding()}
          {renderRoom()}

          <div className="mt-4">
            <div>performed on: {strftime('%b %e %Y', performed_at)}</div>
            <DatePicker
              label="change date performed"
              date={performed_at}
              onChange={(d: Date | null) => setState({ performed_at: d })}
            />
          </div>

          <Box display="flex" alignItems="center" className="my-3">
            <TextField
              value={state.serial_number || ''}
              onChange={e => setState({ serial_number: e.target.value })}
              label="Serial Number"
              type="number"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />

            <Button onClick={scanNumber} variant="outlined" disabled={working} className="ml-3">
              Scan #
            </Button>
          </Box>

          <FridgeCleaningModalPhotoRow {...{ id, working, image_file_name }} />

          <TextField
            style={{ width: '90%' }}
            className="my-3"
            label="notes"
            multiline
            rows="4"
            value={state.notes || ''}
            onChange={e => setState({ notes: e.target.value })}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>

        <Divider className="mt-1" />

        <div className="d-flex justify-content-between align-items-center mx-4 my-3">
          <Button onClick={handleClose} variant="outlined" disabled={state.working}>
            Cancel
          </Button>

          {state.id ? (
            <Button onClick={updateFridgeCleaning} variant="outlined" disabled={state.working}>
              Update
            </Button>
          ) : (
            <Button onClick={createFridgeCleaning} variant="outlined" disabled={state.working}>
              Save
            </Button>
          )}
        </div>
        <BarcodeScanningModal />
      </div>
    </Modal>
  );
};

export default FridgeCleaningModal;
