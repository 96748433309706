import React from 'react';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import { store, getModels, router } from '../../constants';
import { FilterModalState, View } from '../../types/state_types';
import Filter from '../../models/Filter';
import OnlineSection from './OnlineSection';
import Button from '@material-ui/core/Button';
import { getFridgeCleaningModalState } from '../../helpers/fridge_cleaning_helpers';

const clickFilter = () => {
  const filter = window.App.filter;
  let state: FilterModalState;

  if (filter) {
    state = { open: true, ...filter.toJSON() };
  } else {
    state = { ...Filter.emptyModalState(), open: true, school_id: getModels('schools')[0].id };
  }

  store.setState('filter_modal', state);
};

const clickEditProfile = () => {
  const user = store.getState('user_work_page').user;

  if (user) {
    router.go(`/profiles/${user.id}`);
  }
};

const clickAddCleanRepair = () => {
  const filter = window.App.filter || null;
  store.setState('fridge_cleaning_modal', getFridgeCleaningModalState(null, true, filter));
};

const getButton = (view?: View) => {
  if (view === 'Cleaning') {
    return (
      <Button size="small" onClick={clickAddCleanRepair} variant="outlined">
        ADD
      </Button>
    );
  } else if (view === 'UserWork' && window.currentUser.admin) {
    return (
      <Button size="small" onClick={clickEditProfile}>
        edit
      </Button>
    );
  } else {
    return (
      <IconButton onClick={clickFilter} edge="start" color="inherit" aria-label="menu" style={{ marginLeft: 16 }}>
        <FilterListIcon />
      </IconButton>
    );
  }
};

function AppBarRight() {
  const { viewShown } = store.useStore('main', ['viewShown']);

  return (
    <div>
      <OnlineSection />
      {getButton(viewShown)}
    </div>
  );
}

export default React.memo(AppBarRight);
