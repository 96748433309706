import ApplicationModel from './ApplicationModel';
import { SchoolJSON, TableName } from '../types/json_types';
import { ClassName } from '../types/types';
import { filterModelsByAttr } from '../app_util';
import { getModels } from '../constants';

class School extends ApplicationModel {
  name: string;
  short: string;

  constructor(json: SchoolJSON) {
    super(json);

    this.name = json.name;
    this.short = json.short;
  }

  getInstalls() {
    return filterModelsByAttr(getModels('installs'), 'school', this);
  }

  getClassName() {
    const className: ClassName = 'School';
    return className;
  }

  static getTableName(): TableName {
    return 'schools';
  }
}

export default School;
