import React from 'react';
import Ticket from '../../models/Ticket';
import Button from '@material-ui/core/Button';
import ApiRequest, { ApiRequestCallbacks } from '../../ApiRequest';
import { TicketRespJSON } from '../../types/json_types';
import { findModelById } from '../../app_util';
import { getModels, store } from '../../constants';
import { getTicketResolutionModalState } from '../../helpers/ticket_helpers';

type fn = React.Dispatch<React.SetStateAction<boolean>>;

const clickUnresolveTicket = (ticket: Ticket, working: boolean, setWorking: fn) => {
  if (working) {
    return;
  }

  setWorking(true);

  const callbacks: ApiRequestCallbacks = {
    onSuccess: (resp: unknown) => {
      const json = resp as TicketRespJSON;

      const heldTicket = new Ticket(json.ticket);
      Object.assign(ticket, heldTicket);

      const room = findModelById(getModels('rooms'), ticket.room_id);
      room.unresolved_tickets_count = json.room.unresolved_tickets_count;

      const appState = store.getFullStore();
      appState.ticket_resolution_modal = getTicketResolutionModalState(null);

      store.forceUpdate('job_detail');
    },
    onError: () => {
      setWorking(false);
      alert('an error occurred');
    },
  };

  const request = new ApiRequest(`/api/tickets/${ticket.id}/mark_unresolved.json`, 'POST', callbacks);
  request.perform();
};

interface UnresolveTicketButtonProps {
  ticket: Ticket;
}

const UnresolveTicketButton = (props: UnresolveTicketButtonProps) => {
  const { ticket } = props;

  const [working, setWorking] = React.useState(false);

  return (
    <Button
      variant="outlined"
      className="mt-2"
      disabled={working}
      size="small"
      onClick={() => clickUnresolveTicket(ticket, working, setWorking)}
    >
      Unresolve
    </Button>
  );
};

export default UnresolveTicketButton;
