import React from 'react';
import { store } from '../constants';

const { getState } = store.getHelpers('home');

const Home = () => {
  const { msg } = getState();

  return <div className="home">Hello {msg}!</div>;
};

export default Home;
