import { AllJSONResponse } from './types/json_types';
import ApplicationModel from './models/ApplicationModel';
import School from './models/School';
import Building from './models/Building';
import Room from './models/Room';
import Install from './models/Install';
import { shorts, setModels, store, Count, getModels } from './constants';

import groupBy from 'lodash/groupBy';
import uniq from 'lodash/uniq';
import map from 'lodash/map';
import Product from './models/Product';
import Student from './models/Student';
import sortBy from 'lodash/sortBy';
import toUpper from 'lodash/toUpper';
import User from './models/User';
import InstallAction from './models/InstallAction';
import RoomLocking from './models/RoomLocking';
import RoomOccupation from './models/RoomOccupation';
import Ticket from './models/Ticket';
import FieldNote from './models/FieldNote';
import Payscale from './models/Payscale';
import Paycheck from './models/Paycheck';
import BonusBuck from './models/BonusBuck';
import Dropoff from './models/Dropoff';
import Warehouse from './models/Warehouse';
import FridgeCleaning from './models/FridgeCleaning';

export const swapModels = <T extends ApplicationModel>(arr: Array<T>, prevObject: T, nextObject: T) => {
  const index = arr.indexOf(prevObject);
  arr[index] = nextObject;
};

export const findModelById = function<T extends ApplicationModel>(arr: Array<T>, id: number) {
  const length = arr.length;

  for (let index = 0; index < length; index++) {
    if (arr[index].id === id) return arr[index];
  }

  throw new Error(`Couldn't find ${arr[0].getClassName()} by id ${id}`);
};

export const findModelByAttr = function<T extends ApplicationModel, Key extends keyof T>(
  arr: Array<T>,
  attr: Key,
  value: T[Key],
) {
  const length = arr.length;

  for (let index = 0; index < length; index++) {
    if (arr[index][attr] === value) return arr[index];
  }

  throw new Error(`Couldn't find ${arr[0].getClassName()} by attr ${attr} & val ${value}`);
};

export const filterModelsByAttr = function<T extends ApplicationModel, Key extends keyof T>(
  arr: Array<T>,
  attr: Key,
  value: T[Key],
) {
  const length = arr.length;

  const foundItems: Array<T> = [];
  let item: T;

  for (let index = 0; index < length; index++) {
    item = arr[index];
    if (item[attr] === value) {
      foundItems.push(item);
    }
  }

  return foundItems;
};

export const filterModelsByInclusion = function<T extends ApplicationModel, Key extends keyof T>(
  arr: Array<T>,
  attr: Key,
  ids: number[],
) {
  const length = arr.length;
  const models = [];
  let model: T;

  for (let index = 0; index < length; index++) {
    model = arr[index];
    const id: unknown = model[attr];
    if (ids.includes(id as number)) {
      models.push(model);
    }
  }

  return models;
};

export const handleData = function(allJson: AllJSONResponse) {
  setModels(
    'products',
    allJson.products.map(p => new Product(p)),
  );
  setModels(
    'schools',
    allJson.schools.map(s => new School(s)),
  );
  setModels(
    'buildings',
    allJson.buildings.map(b => new Building(b)),
  );
  const rooms = setModels(
    'rooms',
    allJson.rooms.map(r => new Room(r)),
  );
  const installs = setModels(
    'installs',
    allJson.installs.map(i => new Install(i)),
  );
  const students = setModels(
    'students',
    allJson.students.map(i => new Student(i)),
  );
  const users = setModels(
    'users',
    allJson.users.map(u => new User(u)),
  );
  const currentUser = window.currentUser;
  const byebye = findModelById(users, currentUser.id);
  swapModels(users, byebye, currentUser);

  setModels(
    'room_lockings',
    (allJson.room_lockings || []).map(rl => new RoomLocking(rl)),
  );
  setModels(
    'room_occupations',
    (allJson.room_occupations || []).map(ro => new RoomOccupation(ro)),
  );
  const install_actions = setModels(
    'install_actions',
    (allJson.install_actions || []).map(ia => new InstallAction(ia)),
  );
  setModels(
    'tickets',
    (allJson.tickets || []).map(t => new Ticket(t)),
  );
  setModels(
    'field_notes',
    (allJson.field_notes || []).map(n => new FieldNote(n)),
  );
  setModels(
    'payscales',
    (allJson.payscales || []).map(ps => new Payscale(ps)),
  );
  setModels(
    'paychecks',
    (allJson.paychecks || []).map(pc => new Paycheck(pc)),
  );
  setModels(
    'bonus_bucks',
    (allJson.bonus_bucks || []).map(bb => new BonusBuck(bb)),
  );
  setModels(
    'warehouses',
    (allJson.warehouses || []).map(d => new Warehouse(d)),
  );
  setModels(
    'dropoffs',
    (allJson.dropoffs || []).map(d => new Dropoff(d)),
  );
  setModels(
    'fridge_cleanings',
    (allJson.fridge_cleanings || []).map(d => new FridgeCleaning(d)),
  );

  const installActionsGroupedByInstallId = groupBy(install_actions, ia => ia.install_id);

  let install: Install;
  for (let i = 0; i < installs.length; i++) {
    install = installs[i];
    install.setInstallActions(installActionsGroupedByInstallId[install.id] || []);
  }

  const installsGroupedByRoomId = groupBy(installs, i => i.room_id);
  let room: Room;
  for (let i = 0; i < rooms.length; i++) {
    room = rooms[i];
    room.installs = installsGroupedByRoomId[room.id];
    room.students = uniq(room.installs.map(i => i.student_id)).map(id => findModelById(students, id));
  }
};

type BelongsToBuilding = {
  building_id: number;
};

export const intersperseBuildings = function<T extends BelongsToBuilding>(
  items: T[],
  buildings: Array<Building>,
): Array<T | Building> {
  const building_ids = uniq(map(items, 'building_id'));
  buildings = sortBy(
    buildings.filter(b => building_ids.includes(b.id)),
    b => b.shown_name,
  );

  return buildings.map(building => [building, items.filter(r => r.building_id === building.id)].flat()).flat();
};

export const findModelsByIdInclusion = function<T extends ApplicationModel>(arr: Array<T>, ids: number[]) {
  const length = arr.length;
  const models = [];
  let model: T;

  for (let index = 0; index < length; index++) {
    model = arr[index];
    if (ids.includes(model.id)) {
      models.push(model);
    }
  }

  return models;
};

export const filterModelsByAttrInclusion = function<T extends ApplicationModel, Key extends keyof T>(
  arr: Array<T>,
  attr: Key,
  id: number,
) {
  const length = arr.length;
  const models = [];
  let model: T;
  let ids: unknown;

  for (let index = 0; index < length; index++) {
    model = arr[index];
    ids = model[attr];
    if ((ids as number[]).includes(id)) {
      models.push(model);
    }
  }

  return models;
};

export const sortInstallsByShort = (installs: Install[]) => {
  return sortBy(installs, i => {
    return shorts.indexOf(i.product.short);
  });
};

export const getActiveUsers = () => {
  return filterModelsByAttr(getModels('users'), 'active', true);
};

export const toggleDarkmode = () => {
  const full = store.getFullStore();

  if (localStorage.getItem('light_mode')) {
    localStorage.removeItem('light_mode');
    full.top.colormode = 'dark';
    document.body.className = 'darkmode';
  } else {
    localStorage.setItem('light_mode', 'yup');
    full.top.colormode = 'light';
    document.body.className = 'lightmode';
  }

  store.forceUpdateEverything();
};

export const shortFromCount = (count: Count) => {
  return toUpper(count.split('_')[0]);
};
