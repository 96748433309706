import FridgeCleaning, { fridge_statuses, fridge_types, product_types } from '../models/FridgeCleaning';
import { FridgeCleaningModalState } from '../types/state_types';
import { store } from '../constants';
import Filter from '../models/Filter';

const firstBuilding = (building_ids?: number[]) => {
  return building_ids?.length ? building_ids[0] : null;
};

export const getFridgeCleaningModalStateFromTemplate = (fridge_cleaning: FridgeCleaning): FridgeCleaningModalState => {
  return {
    open: true,
    working: false,
    errors: [],

    id: null,
    brand: fridge_cleaning.brand,
    worker_id: fridge_cleaning.worker_id,
    performed_at: new Date(),
    warehouse_id: fridge_cleaning.warehouse_id,
    school_id: fridge_cleaning.school_id,
    building_id: fridge_cleaning.building_id,
    room_id: fridge_cleaning.room_id,
    room_str: fridge_cleaning.room_str,
    serial_number: null,
    status: fridge_cleaning.status,
    type: fridge_cleaning.type,
    image_file_name: null,
    product_type: fridge_cleaning.product_type,
    notes: null,
  };
};

export const getFridgeCleaningModalState = (
  fridge_cleaning: FridgeCleaning | null,
  open: boolean,
  filter: Filter | null,
): FridgeCleaningModalState => {
  if (fridge_cleaning) {
    return {
      open,
      working: false,
      errors: [],

      id: fridge_cleaning.id,
      worker_id: fridge_cleaning.worker_id,
      performed_at: fridge_cleaning.performed_at,
      warehouse_id: fridge_cleaning.warehouse_id,
      school_id: fridge_cleaning.school_id,
      building_id: fridge_cleaning.building_id,
      room_id: fridge_cleaning.room_id,
      room_str: fridge_cleaning.room_str,
      serial_number: fridge_cleaning.serial_number,
      status: fridge_cleaning.status,
      type: fridge_cleaning.type,
      image_file_name: fridge_cleaning.image_file_name,
      product_type: fridge_cleaning.product_type,
      brand: fridge_cleaning.brand,
      notes: fridge_cleaning.notes,
    };
  } else {
    return {
      open,
      working: false,
      errors: [],

      id: null,
      worker_id: window.currentUser?.id || null,
      performed_at: new Date(),
      warehouse_id: null,
      school_id: filter?.school_id || null,
      building_id: firstBuilding(filter?.building_ids),
      room_id: null,
      room_str: null,
      serial_number: null,
      image_file_name: null,
      status: fridge_statuses[0],
      type: fridge_types[0],
      product_type: product_types[0],
      brand: null,
      notes: null,
    };
  }
};

export const setFridgeCleaningModalState = (fridge_cleaning: FridgeCleaning, open: boolean, filter: Filter | null) => {
  const state = getFridgeCleaningModalState(fridge_cleaning, open, filter);
  store.setState('fridge_cleaning_modal', state);
};
