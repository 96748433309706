import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Divider from '@material-ui/core/Divider';
import { store } from '../../constants';
import Typography from '@material-ui/core/Typography';
import { BonusBuckModalState } from '../../types/state_types';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ErrorAlert from '../ErrorAlert';
import BonusBuck from '../../models/BonusBuck';
import MultiUserSelect from '../MultiUserSelect';
import BonusBuckSaveButton from './BonusBuckSaveButton';
import BonusBuckDestroyButton from './BonusBuckDestroyButton';
import DatePicker from '../DatePicker';
import strftime from 'strftime';

const { setState, useStoreState } = store.getHelpers('bonus_buck_modal');

function getModalStyle() {
  return {
    zIndex: 99999,
    top: '0',
    left: '0',
    outline: '0',
    width: '100%',
    border: 'none',
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.background.paper,
      padding: 5,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

const handleClose = () => {
  const state = BonusBuck.getModalState(null, false);
  setState(state);
};

const onChange = <Key extends keyof BonusBuckModalState>(attr: Key, value: BonusBuckModalState[Key]) => {
  setState({ [attr]: value });
};

const closeErrorAlert = () => {
  setState({ errors: [] });
};

const BonusBuckModal = () => {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const modalStyle = getModalStyle();

  const state = useStoreState();

  return (
    <div>
      <Modal open={state.open} onClose={handleClose}>
        <div style={modalStyle} className={classes.paper}>
          <Typography variant="h6" style={{ textAlign: 'center' }}>
            Add Bonus Buck
          </Typography>

          <Divider className="mb-2" />

          <Box flexGrow="1" style={{ overflow: 'scroll' }}>
            <ErrorAlert errors={state.errors} closeAlert={closeErrorAlert} className="mt-2 mb-3" />

            <MultiUserSelect
              user_ids={state.worker_ids}
              label="Split BBs with:"
              onChange={user_ids => onChange('worker_ids', user_ids)}
            />

            <div className="mt-3">
              <TextField
                label="Bonus Bucks"
                type="number"
                name="dollars"
                placeholder="1"
                value={state.dollars || ''}
                onChange={e => onChange('dollars', parseInt(e.target.value))}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>

            <div className="mt-4">
              <div>performed on: {strftime('%b %e %Y', state.performed_at)}</div>
              <DatePicker
                label="change date"
                date={state.performed_at}
                onChange={(d: Date | null) => setState({ performed_at: d || new Date() })}
              />
            </div>

            <div className="my-3">
              <TextField
                style={{ width: '100%' }}
                className="mt-2"
                label="Reason"
                multiline
                rows="3"
                value={state.reason || ''}
                onChange={e => onChange('reason', e.target.value)}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </Box>

          <Divider className="mb-2" />

          <Box display="flex" justifyContent="space-between">
            <Button onClick={handleClose} variant="outlined" size="large" disabled={state.working}>
              Cancel
            </Button>

            {state.id ? <BonusBuckDestroyButton paycheck_id={state.id} working={state.working} /> : null}

            <BonusBuckSaveButton id={state.id} working={state.working} />
          </Box>
        </div>
      </Modal>
    </div>
  );
};

export default React.memo(BonusBuckModal);
