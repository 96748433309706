import React from 'react';
import Button from '@material-ui/core/Button';
import { store, getModels } from '../../constants';
import ApiRequest, { ApiRequestCallbacks } from '../../ApiRequest';
import { isErrorResponse } from '../../types/json_types';
import Paycheck from '../../models/Paycheck';
import { findModelById } from '../../app_util';
import red from '@material-ui/core/colors/red';
import { spliceFromArray } from '../../util';

const { setState } = store.getHelpers('paycheck_modal');

const destroyPaycheck = (paycheck_id: number, working: boolean) => {
  if (working) {
    return;
  }

  const callbacks: ApiRequestCallbacks = {
    onLoading: () => setState({ working: true }),
    onSuccess: () => {
      const allPaychecks = getModels('paychecks');
      const paycheck = findModelById(allPaychecks, paycheck_id);
      spliceFromArray(allPaychecks, paycheck);

      store.forceUpdate('user_work_page');
      setState(Paycheck.getModalState(null, false, null));
    },
    onError: (json: unknown) => {
      if (isErrorResponse(json)) {
        setState({ errors: json.errors });
      } else {
        alert('some funky error occurred');
      }
    },
    onDone: () => setState({ working: false }),
  };

  const request = new ApiRequest(`/api/paychecks/${paycheck_id}.json`, 'DELETE', callbacks);
  request.perform();
};

interface PaycheckModalDestroyButtonProps {
  working: boolean;
  paycheck_id: number;
}

const PaycheckModalDestroyButton = (props: PaycheckModalDestroyButtonProps) => {
  return (
    <Button
      style={{ color: red[900], borderColor: red[900] }}
      onClick={() => destroyPaycheck(props.paycheck_id, props.working)}
      variant="outlined"
      size="large"
      disabled={props.working}
    >
      Destroy
    </Button>
  );
};

export default PaycheckModalDestroyButton;
