import { InstallStatus } from './types';
import { PayscaleType, PayscaleGroup } from '../models/Payscale';
import { FridgeStatus, FridgeType, FridgeProduct, FridgeBrand } from '../models/FridgeCleaning';

export interface ModelJSON {
  id: number;
  created_at_ts: number;
}

export interface UserJSON extends ModelJSON {
  email: string;
  username: string;
  first_name: string;
  last_name: string;
  address1: string | null | undefined;
  address2: string | null | undefined;
  city: string | null | undefined;
  state: string | null | undefined;
  zip_code: string | null | undefined;
  phone: string | null;
  ssn: string | null;
  role: string;
  agreed_to_terms: boolean;
  active: boolean;
}

export interface FridgeCleaningJSON extends ModelJSON {
  type: FridgeType;
  creator_id: number;
  worker_id: number;
  performed_at_ts: number;
  warehouse_id: number | null;
  school_id: number | null;
  building_id: number | null;
  room_id: number | null;
  room_str: string | null;
  serial_number: string | null;
  status: FridgeStatus;
  product_type: FridgeProduct;
  notes: string | null;
  brand: FridgeBrand | null;
  image_file_name: string | null;
}

export interface DropoffJSON extends ModelJSON {
  bl_count: number;
  mf_count: number;
  sr_count: number;
  ft_count: number;
  dft_count: number;
  bs_count: number;
  es_count: number;
  tv32_count: number;
  tv40_count: number;
  notes: string;
  building_ids: number[];
  worker_ids: number[];
  creator_id: number;
  school_id: number;
  driver_id: number | null;
  warehouse_id: number | null;
  type: string;
  performed_at_ts: number;
}

export interface PaycheckJSON extends ModelJSON {
  date_at_ts: number;
  type: string;
  notes: string | null;
  check_number: string;
  amount_cents: number;
  payee_id: number;
  creator_id: number;
}

export interface BonusBuckJSON extends ModelJSON {
  worker_ids: number[];
  creator_id: number;
  performed_at_ts: number;
  performed_at: Date;
  dollars: number;
  reason: string | null;
}

export interface SentBonusBuckJSON {
  worker_ids: number[];
  dollars: number;
  reason: string | null;
  performed_at: Date;
}

export interface SentPaycheckJSON {
  date_at_ts: number;
  type: string;
  notes: string | null;
  check_number: string | null;
  amount: string | null;
  payee_id: number | null;
}

export interface InstallJSON extends ModelJSON {
  product_id: number;
  room_id: number;
  student_id: number;
  futon_new: boolean;
  has_mesh: boolean;
  tv_hookup: boolean;
  futon_type: string;
  status: InstallStatus;
}

export interface PayscaleJSON extends ModelJSON {
  label: string;
  type: PayscaleType;
  group: PayscaleGroup;
  bl_cents: number;
  mf_cents: number;
  sr_cents: number;
  ft_cents: number;
  bs_cents: number;
  es_cents: number;
  tv_cents: number;
}

export interface StudentJSON extends ModelJSON {
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  school_id: number;
  installs_count: number;
}

export interface TicketJSON extends ModelJSON {
  room_id: number;
  text: string;
  creator_id: number;
  resolved_by_id: number | null;
  resolved_at_ts: number | null;
  resolution_notes: string | null;
}

export interface FieldNoteJSON extends ModelJSON {
  room_id: number;
  note: string;
  creator_id: number;
  image_file_name: string | null;
}

export interface InstallActionCreationJSON {
  install_status: InstallStatus;
  install_actions: Array<InstallActionJSON> | null;
}

export interface InstallActionJSON extends ModelJSON {
  set: boolean;
  type: 'uninstalled' | 'removed' | 'set_missing';
  creator_id: number;
  actor_id: number;
  install_id: number;
  splitter_ids: number[];
}

export type TicketRespJSON = {
  room: RoomJSON;
  ticket: TicketJSON;
};

export type DropoffRespJSON = {
  dropoff: DropoffJSON;
};

export type FridgeCleaningRespJSON = {
  fridge_cleaning: FridgeCleaningJSON;
};

export type PayscaleRespJSON = {
  payscale: PayscaleJSON;
};

export interface RoomJSON extends ModelJSON {
  installs_count: number;
  tickets_count: number;
  unresolved_tickets_count: number;
  field_notes_count: number;

  number: string;
  wing: string;
  building_id: number;
  locked: boolean | null;
  occupied: boolean;
}

export interface RoomLockingJSON extends ModelJSON {
  locked: boolean | null;
  room_id: number;
  set_by_id: number;
}

export interface RoomOccupationJSON extends ModelJSON {
  occupied: boolean;
  room_id: number;
  set_by_id: number;
}

export interface BuildingJSON extends ModelJSON {
  name: string;
  complex: string;
  area: string;
  shown_name: string;
  gmaps_link: string;
  notes: string;
  rooms_count: number;
  school_id: number;
}

export interface SchoolJSON extends ModelJSON {
  name: string;
  short: string;
}

export interface ProductJSON extends ModelJSON {
  name: string;
  short: string;
  short_short: string;
}

export interface WarehouseJSON extends ModelJSON {
  name: string;
}

export type UserJSONResponse = {
  currentUser: UserJSON;
};

export type CurrentUserJSONResponse = {
  currentUser: UserJSON | null;
};

export type ErrorResponse = {
  errors: Array<string>;
};

export type AllJSONResponse = {
  products: Array<ProductJSON>;
  buildings: Array<BuildingJSON>;
  rooms: Array<RoomJSON>;
  schools: Array<SchoolJSON>;
  installs: Array<InstallJSON>;
  students: Array<StudentJSON>;
  users: Array<UserJSON>;
  install_actions: Array<InstallActionJSON>;
  room_lockings: Array<RoomLockingJSON>;
  room_occupations: Array<RoomOccupationJSON>;
  tickets: Array<TicketJSON>;
  field_notes: Array<FieldNoteJSON>;
  payscales: Array<PayscaleJSON>;
  paychecks: Array<PaycheckJSON>;
  bonus_bucks: Array<BonusBuckJSON>;
  dropoffs: Array<DropoffJSON>;
  warehouses: Array<WarehouseJSON>;
  fridge_cleanings: Array<FridgeCleaningJSON>;
};

export type FilterJSON = {
  school_id: number;
  building_ids: number[];
  product_ids: number[];
  statuses: InstallStatus[];
  only_show_available_jobs: boolean;
};

export type TableName = keyof AllJSONResponse;

export function isErrorResponse<T>(json: T | ErrorResponse): json is ErrorResponse {
  return (json as ErrorResponse).errors !== undefined;
}
