import React from 'react';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Payscale from '../../models/Payscale';
import { getModels, router, store } from '../../constants';
import Typography from '@material-ui/core/Typography';
import groupBy from 'lodash/groupBy';

const clickRow = (payscale: Payscale) => {
  router.go(`/payscales/${payscale.id}`);
};

const renderPayscaleRow = (payscale: Payscale) => {
  return (
    <div
      key={'payscale_' + payscale.id}
      style={{ minHeight: 45 }}
      onClick={() => {
        clickRow(payscale);
      }}
      className="payscale-row list-group-item d-flex justify-content-between align-items-center p-1"
    >
      <div>
        <span className="fw-500 mr-2">{payscale.type}:</span>
        <span>{payscale.label}</span>
      </div>
      <ArrowForwardIcon />
    </div>
  );
};

let yeg = 12;
const renderGroup = (group: string, payscales: Payscale[]) => {
  return (
    <div key={`yeez_${yeg++}`} className="mt-3">
      <Typography style={{ fontWeight: 'bold' }}>{group}</Typography>
      {payscales.map(renderPayscaleRow)}
    </div>
  );
};

function PayscaleList() {
  const payscales = getModels('payscales');

  const currentUser = window.currentUser;

  const length = payscales.length;

  React.useEffect(() => {
    const title = `Payscales by group (${length})`;

    store.setState('app_bar', { title });
  }, [length]);

  if (!currentUser.admin) {
    router.go('/');
  }

  const groupedPayscales = groupBy(payscales, ps => ps.group);

  return (
    <div>
      <div id="payscales" className="mt-3">
        {Object.entries(groupedPayscales).map(e => renderGroup(e[0], e[1]))}
      </div>
    </div>
  );
}

export default PayscaleList;
