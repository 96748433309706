import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import { ProfileFormState } from '../../types/state_types';
import { store } from '../../constants';
import ApiRequest, { ApiRequestCallbacks } from '../../ApiRequest';
import { isErrorResponse, UserJSON } from '../../types/json_types';
import User from '../../models/User';
import ErrorAlert from '../ErrorAlert';

const { getState, setState, useStoreState } = store.getHelpers('profile_form');

const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
  event.preventDefault();

  const state = getState();

  if (state.working) {
    return;
  }

  setState({ working: true });

  const callbacks: ApiRequestCallbacks = {
    onSuccess: (resp: unknown) => {
      const user_json = resp as UserJSON;
      const heldUser = new User(user_json);

      Object.assign(window.currentUser, heldUser);

      store.forceUpdateEverything();
    },
    onError: json => {
      if (isErrorResponse(json)) {
        setState({ errors: json.errors });
      } else {
        alert('an error occurred');
      }
    },
    onDone: () => {
      setState({ working: false });
    },
  };
  const data = { user_id: state.user_id, profile: state };

  const request = new ApiRequest(`/api/basic_profile_update.json`, 'PATCH', callbacks, data);
  request.perform();

  console.log('onSubmit');
};

const onChange = <Key extends keyof ProfileFormState>(attr: Key, value: ProfileFormState[Key]) => {
  setState({ [attr]: value });
};

const onCloseErrorAlert = () => {
  setState({ errors: [] });
};

const BasicProfileForm = () => {
  const state = useStoreState();

  return (
    <div>
      <ErrorAlert errors={state.errors} closeAlert={onCloseErrorAlert} />
      <form onSubmit={onSubmit}>
        <Box display="flex" flexDirection="column">
          <TextField
            value={state.email}
            onChange={e => onChange('email', e.target.value)}
            label="Email*"
            variant="outlined"
            disabled
            className="mt-2"
          />

          <TextField
            value={state.first_name || ''}
            onChange={e => onChange('first_name', e.target.value)}
            label="First name*"
            variant="outlined"
            className="mt-2"
          />

          <TextField
            value={state.last_name || ''}
            onChange={e => onChange('last_name', e.target.value)}
            label="Last name*"
            variant="outlined"
            className="mt-2"
          />

          <TextField
            value={state.username || ''}
            onChange={e => onChange('username', e.target.value)}
            label="Username*"
            variant="outlined"
            className="mt-2"
          />

          <TextField
            value={state.phone || ''}
            onChange={e => onChange('phone', e.target.value)}
            label="Phone number"
            variant="outlined"
            className="mt-2"
          />

          <FormControlLabel
            className="mt-2"
            control={
              <Checkbox
                checked={state.agreed_to_terms}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange('agreed_to_terms', e.target.checked)}
                name="checkedB"
                color="primary"
              />
            }
            label="I agree to terms linked below"
          />

          <div>
            <Link
              href="http://dormsdirect.com/ica.html"
              target="_blank"
              className="mb-3 mt-1 d-flex align-items-center"
            >
              <div>INDEPENDENT CONTRACTOR AGREEMENT</div>
              <OpenInNewIcon className="ml-1" />
            </Link>
          </div>

          <Button
            disabled={state.working}
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            className="mt-2"
            disableElevation
          >
            Save
          </Button>

          <div>* required</div>
        </Box>
      </form>
    </div>
  );
};

export default BasicProfileForm;
