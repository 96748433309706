import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AppBarIcon from './AppBarIcon';
import AppBarRight from './AppBarRight';
import AppBarTitle from './AppBarTitle';
import AppBarSubtitle from './AppBarSubtitle';
import AppBarSubsubtitle from './AppBarSubsubtitle';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

// #333
export default function AppBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MuiAppBar position="static">
        <Toolbar>
          <AppBarIcon />
          <div className={classes.title}>
            <Typography style={{ textAlign: 'center' }}>
              <AppBarTitle />
            </Typography>
            <AppBarSubtitle />
            <AppBarSubsubtitle />
          </div>
          <AppBarRight />
        </Toolbar>
      </MuiAppBar>
    </div>
  );
}
