import React from 'react';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';
import { intersperseBuildings } from '../../app_util';
import { getModels, store } from '../../constants';
import { onScroll, clearListCache } from './job_helpers';
import Building from '../../models/Building';
import { getFilteredRooms } from '../filter_helpers';
import CenterAll from '../CenterAll';
import SchoolSelectMeh from './SchoolSelectMeh';
import BuildingRow from './BuildingRow';
import RoomRow from './RoomRow';
import clsx from 'clsx';
import { getStatsForBuilding } from '../../stats_helper';
import { View } from '../../types/state_types';

interface JobsViewProps {
  viewShown: View;
}

let rendered = false;

export default function JobsView(props: JobsViewProps) {
  const { viewShown } = props;
  const filter = window.App.filter;

  React.useEffect(() => {
    return () => {
      clearListCache();
      rendered = false;
    };
  }, []);

  if (viewShown === 'Jobs') {
    rendered = true;
  } else if (!rendered) {
    return null;
  }

  if (filter) {
    const onListPage = viewShown === 'Jobs';
    const classes = clsx('List', { hidden: viewShown === 'JobDetail' });

    const school = filter.getSchool();

    const rooms = getFilteredRooms(filter);
    const buildings = getModels('buildings').filter(b => b.school_id === school.id);

    const rooms_w_buildings = intersperseBuildings(rooms, buildings);

    if (onListPage) {
      let subsubtitle: string;

      if (filter.building_ids.length) {
        subsubtitle = `${rooms.length} filtered rooms in list`;
      } else {
        subsubtitle = `${rooms.length} rooms in list`;
      }

      setTimeout(() => {
        store.setState('app_bar', {
          title: school.name,
          subtitle: (rooms_w_buildings[0] as Building).shown_name,
          subsubtitle: subsubtitle,
        });
      }, 1);
    }

    return (
      <List
        className={classes}
        onScroll={onScroll}
        height={window.innerHeight - 65}
        itemCount={rooms_w_buildings.length}
        itemSize={50}
        width="100%"
      >
        {({ index, style }: ListChildComponentProps) => {
          const obj = rooms_w_buildings[index];
          if (obj.isBuilding()) {
            return BuildingRow(index, style, obj, getStatsForBuilding(obj, rooms));
          } else {
            return RoomRow(index, style, obj);
          }
        }}
      </List>
    );
  } else {
    setTimeout(() => {
      store.setState('app_bar', {
        title: 'Jobs',
        subtitle: null,
        subsubtitle: null,
      });
    }, 1);

    return (
      <CenterAll className="mt-4">
        <SchoolSelectMeh />
      </CenterAll>
    );
  }
}
