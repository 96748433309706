import { store } from './constants';

function updateOnlineStatus() {
  store.setState('main', {
    isOnline: navigator.onLine,
  });
}

window.addEventListener('online', updateOnlineStatus);
window.addEventListener('offline', updateOnlineStatus);
