import ApplicationModel from './ApplicationModel';
import { FridgeCleaningJSON } from '../types/json_types';
import { ClassName } from '../types/types';
import User from './User';
import { getModels } from '../constants';
import { findModelById } from '../app_util';
import Warehouse from './Warehouse';
import School from './School';
import { areSameDay } from '../util';

export type FridgeBrand = 'SnackMate' | 'Danby' | 'MicroFridge';
export const fridge_brands: FridgeBrand[] = ['SnackMate', 'Danby', 'MicroFridge'];

export type FridgeType = 'clean' | 'repair';
export const fridge_types: FridgeType[] = ['clean', 'repair'];

export type FridgeProduct = 'LockBox' | 'Fridge' | 'Futon';
export const product_types: FridgeProduct[] = ['LockBox', 'Fridge', 'Futon'];

export type FridgeStatus = 'in_service' | 'needs_repair' | 'in_storage' | 'retired';
export const fridge_statuses: FridgeStatus[] = ['in_service', 'needs_repair', 'in_storage', 'retired'];

class FridgeCleaning extends ApplicationModel {
  creator_id: number;
  creator: User;
  worker_id: number;
  worker: User;
  type: FridgeType;
  performed_at_ts: number;
  performed_at: Date;
  warehouse_id: number | null;
  warehouse: Warehouse | null;
  school_id: number | null;
  school: School | null;
  building_id: number | null;
  room_id: number | null;
  room_str: string | null;
  serial_number: string | null;
  status: FridgeStatus;
  product_type: FridgeProduct;
  notes: string | null;
  brand: FridgeBrand | null;
  image_file_name: string | null;

  constructor(json: FridgeCleaningJSON) {
    super(json);

    this.type = json.type;
    const allUsers = getModels('users');
    this.creator_id = json.creator_id;
    this.creator = findModelById(allUsers, json.creator_id);
    this.worker_id = json.worker_id;
    this.worker = findModelById(allUsers, json.worker_id);
    this.performed_at_ts = json.performed_at_ts;
    this.performed_at = new Date(json.performed_at_ts);
    this.warehouse_id = json.warehouse_id;
    this.warehouse = json.warehouse_id ? findModelById(getModels('warehouses'), json.warehouse_id) : null;
    this.school_id = json.school_id;
    this.school = json.school_id ? findModelById(getModels('schools'), json.school_id) : null;
    this.building_id = json.building_id;
    this.room_id = json.room_id;
    this.room_str = json.room_str;
    this.serial_number = json.serial_number;
    this.status = json.status;
    this.product_type = json.product_type;
    this.notes = json.notes;
    this.brand = json.brand;
    this.image_file_name = json.image_file_name;
  }

  getClassName() {
    const className: ClassName = 'FridgeCleaning';
    return className;
  }

  getBuilding() {
    return this.building_id ? findModelById(getModels('buildings'), this.building_id) : null;
  }

  getWarehouse() {
    return this.warehouse_id ? findModelById(getModels('warehouses'), this.warehouse_id) : null;
  }

  getLocationObject() {
    return this.warehouse_id ? this.warehouse : this.building_id ? this.getBuilding() : this.school;
  }

  getLocationText() {
    const location = this.getLocationObject();

    if (location?.isBuilding()) {
      return `${location.shown_name} - ${location.school.short}`;
    } else if (location?.isSchool()) {
      return location.name;
    } else if (location?.isWarehouse()) {
      return location.name;
    } else {
      return 'unknown location';
    }
  }

  getKey() {
    const location = this.getLocationObject();

    if (location) {
      return `${location.getClassName()}${location.id}`;
    } else {
      return '??';
    }
  }

  dayMonth() {
    return `${this.performed_at.getMonth() + 1}/${this.performed_at.getDate()}`;
  }

  isSameGroup(fc: FridgeCleaning) {
    return (
      fc.worker_id === this.worker_id && fc.getKey() === this.getKey() && areSameDay(this.performed_at, fc.performed_at)
    );
  }
}

export default FridgeCleaning;
