import React from 'react';
import Install from './models/Install';
import { InstallStatus } from './types/types';
import Button from '@material-ui/core/Button';
import { colors } from './constants';

export const getColor = (status: InstallStatus) => {
  const color = colors[status];

  if (color) {
    return color;
  } else {
    throw new Error(`couldnt determine short color for status ${status}`);
  }
};

export const renderShort = (install: Install) => {
  const color = getColor(install.status);
  const style = { color };

  const short = install.product.short;

  return (
    <div style={style} key={`short_${install.id}`} className="pl-2">
      {short}
    </div>
  );
};

export const renderInstallButton = (install: Install, onClick: (install: Install) => void) => {
  const key = `button_install_${install.id}`;

  const options = install.getOptions();

  const id = `install_${install.id}`;

  const color = getColor(install.status);

  const style = {
    color: color,
    borderColor: color,
  };

  return (
    <Button
      className="mr-3 mt-2"
      style={style}
      key={key}
      id={id}
      onClick={() => {
        onClick(install);
      }}
      variant="outlined"
    >
      {install.product.short}
      {options.length ? <span>{options.join('-')}</span> : null}
    </Button>
  );
};
