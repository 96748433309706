import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Divider from '@material-ui/core/Divider';
import Paycheck from '../../models/Paycheck';
import { store, getModels } from '../../constants';
import UserSelect from '../UserSelect';
import Typography from '@material-ui/core/Typography';
import DatePicker from '../DatePicker';
import { PaycheckModalState } from '../../types/state_types';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ErrorAlert from '../ErrorAlert';
import PaycheckModalSaveButton from './PaycheckSaveButton';
import PaycheckModalDestroyButton from './PaycheckDestroyButton';

const { setState, useStoreState } = store.getHelpers('paycheck_modal');

function getModalStyle() {
  return {
    zIndex: 99999,
    top: '0',
    left: '0',
    outline: '0',
    width: '100%',
    border: 'none',
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.background.paper,
      padding: 5,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

const handleClose = () => {
  const state = Paycheck.getModalState(null, false, null);
  setState(state);
};

const onChangePayee = (user_id: number | null) => {
  if (!user_id) {
    user_id = getModels('users')[0].id;
  }

  setState({ payee_id: user_id });
};

const onChangeDate = (date: Date | null) => {
  setState({ date_at: date || new Date() });
};

const onChange = <Key extends keyof PaycheckModalState>(attr: Key, value: PaycheckModalState[Key]) => {
  setState({ [attr]: value });
};

const closeErrorAlert = () => {
  setState({ errors: [] });
};

const PaycheckModal = () => {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const modalStyle = getModalStyle();

  const state = useStoreState();

  return (
    <div>
      <Modal open={state.open} onClose={handleClose}>
        <div style={modalStyle} className={classes.paper}>
          <Typography variant="h6" style={{ textAlign: 'center' }}>
            Add Paycheck
          </Typography>

          <Divider className="mb-2" />

          <Box flexGrow="1" style={{ overflow: 'scroll' }}>
            <ErrorAlert errors={state.errors} closeAlert={closeErrorAlert} className="mt-2 mb-3" />

            <UserSelect label="Change payee" user_id={state.payee_id} onChange={onChangePayee} />

            <div className="mt-3">
              <DatePicker label="Date received" date={state.date_at} onChange={onChangeDate} />
            </div>

            <div className="mt-3">
              <TextField
                label="Amount"
                type="number"
                name="amount"
                placeholder="100.00"
                value={state.amount || ''}
                onChange={e => onChange('amount', parseFloat(e.target.value))}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>

            {state.type === 'check' ? (
              <div className="mt-3">
                <TextField
                  label="Check number"
                  type="textarea"
                  name="check_number"
                  onChange={e => onChange('check_number', e.target.value)}
                  value={state.check_number || ''}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            ) : null}

            <FormControl variant="outlined" className="mt-4">
              <InputLabel>Type</InputLabel>
              <Select value={state.type} onChange={e => onChange('type', e.target.value as string)} label="Type">
                <MenuItem value="check">Check</MenuItem>
                <MenuItem value="cash">Cash</MenuItem>
              </Select>
            </FormControl>

            <div className="my-3">
              <TextField
                style={{ width: '100%' }}
                className="mt-2"
                label="Notes"
                multiline
                rows="3"
                value={state.notes || ''}
                onChange={e => onChange('notes', e.target.value)}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </Box>

          <Divider className="mb-2" />

          <Box display="flex" justifyContent="space-between">
            <Button onClick={handleClose} variant="outlined" size="large" disabled={state.working}>
              Cancel
            </Button>

            {state.id ? <PaycheckModalDestroyButton paycheck_id={state.id} working={state.working} /> : null}

            <PaycheckModalSaveButton id={state.id} working={state.working} />
          </Box>
        </div>
      </Modal>
    </div>
  );
};

export default React.memo(PaycheckModal);
