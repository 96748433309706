import ApplicationModel from './ApplicationModel';
import { TableName, BonusBuckJSON } from '../types/json_types';
import { ClassName } from '../types/types';
import { BonusBuckModalState } from '../types/state_types';
import { findModelById } from '../app_util';
import { getModels } from '../constants';
import User from './User';

class BonusBuck extends ApplicationModel {
  worker_ids: number[];
  creator_id: number;
  creator: User;
  dollars: number;
  reason: string | null;
  performed_at_ts: number;
  performed_at: Date;

  constructor(json: BonusBuckJSON) {
    super(json);
    this.worker_ids = json.worker_ids;
    this.creator_id = json.creator_id;
    this.dollars = json.dollars;
    this.performed_at_ts = json.performed_at_ts;
    this.performed_at = new Date(json.performed_at_ts);
    this.reason = json.reason;
    const allUsers = getModels('users');
    this.creator = findModelById(allUsers, this.creator_id);
  }

  getWorkers() {
    const allUsers = getModels('users');
    return this.worker_ids.map(wid => findModelById(allUsers, wid));
  }

  getClassName() {
    const className: ClassName = 'BonusBuck';
    return className;
  }

  individualDollars() {
    return this.dollars / this.worker_ids.length;
  }

  static getTableName(): TableName {
    return 'bonus_bucks';
  }

  static getModalState(bonus_buck: BonusBuck | null, open: boolean): BonusBuckModalState {
    const id = bonus_buck?.id || null;
    const dollars = bonus_buck?.dollars || 0;
    const worker_ids: number[] = bonus_buck?.worker_ids || [];
    const reason = bonus_buck?.reason || null;
    const working = false;
    const errors: string[] = [];
    const performed_at = bonus_buck?.performed_at || new Date();

    return { id, reason, dollars, open, worker_ids, performed_at, working, errors };
  }
}

export default BonusBuck;
