import ApplicationModel from './ApplicationModel';
import { FieldNoteJSON, TableName } from '../types/json_types';
import { ClassName } from '../types/types';
import { getModels } from '../constants';
import { findModelById } from '../app_util';
import User from './User';
import strftime from 'strftime';
import { FieldNoteModalState } from '../types/state_types';
import { getImageUrl } from '../helpers/paperclip_helpers';

class FieldNote extends ApplicationModel {
  note: string;
  creator_id: number;
  creator: User;
  room_id: number;
  image_file_name: string | null;

  constructor(json: FieldNoteJSON) {
    super(json);

    this.note = json.note;
    this.creator_id = json.creator_id;
    this.creator = findModelById(getModels('users'), this.creator_id);
    this.room_id = json.room_id;
    this.image_file_name = json.image_file_name;
  }

  hasImage() {
    return !!this.image_file_name;
  }

  getThumb() {
    if (this.image_file_name) {
      return getImageUrl(this.id, 'field_notes', 'thumb', this.image_file_name);
    } else {
      return null;
    }
  }

  getText() {
    const creatorName = this.creator.username;
    const dateStr = `on ${strftime('%b %e %Y @ %l:%M %p', this.created_at)}`;

    return `"${this.note}" by ${creatorName} ${dateStr}`;
  }

  getClassName() {
    const className: ClassName = 'FieldNote';
    return className;
  }

  getTableName() {
    return FieldNote.getTableName();
  }

  static getTableName(): TableName {
    return 'field_notes';
  }

  static getModalState(field_note: FieldNote | null, open: boolean): FieldNoteModalState {
    const id = field_note?.id || null;
    const note = field_note?.note || '';
    const image_file_name = field_note?.image_file_name || null;
    const working = false;

    return { id, note, working, open, image_file_name };
  }
}

export default FieldNote;
