import React from 'react';
import TextField from '@material-ui/core/TextField';

type Props<T extends string> = {
  options: T[];
  value: T | null;
  onChange: (value: T | null) => void;
  label: string;
  nullLabel: string;
};

function GenericStringSelectNull<T extends string>(props: Props<T>): JSX.Element {
  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const val = e.target.value as string;

    if (val === 'null') {
      props.onChange(null);
    } else {
      props.onChange(val as T);
    }
  }

  return (
    <TextField
      variant="outlined"
      SelectProps={{
        native: true,
      }}
      select
      label={props.label}
      value={props.value || 'null'}
      onChange={handleOnChange}
    >
      <option value="null">{props.nullLabel}</option>
      {props.options.map(option => (
        <option key={`generic_select_${option}`} value={option}>
          {option}
        </option>
      ))}
    </TextField>
  );
}

export default GenericStringSelectNull;
