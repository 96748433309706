import React from 'react';

import { centsToDollarString } from '../../util';
import { ShortShortMoneyStats, ShortShortMoneyNumbers } from '../../stats_helper';
import { colors, short_shorts } from '../../constants';

let key1 = 1;

const renderMoneyTd = (cents: number) => {
  const key = 'brah' + key1++;

  return <td key={key}>{centsToDollarString(cents)}</td>;
};

const renderTd = (count: number) => {
  const key = 'brah' + key1++;

  return <td key={key}>{count}</td>;
};

interface MoneyTableProps {
  stats: ShortShortMoneyNumbers;
  money: ShortShortMoneyStats;
}

function MoneyTable(props: MoneyTableProps) {
  const { money, stats } = props;

  return (
    <React.Fragment>
      <table className="muhtable">
        <thead>
          <tr>
            <th></th>
            {short_shorts.map(s => (
              <th key={key1++}>{s}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            {short_shorts.map(s => {
              return renderMoneyTd(money[s].total_amount);
            })}
          </tr>
          <tr style={{ color: colors.uninstalled }}>
            <td></td>
            {short_shorts.map(s => {
              return renderMoneyTd(money[s].uninstalled_amount);
            })}
          </tr>
          <tr style={{ color: colors.removed }}>
            <td></td>
            {short_shorts.map(s => {
              return renderMoneyTd(money[s].removed_amount);
            })}
          </tr>

          <tr style={{ color: colors.uninstalled }}>
            <td></td>
            {short_shorts.map(s => {
              return renderTd(stats[s].uninstalled);
            })}
          </tr>
          <tr style={{ color: colors.removed }}>
            <td></td>
            {short_shorts.map(s => {
              return renderTd(stats[s].removed);
            })}
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
}

export default MoneyTable;
