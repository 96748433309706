import { HTTPMethod, PlainObject } from './types/types';
import { makeRequest } from './fetchHelpers';

export interface ApiRequestCallbacks {
  onLoading?: () => void;
  onSuccess: (json: unknown) => void;
  onDone?: () => void;
  onError: (json: unknown) => void;
}

enum Status {
  Idle,
  Loading,
  Success,
  Error,
}

class ApiRequest {
  path: string;
  method: HTTPMethod;
  http_status?: number;
  payload?: PlainObject;
  success?: boolean;
  error?: boolean;
  status: Status;
  callbacks: ApiRequestCallbacks;

  constructor(path: string, method: HTTPMethod, callbacks: ApiRequestCallbacks, payload?: unknown) {
    this.path = path;
    this.status = Status.Idle;
    this.method = method;
    this.callbacks = callbacks;
    this.payload = payload as PlainObject;
    this.http_status = undefined;
  }

  isLoading() {
    return this.status === Status.Loading;
  }

  async perform() {
    this.status = Status.Loading;

    if (this.callbacks.onLoading) {
      this.callbacks.onLoading();
    }

    const result = await makeRequest(this.path, this.method, this.payload);

    const { json, response } = result;

    this.status = response.status;

    if (this.status >= 200 && this.status < 300) {
      this.status = Status.Success;

      this.callbacks.onSuccess(json);
    } else if (this.status >= 400 && this.status < 600) {
      this.status = Status.Error;

      this.callbacks.onError(json);
    }

    if (this.callbacks.onDone) {
      this.callbacks.onDone();
    }
  }
}

export default ApiRequest;
