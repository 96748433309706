import ApplicationModel from './ApplicationModel';
import { RoomJSON, TableName } from '../types/json_types';
import { ClassName, InstallStatus } from '../types/types';
import Building from './Building';
import { findModelById, filterModelsByAttr } from '../app_util';
import { isPresent } from '../util';
import Install from './Install';
import Student from './Student';
import { getModels } from '../constants';

class Room extends ApplicationModel {
  installs_count: number;
  tickets_count: number;
  unresolved_tickets_count: number;
  field_notes_count: number;

  number: string;
  wing: string;
  building_id: number;
  building: Building;
  installs!: Array<Install>;
  students!: Array<Student>;
  locked: boolean | null;
  previousLocked?: boolean | null;
  occupied: boolean;

  constructor(json: RoomJSON) {
    super(json);

    this.installs_count = json.installs_count;
    this.tickets_count = json.tickets_count;
    this.unresolved_tickets_count = json.unresolved_tickets_count;
    this.field_notes_count = json.field_notes_count;

    this.number = json.number;
    this.wing = json.wing;
    this.building_id = json.building_id;
    this.locked = json.locked;
    this.occupied = json.occupied;
    this.building = findModelById(getModels('buildings'), this.building_id);
  }

  showName(sep = '-') {
    return `${this.wingName()} ${sep} ${this.building.shown_name}`;
  }

  getTickets() {
    return filterModelsByAttr(getModels('tickets'), 'room_id', this.id);
  }

  getFieldNotes() {
    return filterModelsByAttr(getModels('field_notes'), 'room_id', this.id);
  }

  getRoomLockings() {
    return filterModelsByAttr(getModels('room_lockings'), 'room_id', this.id);
  }

  getRoomOccupations() {
    return filterModelsByAttr(getModels('room_occupations'), 'room_id', this.id);
  }

  wingName() {
    if (isPresent(this.wing)) {
      return `#${this.number}, ${this.wing}`;
    } else {
      return '#' + this.number;
    }
  }

  hasAvailableJobs() {
    return !!this.installs.find(i => i.isAvailable());
  }

  getClassName() {
    const className: ClassName = 'Room';
    return className;
  }

  getInstallActions() {
    return this.installs.flatMap(i => i.getInstallActions());
  }

  getActivity() {
    return [
      this.getInstallActions().map(ia => ia.toActivity()),
      this.getRoomLockings().map(rl => rl.toActivity()),
      this.getRoomOccupations().map(ro => ro.toActivity()),
    ].flat();
  }

  hasProducts(product_ids: number[]) {
    return !!this.installs.find(i => product_ids.includes(i.product.id));
  }

  hasStatuses(statuses: InstallStatus[]) {
    return !!this.installs.find(i => statuses.includes(i.status));
  }

  hasProductsAndStatuses(product_ids: number[], statuses: InstallStatus[]) {
    return !!this.installs.find(i => product_ids.includes(i.product.id) && statuses.includes(i.status));
  }

  static getTableName(): TableName {
    return 'rooms';
  }
}

export default Room;
