import React from 'react';
import { store, router } from '../../constants';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const openDrawer = () => {
  store.setState('drawer', { open: true });
};

const clickBack = (href: string) => () => {
  router.go(href);
};

const AppBarIcon = () => {
  const { drill_href } = store.useStore('app_bar');

  return (
    <div className="mr-2">
      {drill_href ? (
        <IconButton onClick={clickBack(drill_href)} edge="start" color="inherit" disableRipple>
          <ArrowBackIosIcon />
        </IconButton>
      ) : (
        <IconButton onClick={openDrawer} edge="start" color="inherit" disableRipple>
          <MenuIcon />
        </IconButton>
      )}
    </div>
  );
};

export default React.memo(AppBarIcon);
