import React from 'react';
import Button from '@material-ui/core/Button';
import { store, getModels } from '../../constants';
import ApiRequest, { ApiRequestCallbacks } from '../../ApiRequest';
import { isErrorResponse } from '../../types/json_types';
import { findModelById } from '../../app_util';
import red from '@material-ui/core/colors/red';
import { spliceFromArray } from '../../util';
import BonusBuck from '../../models/BonusBuck';

const { setState } = store.getHelpers('bonus_buck_modal');

const destroyBonusBuck = (bonus_buck_id: number, working: boolean) => {
  if (working) {
    return;
  }

  if (window.confirm('are you sure?')) {
    const callbacks: ApiRequestCallbacks = {
      onLoading: () => setState({ working: true }),
      onSuccess: () => {
        const allBonusBucks = getModels('bonus_bucks');
        const bonus_buck = findModelById(allBonusBucks, bonus_buck_id);
        spliceFromArray(allBonusBucks, bonus_buck);

        store.forceUpdate('bonus_bucks_page');
        setState(BonusBuck.getModalState(null, false));
      },
      onError: (json: unknown) => {
        if (isErrorResponse(json)) {
          setState({ errors: json.errors });
        } else {
          alert('some funky error occurred');
        }
      },
      onDone: () => setState({ working: false }),
    };

    const request = new ApiRequest(`/api/bonus_bucks/${bonus_buck_id}.json`, 'DELETE', callbacks);
    request.perform();
  }
};

interface BonusBuckDestroyButtonProps {
  working: boolean;
  paycheck_id: number;
}

const BonusBuckDestroyButton = (props: BonusBuckDestroyButtonProps) => {
  return (
    <Button
      style={{ color: red[900], borderColor: red[900] }}
      onClick={() => destroyBonusBuck(props.paycheck_id, props.working)}
      variant="outlined"
      size="large"
      disabled={props.working}
    >
      Destroy
    </Button>
  );
};

export default BonusBuckDestroyButton;
