import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { store, getModels } from '../../constants';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { FieldNoteJSON } from '../../types/json_types';
import FieldNote from '../../models/FieldNote';
import FieldNoteDestroyButton from './FieldNoteDestroyButton';
import FieldNoteSaveButton from './FieldNoteSaveButton';
import FieldNoteModalPhotoRow from './FieldNoteModalPhotoRow';

const { setState: setModalState, useStoreState } = store.getHelpers('field_note_modal');

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: '0',
    width: '100%',
    border: 'none',
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: 0,
    },
  }),
);

function clickSubmit(note: string, working: boolean) {
  if (working) {
    return;
  }

  const room = store.getState('job_detail').room;

  if (!room) {
    throw new Error('no room, wtf');
  }

  const formData = new FormData();

  const fileInput = document.querySelector('input#image') as HTMLInputElement;
  if (fileInput?.files?.length) {
    formData.append('image', fileInput.files[0]);
  }

  formData.append('note', note);
  formData.append('room_id', room.id.toString());

  setModalState({ working: true });
  fetch('/api/field_notes.json', {
    method: 'POST',
    body: formData,
  })
    .then(response => response.json())
    .then((json: unknown) => {
      const field_note = new FieldNote(json as FieldNoteJSON);
      getModels('field_notes').unshift(field_note);
      const appState = store.getFullStore();

      appState.field_note_modal = FieldNote.getModalState(null, false);

      setModalState({ working: false });
      store.forceUpdate('job_detail');
    })
    .catch(() => {
      alert('an error occurred');
      setModalState({ working: false });
    });
}

const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setModalState({ note: event.target.value });
};

const handleClose = () => {
  setModalState({ open: false, note: '', working: false });
};

const renderPhotoRow = (image_file_name: string | null, id: number | null, working: boolean): JSX.Element => {
  if (image_file_name && id) {
    return <FieldNoteModalPhotoRow {...{ image_file_name, id, working }} />;
  } else {
    return (
      <div style={{ position: 'relative' }} className="mt-3">
        <label
          htmlFor="image"
          style={{ position: 'absolute', left: 0, top: -3 }}
          className="file-cover-btn MuiButton-root MuiButton-outlined MuiButtonBase-root MuiButton-outlinedSizeSmall"
        >
          Attach a photo
        </label>

        <input
          type="file"
          id="image"
          name="image"
          accept="image/png, image/jpeg"
          style={{ marginLeft: 60, outline: 'none' }}
        />
      </div>
    );
  }
};
const FieldNoteModal = () => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const { id, open, working, note, image_file_name } = useStoreState();

  const title = id ? 'Edit field note' : 'Add a field note';

  return (
    <Modal open={open} onClose={handleClose}>
      <div style={modalStyle} className={classes.paper}>
        <div className="px-4 my-2">
          <Typography variant="h6" className="d-flex">
            {title}
          </Typography>

          <TextField
            style={{ width: '100%' }}
            className="mt-2"
            id="outlined-multiline-static"
            multiline
            rows="4"
            value={note}
            onChange={onChange}
            variant="outlined"
          />

          {renderPhotoRow(image_file_name, id, working)}
        </div>
        <div className="d-flex justify-content-between align-items-center mx-4 my-3">
          <Button onClick={handleClose} variant="outlined" disabled={working}>
            Cancel
          </Button>

          {id ? <FieldNoteDestroyButton field_note_id={id} working={working} /> : null}

          {id ? (
            <FieldNoteSaveButton id={id} working={working} />
          ) : (
            <Button onClick={() => clickSubmit(note, working)} variant="outlined" disabled={working}>
              Create
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(FieldNoteModal);
