import Payscale from '../models/Payscale';
import { PayscaleModalState } from '../types/state_types';
import { store } from '../constants';
import clone from 'lodash/clone';

export const getPayscaleModalState = (payscale: Payscale, open: boolean): PayscaleModalState => {
  return {
    open,
    working: false,
    errors: [],

    id: payscale.id,
    label: payscale.label,
    short_cents: clone(payscale.short_cents),
    group: payscale.group,
    type: payscale.type,
  };
};

export const getBlankPayscaleModalState = (): PayscaleModalState => {
  return {
    open: false,
    working: false,
    errors: [],
    id: 9998,
    label: 'nada',
    short_cents: {},
    group: 'basic',
    type: 'dropoff',
  };
};

export const setPayscaleModalState = (payscale: Payscale, open: boolean) => {
  const state = getPayscaleModalState(payscale, open);
  store.setState('payscale_modal', state);
};
