import React from 'react';
import { getModels } from '../../constants';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { store } from '../../constants';
import BonusBuck from '../../models/BonusBuck';
import BonusBuckModal from './BonusBuckModal';
import strftime from 'strftime';
import { andJoin, isPresent } from '../../util';
import { Box } from '@material-ui/core';
import sortBy from 'lodash/sortBy';

const { setState } = store.getHelpers('bonus_buck_modal');

const clickAdd = () => {
  const state = BonusBuck.getModalState(null, true);

  setState(state);
};

const clickRow = (bb: BonusBuck) => {
  const currentUser = window.currentUser;
  if (currentUser.admin || currentUser.id === bb.creator_id) {
    const state = BonusBuck.getModalState(bb, true);

    setState(state);
  }
};

const renderBB = (bb: BonusBuck) => {
  const date = strftime('%-m/%-d/%y', bb.performed_at);
  const users = andJoin(bb.getWorkers().map(u => u.username));
  const reason = isPresent(bb.reason) ? bb.reason : 'reasons';

  return (
    <Box
      key={`bb_${bb.id}`}
      className="job-row"
      style={{ minHeight: 50 }}
      display="flex"
      alignItems="center"
      onClick={() => clickRow(bb)}
    >
      {users} received ${bb.dollars} on {date} for {reason}
    </Box>
  );
};

const BonusBucks = () => {
  store.useStore('bonus_bucks_page');

  const bonus_bucks = sortBy(getModels('bonus_bucks'), bb => -bb.performed_at);

  return (
    <div className="mt-3">
      <Typography variant="h6" gutterBottom className="d-flex justify-content-between align-items-center">
        Bonus Bucks ({bonus_bucks.length})
        <Button onClick={clickAdd} size="small" variant="outlined" className="my-2">
          Add Bonus Buck
        </Button>
      </Typography>

      {bonus_bucks.map(renderBB)}

      <BonusBuckModal />
    </div>
  );
};

export default BonusBucks;
