import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { store, getModels } from '../../constants';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ApiRequest, { ApiRequestCallbacks } from '../../ApiRequest';
import { TicketRespJSON } from '../../types/json_types';
import Ticket from '../../models/Ticket';
import { findModelById } from '../../app_util';
import { getTicketModalState } from '../../helpers/ticket_helpers';

const { setState: setModalState, useStoreState } = store.getHelpers('ticket_modal');

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: 0,
    width: '100%',
    border: 'none',
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: 0,
    },
  }),
);

const clickUpdate = (id: number, text: string, working: boolean) => {
  if (working) {
    return;
  }

  const callbacks: ApiRequestCallbacks = {
    onLoading: () => setModalState({ working: true }),
    onSuccess: (resp: unknown) => {
      const allTickets = getModels('tickets');
      const ticket = findModelById(allTickets, id);
      const room = findModelById(getModels('rooms'), ticket.room_id);

      const json = resp as TicketRespJSON;

      const nextTicket = new Ticket(json.ticket);
      room.unresolved_tickets_count = json.room.unresolved_tickets_count;
      room.tickets_count = json.room.tickets_count;

      Object.assign(ticket, nextTicket);

      store.getFullStore().ticket_modal = getTicketModalState(null, false);

      store.forceUpdate('job_detail');
    },
    onError: () => {
      alert('an error occurred');
    },
    onDone: () => setModalState({ working: false }),
  };

  const data = {
    text: text,
  };

  const request = new ApiRequest(`/api/tickets/${id}.json`, 'PATCH', callbacks, data);
  request.perform();
};

function clickSubmit(text: string, working: boolean) {
  if (working) {
    return;
  }

  const room = store.getState('job_detail').room;

  if (!room) {
    throw new Error('no room, wtf');
  }

  const callbacks: ApiRequestCallbacks = {
    onLoading: () => setModalState({ working: true }),
    onSuccess: (resp: unknown) => {
      const json = resp as TicketRespJSON;
      const ticket = new Ticket(json.ticket);
      getModels('tickets').unshift(ticket);
      const appState = store.getFullStore();

      room.tickets_count = json.room.tickets_count;
      room.unresolved_tickets_count = json.room.unresolved_tickets_count;

      appState.ticket_modal = getTicketModalState(null, false);

      store.forceUpdate('job_detail');
    },
    onError: () => {
      alert('an error occurred');
    },
    onDone: () => setModalState({ working: false }),
  };

  const data = {
    room_id: room.id,
    text: text,
  };

  const request = new ApiRequest('/api/tickets.json', 'POST', callbacks, data);
  request.perform();
}

const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setModalState({ text: event.target.value });
};

const handleClose = () => {
  setModalState({ open: false, text: '', working: false });
};

const TicketModal = () => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const { id, open, working, text } = useStoreState();

  const title = id ? 'Update needs attention ticket' : 'Why does this room need attention?';

  return (
    <Modal open={open} onClose={handleClose}>
      <div style={modalStyle} className={classes.paper}>
        <div className="px-4 my-2">
          <Typography variant="h6" className="d-flex">
            {title}
          </Typography>

          <TextField
            style={{ width: '100%' }}
            className="mt-2"
            id="outlined-multiline-static"
            multiline
            rows="4"
            value={text}
            onChange={onChange}
            variant="outlined"
          />
        </div>
        <div className="d-flex justify-content-between align-items-center mx-4 my-3">
          <Button onClick={handleClose} variant="outlined" disabled={working}>
            Cancel
          </Button>

          {id ? (
            <Button onClick={() => clickUpdate(id, text, working)} variant="outlined" disabled={working}>
              Update
            </Button>
          ) : (
            <Button onClick={() => clickSubmit(text, working)} variant="outlined" disabled={working}>
              Submit
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(TicketModal);
