import School from '../../models/School';
import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { getModels } from '../../constants';
import Warehouse from '../../models/Warehouse';

const renderSchoolOption = (school: School) => {
  const key = `school_${school.id}_option`;

  const value = `School${school.id}`;

  return (
    <option value={value} key={key} data-type="school">
      {school.name}
    </option>
  );
};

const renderWarehouseOption = (warehouse: Warehouse) => {
  const key = `warehouse_${warehouse.id}_option`;

  const value = `Warehouse${warehouse.id}`;

  return (
    <option value={value} key={key} data-type="warehouse">
      {warehouse.name}
    </option>
  );
};

type LocationSelectProps = {
  onChangeWarehouse: (school_id: number) => void;
  onChangeSchool: (school_id: number) => void;
  itemClass: 'Warehouse' | 'School';
  itemId: number;
};

let cnt = 1;

const LocationSelect = (props: LocationSelectProps) => {
  const value = `${props.itemClass}${props.itemId}`;

  const id = `warehouse-select-${cnt++}`;

  const handleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const t = e.target as HTMLSelectElement;
    const type = t.selectedOptions[0].dataset.type;
    let id: number;
    const match = t.value.match(/\d+$/);

    if (match) {
      id = parseInt(match[0]);
    } else {
      throw new Error('should match a number here');
    }

    if (!id) {
      throw new Error(`location select couldnt parse id from value str`);
    }

    if (type === 'school') {
      props.onChangeSchool(id);
    } else {
      props.onChangeWarehouse(id);
    }
  };

  return (
    <FormControl variant="outlined">
      <InputLabel htmlFor={id}>Select Location</InputLabel>
      <Select id={id} native value={value} onChange={handleChange}>
        {getModels('schools').map(renderSchoolOption)}
        {getModels('warehouses').map(renderWarehouseOption)}
      </Select>
    </FormControl>
  );
};

export default React.memo(LocationSelect);
