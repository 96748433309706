import React from 'react';
import { counts, Count, getShortShort } from '../../constants';
import Dropoff from '../../models/Dropoff';
import Payscale from '../../models/Payscale';
import { centsToDollarString } from '../../util';
import { shortFromCount } from '../../app_util';

const renderCount = (dropoff: Dropoff, count: Count, payscale: Payscale) => {
  const count_amount = dropoff[count];
  const short = shortFromCount(count);
  const short_short = getShortShort(short);
  const cents = payscale.getCents(short_short);

  const total = count_amount * cents;

  return (
    <tr key={`td_${count}`}>
      <td className="fw-500" style={{ minWidth: 65 }}>
        {short}:
      </td>
      <td>{count_amount || 0}</td>
      <td className="px-3">{centsToDollarString(cents)}</td>
      <td>{centsToDollarString(total)}</td>
    </tr>
  );
};

interface DropoffCountsTable {
  dropoff: Dropoff;
  payscale: Payscale;
  workers_length?: number;
}

const DropoffCountsTable = (props: DropoffCountsTable) => {
  const { dropoff, payscale, workers_length } = props;
  const total = dropoff.total();
  const total_cents = dropoff.totalCents(payscale);
  const each_made_cents = workers_length ? total_cents / workers_length : null;

  return (
    <div>
      <table id="dropoff_calc_table">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th style={{ textAlign: 'center' }}>per</th>
            <th>total</th>
          </tr>
        </thead>
        <tbody>
          {counts.map(count => renderCount(dropoff, count, payscale))}
          <tr>
            <td className="fw-500">Total:</td>
            <td>{total}</td>
            <td></td>
            <td className="fw-500">{centsToDollarString(total_cents)}</td>
          </tr>
        </tbody>
      </table>
      {each_made_cents ? (
        <div className="fw-500 mt-3">Each worker earned {centsToDollarString(each_made_cents)}</div>
      ) : (
        <div className="fw-500 mt-3">Driver earned {centsToDollarString(total_cents)}</div>
      )}
    </div>
  );
};

export default DropoffCountsTable;
