import ApplicationModel from './ApplicationModel';
import { BuildingJSON, TableName } from '../types/json_types';
import School from './School';
import { ClassName } from '../types/types';
import { findModelById } from '../app_util';
import { getModels } from '../constants';

class Building extends ApplicationModel {
  complex: string;
  area: string;
  shown_name: string;
  gmaps_link: string;
  notes: string;
  rooms_count: number;
  school_id: number;
  school: School;
  name: string;

  constructor(json: BuildingJSON) {
    super(json);
    this.name = json.name;
    this.complex = json.complex;
    this.area = json.area;
    this.shown_name = json.shown_name;
    this.gmaps_link = json.gmaps_link;
    this.notes = json.notes;
    this.rooms_count = json.rooms_count;
    this.school_id = json.school_id;
    this.school = findModelById(getModels('schools'), json.school_id);
  }

  getClassName() {
    const className: ClassName = 'Building';
    return className;
  }

  static getTableName(): TableName {
    return 'buildings';
  }
}

export default Building;
