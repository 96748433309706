import { FilterModalState } from '../types/state_types';
import { FilterJSON } from '../types/json_types';
import School from './School';
import { findModelById, filterModelsByAttr } from '../app_util';
import { getModels } from '../constants';
import Building from './Building';
import { InstallStatus } from '../types/types';

class Filter {
  school_id: number;
  school?: School;
  building_ids: number[];
  statuses: InstallStatus[];
  product_ids: number[];
  buildings?: Building[];
  only_available_jobs: boolean;

  constructor(json: FilterJSON) {
    this.school_id = json.school_id;
    this.building_ids = json.building_ids;
    this.product_ids = json.product_ids;
    this.statuses = json.statuses;
    this.only_available_jobs = json.only_show_available_jobs;
  }

  static emptyJSON(): FilterJSON {
    return {
      school_id: getModels('schools')[0].id,
      building_ids: [],
      statuses: [],
      product_ids: [],
      only_show_available_jobs: false,
    };
  }

  getSchool() {
    if (!this.school) {
      this.school = findModelById(getModels('schools'), this.school_id);
    }

    return this.school;
  }

  getBuildings() {
    if (!this.buildings) {
      const schoolBuildings = filterModelsByAttr(getModels('buildings'), 'school_id', this.school_id);
      this.buildings = this.building_ids.map(bid => findModelById(schoolBuildings, bid));
    }

    return this.buildings;
  }

  getProducts() {
    const allProducts = getModels('products');
    return this.product_ids.map(pid => findModelById(allProducts, pid));
  }

  clearCache() {
    this.school = undefined;
    this.buildings = undefined;
  }

  static loadFromLocalStorage() {
    const json_str = window.localStorage.getItem('filter');

    const filter_json = json_str ? (JSON.parse(json_str) as FilterJSON) : null;

    if (filter_json) {
      filter_json.product_ids = filter_json.product_ids || [];
      filter_json.statuses = filter_json.statuses || [];
    }

    if (filter_json) {
      const filter = new Filter(filter_json);

      return filter;
    } else {
      return null;
    }
  }

  toJSON() {
    return {
      school_id: this.school_id,
      building_ids: this.building_ids,
      product_ids: this.product_ids || [],
      statuses: this.statuses || [],
      only_show_available_jobs: this.only_available_jobs,
    };
  }

  saveToLocalStorage() {
    localStorage.setItem('filter', JSON.stringify(this));
  }

  static emptyModalState(): FilterModalState {
    return {
      open: false,
      school_id: null,
      building_ids: [],
      product_ids: [],
      statuses: [],
      only_show_available_jobs: false,
    };
  }
}

export default Filter;
