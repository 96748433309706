import React from 'react';
import Button from '@material-ui/core/Button';
import { store, getModels } from '../../constants';
import ApiRequest, { ApiRequestCallbacks } from '../../ApiRequest';
import { PaycheckJSON, isErrorResponse, SentPaycheckJSON } from '../../types/json_types';
import Paycheck from '../../models/Paycheck';
import { findModelById } from '../../app_util';

const { getState, setState } = store.getHelpers('paycheck_modal');

const update = (paycheck_id: number) => {
  const state = getState();

  if (state.working) {
    return;
  }

  const callbacks: ApiRequestCallbacks = {
    onLoading: () => setState({ working: true }),
    onSuccess: (json: unknown) => {
      const allPaychecks = getModels('paychecks');
      const paycheck = findModelById(allPaychecks, paycheck_id);

      const nextPaycheck = new Paycheck(json as PaycheckJSON);

      const index = allPaychecks.indexOf(paycheck);
      allPaychecks[index] = nextPaycheck;

      store.forceUpdate('user_work_page');
      setState(Paycheck.getModalState(null, false, null));
    },
    onError: (json: unknown) => {
      if (isErrorResponse(json)) {
        setState({ errors: json.errors });
      } else {
        alert('some funky error occurred');
      }
    },
    onDone: () => setState({ working: false }),
  };

  const data: SentPaycheckJSON = {
    date_at_ts: state.date_at.getTime() / 1000,
    type: state.type,
    notes: state.notes,
    check_number: state.check_number,
    amount: state.amount?.toString() || null,
    payee_id: state.payee_id,
  };

  const request = new ApiRequest(`/api/paychecks/${paycheck_id}.json`, 'PUT', callbacks, { paycheck: data });
  request.perform();
};

const create = () => {
  const state = getState();

  if (state.working) {
    return;
  }

  const callbacks: ApiRequestCallbacks = {
    onLoading: () => setState({ working: true }),
    onSuccess: (json: unknown) => {
      const paycheck = new Paycheck(json as PaycheckJSON);
      getModels('paychecks').push(paycheck);

      store.forceUpdate('user_work_page');
      setState(Paycheck.getModalState(null, false, null));
    },
    onError: (json: unknown) => {
      if (isErrorResponse(json)) {
        setState({ errors: json.errors });
      } else {
        alert('some funky error occurred');
      }
    },
    onDone: () => setState({ working: false }),
  };

  const data: SentPaycheckJSON = {
    date_at_ts: state.date_at.getTime() / 1000,
    type: state.type,
    notes: state.notes,
    check_number: state.check_number,
    amount: state.amount?.toString() || null,
    payee_id: state.payee_id,
  };

  const request = new ApiRequest('/api/paychecks.json', 'POST', callbacks, { paycheck: data });
  request.perform();
};

interface PaycheckModalSaveButtonProps {
  id: number | null;
  working: boolean;
}

const PaycheckModalSaveButton = (props: PaycheckModalSaveButtonProps) => {
  const { id, working } = props;
  return (
    <Button onClick={() => (id ? update(id) : create())} variant="outlined" size="large" disabled={working}>
      Save
    </Button>
  );
};

export default PaycheckModalSaveButton;
