import ApplicationModel from './ApplicationModel';
import { ProductJSON } from '../types/json_types';
import { ClassName } from '../types/types';

class Product extends ApplicationModel {
  name: string;
  short: string;
  short_short: string;

  constructor(json: ProductJSON) {
    super(json);

    this.name = json.name;
    this.short = json.short;
    this.short_short = json.short_short;
  }

  getClassName() {
    const className: ClassName = 'Product';
    return className;
  }
}

export default Product;
