import React from 'react';
import sumBy from 'lodash/sumBy';
import sortBy from 'lodash/sortBy';
import Button from '@material-ui/core/Button';
import PaycheckModal from './PaycheckModal';
import Paycheck from '../../models/Paycheck';
import { centsToDollarString } from '../../util';
import {
  getStatsForUser,
  getMoneyForStats,
  computeRoomTotal,
  computeBonusBuckTotal,
  getDropoffTotalForUser,
  getDrivingTotalForUser,
  computeFridgeCleaningTotalCents,
} from '../../stats_helper';
import { getModels, store } from '../../constants';
import User from '../../models/User';
import strftime from 'strftime';
import { isPresent } from '../../util';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import Payscale from '../../models/Payscale';

const { setState } = store.getHelpers('paycheck_modal');

const clickAdd = (user: User) => {
  const state = Paycheck.getModalState(null, true, user.id);
  state.payee_id = user.id;

  setState(state);
};

const clickEdit = (paycheck: Paycheck) => {
  const state = Paycheck.getModalState(paycheck, true, null);

  setState(state);
};

const renderType = (type: string, check_number: string) => {
  const str = type;

  if (type === 'check' && isPresent(check_number)) {
    return `${str} (#${check_number})`;
  } else {
    return str;
  }
};

const renderPaycheck = (paycheck: Paycheck) => {
  const key = `pc_${paycheck.id}`;
  const date_str = strftime('%b %e %Y', paycheck.date_at);
  const amt = centsToDollarString(paycheck.amount_cents);
  const { type, notes, check_number } = paycheck;

  return (
    <div key={key} className="my-3">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <div>
          Received {amt} on {date_str} via {renderType(type, check_number)}
        </div>

        <Button onClick={() => clickEdit(paycheck)} size="small" variant="outlined">
          Edit
        </Button>
      </Box>
      {isPresent(notes) ? (
        <div>
          <b>notes:</b> {notes}
        </div>
      ) : null}
    </div>
  );
};

interface ReceivedProps {
  user: User;
}

function Received(props: ReceivedProps) {
  const { user } = props;
  const stats = getStatsForUser(user);

  const money = getMoneyForStats(stats, user);

  const room_total = computeRoomTotal(money);
  const dropoff_total = getDropoffTotalForUser(user);
  const driving_total = getDrivingTotalForUser(user);
  const bonus_buck_total = computeBonusBuckTotal(user);

  const ps = Payscale.find('fridge_cleaning', user);
  const fridge_cleaning_total = computeFridgeCleaningTotalCents(user, ps);

  const total = room_total + driving_total + dropoff_total + bonus_buck_total * 100 + fridge_cleaning_total;

  let paychecks = getModels('paychecks').filter(p => p.payee_id === user.id);
  paychecks = sortBy(paychecks, p => p.date_at_ts);

  const total_cents = sumBy(paychecks, p => p.amount_cents);

  let remaining = total - total_cents;
  remaining = remaining < 0 ? 0 : remaining;

  return (
    <div className="p-1">
      <div className="mb-2">
        <table style={{ width: '100%' }} className="muhtable">
          <thead>
            <tr>
              <th>Received</th>
              <th>Earned</th>
              <th>Remaining</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{centsToDollarString(total_cents)}</td>
              <td>{centsToDollarString(total)}</td>
              <td>{centsToDollarString(remaining)}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="mt-3">
        <Typography variant="h6" gutterBottom className="d-flex justify-content-between align-items-center">
          Paychecks ({paychecks.length})
          <Button onClick={() => clickAdd(user)} size="small" variant="outlined" className="my-2">
            Add Paycheck
          </Button>
        </Typography>
        {paychecks.map(renderPaycheck)}
      </div>

      <PaycheckModal />
    </div>
  );
}

export default Received;
