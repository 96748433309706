import React from 'react';
import CloseableAlert from './CloseableAlert';
import AlertTitle from '@material-ui/lab/AlertTitle';

interface ErrorAlertProps {
  errors: string[];
  closeAlert: () => void;
  className?: string;
}

const ErrorAlert = (props: ErrorAlertProps) => {
  const errors = props.errors;

  return (
    <CloseableAlert
      open={!!errors.length}
      closeAlert={props.closeAlert}
      className={props.className}
      variant="outlined"
      severity="error"
    >
      <AlertTitle>The following errors occurred:</AlertTitle>
      <ul>
        {errors.map((e, index) => (
          <li key={`signup_err_idx_${index}`}>{e}</li>
        ))}
      </ul>
    </CloseableAlert>
  );
};

export default ErrorAlert;
