import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';

import Modal from '@material-ui/core/Modal';
import { store, getModels, short_shorts } from '../../constants';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ApiRequest, { ApiRequestCallbacks } from '../../ApiRequest';
import { isErrorResponse } from '../../types/json_types';
import { findModelById } from '../../app_util';
import { ShortCents } from '../../models/Payscale';
import ErrorAlert from '../ErrorAlert';
import { PayscaleModalState } from '../../types/state_types';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { centsToDollarString } from '../../util';

const { getState, setState, useStoreState } = store.getHelpers('payscale_modal');

function getModalStyle() {
  return {
    width: '100%',
    top: '0',
    left: '0',
    bottom: '0',
    outline: '0',
    border: 'none',
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: 0,
    },
  }),
);

const clickUpdate = (state: PayscaleModalState) => {
  if (state.working) {
    return;
  }

  const callbacks: ApiRequestCallbacks = {
    onLoading: () => setState({ working: true }),
    onSuccess: (resp: unknown) => {
      const json = resp as ShortCents;

      const allPayscales = getModels('payscales');
      const payscale = findModelById(allPayscales, state.id);

      Object.assign(payscale.short_cents, json);

      const appState = store.getFullStore();
      appState.payscale_modal.open = false;

      store.forceUpdate('main');
    },
    onError: (json: unknown) => {
      if (isErrorResponse(json)) {
        setState({ errors: json.errors });
      } else {
        alert('some funky error occurred');
      }
    },
    onDone: () => setState({ working: false }),
  };

  const data = { ...state, errors: [] };
  const request = new ApiRequest(`/api/payscales/${state.id}.json`, 'PATCH', callbacks, data);
  request.perform();
};

const handleClose = () => {
  setState({ open: false });
};

const clearErrors = () => {
  store.setState('payscale_modal', { errors: [] });
};

const handleChangeCount = (short: string, num: number) => {
  num = num < 0 ? 0 : num;

  getState().short_cents[short] = num;

  store.forceUpdate('payscale_modal');
};

const renderShort = (short: string) => {
  const num = getState().short_cents[short];

  return (
    <div key={`td_ps_${short}`} className="d-flex mt-3 align-items-center">
      <TextField
        id="standard-number"
        label={short + ' Cents'}
        type="number"
        style={{ maxWidth: 100 }}
        variant="outlined"
        size="small"
        value={num || ''}
        onChange={e => handleChangeCount(short, parseInt(e.target.value))}
        InputLabelProps={{
          shrink: true,
        }}
      />

      <div className="ml-2">{centsToDollarString(num || 0)}</div>
    </div>
  );
};

const PayscaleModal = () => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const state = useStoreState();

  const title = `Update ${state.label} payscale`;

  return (
    <Modal open={state.open} onClose={handleClose}>
      <div style={modalStyle} className={classes.paper}>
        <div>
          <Typography variant="h6" className="d-flex align-center" style={{ justifyContent: 'center' }}>
            {title}
          </Typography>

          <ErrorAlert errors={state.errors} closeAlert={clearErrors} />
        </div>

        <Divider className="mb-2" />

        <Box flexGrow="1" style={{ overflow: 'scroll' }} className="pl-1">
          <div>{short_shorts.map(renderShort)}</div>
        </Box>

        <Divider className="mt-1" />

        <div className="d-flex justify-content-between align-items-center mx-4 my-3">
          <Button onClick={handleClose} variant="outlined" disabled={state.working}>
            Cancel
          </Button>

          <Button onClick={() => clickUpdate(state)} variant="outlined" disabled={state.working}>
            Update
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PayscaleModal;
