import React from 'react';
import {
  getMoneyForStats,
  computeRoomTotal,
  getStatsForUserOnDay,
  getDaysWorked,
  computeBonusBuckTotalOnDay,
  getDropoffTotalForUserAndDate,
  getDrivingTotalForUserAndDate,
  computeFridgeCleaningTotalCentsForDay,
} from '../../stats_helper';
import PayscalesTable from './PayscalesTable';
import { EarningsPageProps } from './EarningsPage';
import strftime from 'strftime';
import { store } from '../../constants';
import TotalsTable from './TotalsTable';
import DatePicker from '../DatePicker';
import MoneyTable from './MoneyTable';
import sortBy from 'lodash/sortBy';
import { andJoin } from '../../util';
import Payscale from '../../models/Payscale';

const { setState } = store.getHelpers('user_work_page');

const handleDateChange = (date: Date | null) => {
  setState({ date });
};

const DailyEarnings = (props: EarningsPageProps) => {
  const { user, date } = props;

  if (date) {
    const stats = getStatsForUserOnDay(user, date);

    const money = getMoneyForStats(stats, user);

    const room_total = computeRoomTotal(money);

    const bonus_buck_total = computeBonusBuckTotalOnDay(user, date);

    const dropoff_total = getDropoffTotalForUserAndDate(user, date);

    const driving_total = getDrivingTotalForUserAndDate(user, date);

    const ps = Payscale.find('fridge_cleaning', user);
    const fridge_cleaning_total = computeFridgeCleaningTotalCentsForDay(user, date, ps);

    const total = dropoff_total + driving_total + room_total + fridge_cleaning_total + bonus_buck_total * 100;

    const totals = { total, dropoff_total, driving_total, fridge_cleaning_total, room_total, bonus_buck_total };

    const days = sortBy(getDaysWorked(user), d => d.ts).map(d => d.str);

    return (
      <div>
        <div className="mt-2">{PayscalesTable({ user })}</div>
        <div className="mt-3">Days worked: {andJoin(days)}</div>
        <div className="mt-1 d-flex justify-content-between align-items-center">
          <div>{strftime('%b %e %Y', date)} earnings:</div>
          <DatePicker label="change date" date={date} onChange={handleDateChange} />
        </div>

        <TotalsTable {...totals} />
        <MoneyTable money={money} stats={stats} />
      </div>
    );
  } else {
    return (
      <div>
        <span>Please select a date:</span>
        <DatePicker date={date} onChange={handleDateChange} />
      </div>
    );
  }
};

export default DailyEarnings;
