import React from 'react';
import { store, short_shorts } from '../../constants';
import Payscale from '../../models/Payscale';
import Button from '@material-ui/core/Button';
import { setPayscaleModalState } from '../../helpers/payscale_helpers';
import PayscaleModal from './PayscaleModal';
import Box from '@material-ui/core/Box';
import { centsToDollarString } from '../../util';

const { useStoreState } = store.getHelpers('payscale_detail');

const clickEdit = (payscale: Payscale) => {
  setPayscaleModalState(payscale, true);
};

const renderShort = (short: string, cents: number) => {
  return (
    <div key={`td_ps_${short}`} className="d-flex mt-3 align-items-center">
      <div>{short}:</div>
      <div className="ml-2">{centsToDollarString(cents)}</div>
    </div>
  );
};
const PayscaleDetail = () => {
  const { payscale } = useStoreState();

  if (!payscale) {
    throw new Error('no payscale dudetown');
  }

  const currentUser = window.currentUser;

  return (
    <div className="p-1 mt-2">
      {currentUser.admin && (
        <div className="my-2 d-flex justify-content-between align-items-center">
          <Button onClick={() => clickEdit(payscale)} size="small" variant="outlined">
            Edit
          </Button>
        </div>
      )}

      <div>
        <span className="fw-500 mr-2">Name:</span>
        {payscale.label}
      </div>

      <div>
        <span className="fw-500 mr-2">Type:</span>
        {payscale.type}
      </div>

      <div>
        <span className="fw-500 mr-2">Group:</span>
        {payscale.group}
      </div>

      <Box flexGrow="1" style={{ overflow: 'scroll' }} className="pl-1">
        <div>{short_shorts.map(ss => renderShort(ss, payscale.getCents(ss)))}</div>
      </Box>

      <PayscaleModal />
    </div>
  );
};

export default PayscaleDetail;
