import React from 'react';
import Room from './models/Room';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import WarningIcon from '@material-ui/icons/Warning';
import NoteIcon from '@material-ui/icons/Note';

import PersonIcon from '@material-ui/icons/Person';
import { lightGreen, amber, deepOrange } from '@material-ui/core/colors';

export const getLockedIcon = (room: Room) => {
  switch (room.locked) {
    case true:
      return <LockIcon style={{ color: amber[500] }} />;
    case false:
      return <LockOpenIcon style={{ color: lightGreen[500] }} />;
    case null:
      return null;
  }
};

export const getOccupiedIcon = (room: Room) => {
  return room.occupied ? <PersonIcon style={{ color: deepOrange.A200 }} /> : null;
};

export const getTicketIcon = (room: Room) => {
  return room.unresolved_tickets_count > 0 ? <WarningIcon /> : null;
};

export const getFieldNoteIcon = (room: Room) => {
  return room.field_notes_count > 0 ? <NoteIcon /> : null;
};
