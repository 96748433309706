import User from '../models/User';
import { ProfileFormState, FullProfileFormState } from '../types/state_types';

export const getProfileFormState = (user: User | null): ProfileFormState => {
  return {
    user_id: user?.id || null,
    email: user?.email || null,
    first_name: user?.first_name || null,
    last_name: user?.last_name || null,
    username: user?.username || null,
    phone: user?.phone || null,
    agreed_to_terms: user?.agreed_to_terms || false,
    working: false,
    errors: [],
  };
};

export const getFullProfileFormState = (user: User | null): FullProfileFormState => {
  return {
    user_id: user?.id || null,
    email: user?.email || null,
    first_name: user?.first_name || null,
    last_name: user?.last_name || null,
    username: user?.username || null,
    phone: user?.phone || null,
    address1: user?.address1 || null,
    address2: user?.address2 || null,
    city: user?.city || null,
    state: user?.state || null,
    zip_code: user?.zip_code || null,
    active: user?.active || false,
    ssn: user?.ssn || null,
    working: false,
    errors: [],
  };
};
