import React from 'react';
import {
  getStatsForUser,
  getMoneyForStats,
  computeRoomTotal,
  computeBonusBuckTotal,
  getDropoffTotalForUser,
  getDrivingTotalForUser,
  computeFridgeCleaningTotalCents,
} from '../../stats_helper';
import PayscalesTable from './PayscalesTable';
import MoneyTable from './MoneyTable';
import TotalsTable from './TotalsTable';
import { EarningsPageProps } from './EarningsPage';
import Payscale from '../../models/Payscale';

const AllTimeEarnings = (props: EarningsPageProps) => {
  const { user } = props;

  const stats = getStatsForUser(user);

  const money = getMoneyForStats(stats, user);

  const room_total = computeRoomTotal(money);

  const dropoff_total = getDropoffTotalForUser(user);

  const driving_total = getDrivingTotalForUser(user);

  const ps = Payscale.find('fridge_cleaning', user);
  const fridge_cleaning_total = computeFridgeCleaningTotalCents(user, ps);

  const bonus_buck_total = computeBonusBuckTotal(user);

  const total = room_total + driving_total + dropoff_total + fridge_cleaning_total + bonus_buck_total * 100;

  const totals = { total, driving_total, dropoff_total, room_total, fridge_cleaning_total, bonus_buck_total };

  return (
    <div>
      <div className="mt-2">{PayscalesTable({ user })}</div>
      <div className="mt-2">Total all-time earnings:</div>
      <TotalsTable {...totals} />
      <MoneyTable money={money} stats={stats} />
    </div>
  );
};

export default AllTimeEarnings;
