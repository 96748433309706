import React from 'react';
import { andJoin } from '../../util';
import Button from '@material-ui/core/Button';
import strftime from 'strftime';
import clsx from 'clsx';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Dropoff from '../../models/Dropoff';
import { getModels, router, store } from '../../constants';
import Typography from '@material-ui/core/Typography';
import { setDropoffModalState } from '../../helpers/dropoff_helpers';
import sortBy from 'lodash/sortBy';

const clickNewDropoff = () => {
  setDropoffModalState(null, true);
};

const clickRow = (dropoff: Dropoff) => {
  router.go(`/dropoffs/${dropoff.id}`);
};

const renderDropoffRow = (dropoff: Dropoff) => {
  const date = strftime('%-m/%-d/%y ~ %l:%M %p', dropoff.performed_at);

  const workers = dropoff.getWorkers();

  const workerNames = andJoin(workers.map(w => w.username));

  const verb = dropoff.type === 'pickup' ? 'picked up' : 'dropped off';

  return (
    <div
      key={'dropoff_' + dropoff.id}
      onClick={() => {
        clickRow(dropoff);
      }}
      className="dropoff-row list-group-item d-flex justify-content-between align-items-center p-1"
    >
      <div>
        <span className="fw-500">{dropoff.type}:</span> {workerNames} {verb} {dropoff.total()} products on {date}
      </div>
      <ArrowForwardIcon />
    </div>
  );
};

let rendered = false;

function DropoffList() {
  const { viewShown } = store.useStore('main', ['viewShown']);

  let dropoffs = sortBy(getModels('dropoffs'), d => -d.performed_at_ts);

  const currentUser = window.currentUser;

  React.useEffect(() => {
    return () => {
      rendered = false;
    };
  }, []);

  if (viewShown === 'Dropoffs') {
    rendered = true;
  } else if (!rendered) {
    return null;
  }

  const classes = clsx('p-1 mt-2 mainfit', { hidden: viewShown !== 'Dropoffs' });

  if (!currentUser.admin) {
    dropoffs = dropoffs.filter(d => d.creator_id === currentUser.id || d.worker_ids.includes(currentUser.id));
  }

  return (
    <div className={classes}>
      <div className="d-flex align-items-center justify-content-between">
        <Typography variant="h6">Truck loadings ({dropoffs.length})</Typography>

        <Button onClick={clickNewDropoff} variant="outlined">
          New Loading
        </Button>
      </div>

      <div id="dropoffs" className="mt-3">
        {dropoffs.map(renderDropoffRow)}
      </div>
    </div>
  );
}

export default DropoffList;
