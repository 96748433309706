import React from 'react';
import Box from '@material-ui/core/Box';
import { router } from '../../constants';
import Room from '../../models/Room';
import { renderShort } from '../../install_view_helpers';
import { getLockedIcon, getOccupiedIcon, getTicketIcon, getFieldNoteIcon } from '../../icon_helpers';

const clickRoom = (room_id: number) => {
  router.go(`/jobs/${room_id}`);
};

const RoomRow = (index: number, style: React.CSSProperties, room: Room) => {
  const installs = room.installs;

  return (
    <Box onClick={() => clickRoom(room.id)} className="job-row pl-2" style={style} display="flex" alignItems="center">
      {room.wingName()}
      {getLockedIcon(room)}
      {getOccupiedIcon(room)}
      {getTicketIcon(room)}
      {getFieldNoteIcon(room)}: {installs.map(renderShort)}
    </Box>
  );
};

export default RoomRow;
