import ApplicationModel from './ApplicationModel';
import Room from './Room';
import { findModelById } from '../app_util';
import User from './User';
import { RoomOccupationJSON } from '../types/json_types';
import { ClassName, Activity } from '../types/types';
import { getModels } from '../constants';
import strftime from 'strftime';

class RoomOccupation extends ApplicationModel {
  occupied: boolean;
  room_id: number;
  room: Room;
  set_by_id: number;
  set_by: User;

  constructor(json: RoomOccupationJSON) {
    super(json);

    this.occupied = json.occupied;
    this.room_id = json.room_id;

    const rooms = getModels('rooms');
    this.room = findModelById(rooms, this.room_id);

    this.set_by_id = json.set_by_id;
    const users = getModels('users');
    this.set_by = findModelById(users, this.set_by_id);
  }

  getClassName() {
    const className: ClassName = 'RoomOccupation';
    return className;
  }

  toActivity(): Activity {
    return {
      id: this.id,
      txt: this.getText(),
      creator: this.set_by,
      created_at: this.created_at,
      created_at_ts: this.created_at_ts,
      key: `room_occupation_${this.id}_activity`,
    };
  }

  getText() {
    const creatorName = this.set_by.username;
    const dateStr = `on ${strftime('%b %e %Y @ %l:%M %p', this.created_at)}`;

    let occupiedTxt: string;
    switch (this.occupied) {
      case true:
        occupiedTxt = 'room to occupied';
        break;
      case false:
        occupiedTxt = 'room to unoccupied';
        break;
    }

    return `${creatorName} set ${occupiedTxt} on ${dateStr}`;
  }
}

export default RoomOccupation;
