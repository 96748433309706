import { PlainObject, HTTPMethod } from './types/types';

export const makeRequest = async function(
  path: string,
  method: HTTPMethod,
  payload?: PlainObject,
  customHeaders?: Headers,
) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  };

  if (customHeaders) {
    Object.assign(headers, customHeaders);
  }

  const options: RequestInit = {
    method,
    headers,
    credentials: 'same-origin',
  };

  if (payload) {
    options.body = JSON.stringify(payload);
  }

  const response = await fetch(path, options);

  let json: unknown;
  if (response.status === 204) {
    json = {};
  } else {
    try {
      json = await response.json();
    } catch {
      json = {};
    }
  }

  return {
    response: response,
    json: json,
  };
};

export const deleteItem = function(path: string) {
  return makeRequest(path, 'DELETE');
};

export const postData = async function(path: string, payload: PlainObject) {
  const result = await makeRequest(path, 'POST', payload);

  return result?.json;
};

export const patchData = function(path: string, payload: PlainObject) {
  return makeRequest(path, 'PATCH', payload);
};

export const getData = function(path: string, payload?: PlainObject) {
  return makeRequest(path, 'GET', payload);
};
