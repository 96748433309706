import React from 'react';
import CenterAll from './CenterAll';

const style = {
  border: 'none',
  background: 'none',
};

const host =
  window.location.host === 'dd3.dormsdirect.com'
    ? 'https%3A%2F%2Fdd3.dormsdirect.com'
    : 'http%3A%2F%2Flocalhost%3A3000';
const action = `/slack_sign_in/authorization?proceed_to=${host}%2Fcreate_session_via_slack`;

const SlackSignInButton = () => {
  return (
    <div>
      <CenterAll>
        <form action={action} method="POST">
          <button style={style}>
            <img
              alt="Sign in with Slack"
              height="40"
              width="172"
              srcSet="https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x"
              src="https://platform.slack-edge.com/img/sign_in_with_slack.png"
            />
          </button>
        </form>
      </CenterAll>
      <CenterAll>
        <div>Sign into &quot;dormsdirectworkspace&quot;</div>
      </CenterAll>
    </div>
  );
};

export default SlackSignInButton;
