import React from 'react';
import DotIcon from '@material-ui/icons/FiberManualRecord';
import { green, amber } from '@material-ui/core/colors';
import { store } from '../../constants';

const style: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  right: 5,
  fontSize: 10,
  display: 'flex',
  alignItems: 'center',
};

const getDotStyles = (isOnline: boolean): React.CSSProperties => {
  return {
    width: 10,
    height: 10,
    color: isOnline ? green.A700 : amber[500],
  };
};

function OnlineSection() {
  const { isOnline } = store.useStore('main', ['isOnline']);

  const txt = isOnline ? 'online' : 'offline';

  return (
    <div style={style}>
      <DotIcon style={getDotStyles(!!isOnline)} />
      {txt}
    </div>
  );
}

export default React.memo(OnlineSection);
