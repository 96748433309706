import FridgeCleaning from '../models/FridgeCleaning';
import { getModels } from '../constants';

export const getFridgeCleaningsByGroup = (fc: FridgeCleaning) => {
  return getModels('fridge_cleanings').filter(c => fc.isSameGroup(c));
};

export type FridgeCleaningGroup = Array<[FridgeCleaning, FridgeCleaning[]]>;

export const groupFridgeCleanings = () => {
  const group: FridgeCleaningGroup = [];
  const fridge_cleanings = getModels('fridge_cleanings');

  for (let index = 0; index < fridge_cleanings.length; index++) {
    const fc = fridge_cleanings[index];
    const h = group.find(h => h[0].isSameGroup(fc));

    if (h) {
      h[1].push(fc);
    } else {
      group.push([fc, [fc]]);
    }
  }

  return group;
};
