import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { store, getModels, setModels } from '../../constants';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import InstallActionsModalSection from './InstallActionsModalSection';
import { renderShort } from '../../install_view_helpers';
import ApiRequest, { ApiRequestCallbacks } from '../../ApiRequest';
import Install from '../../models/Install';
import InstallAction from '../../models/InstallAction';
import reject from 'lodash/reject';
import { InstallActionCreationJSON } from '../../types/json_types';

const { setState: setModalState, useStoreState } = store.getHelpers('install_action_modal');

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: 0,
    width: '100%',
    border: 'none',
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: 0,
    },
  }),
);

async function clickSubmit(install: Install) {
  const state = store.getState('install_actions');

  const data = { ...state, install_id: install.id };

  const callbacks: ApiRequestCallbacks = {
    onLoading: () => setModalState({ working: true }),
    onSuccess: (json: unknown) => {
      const { install_actions, install_status } = json as InstallActionCreationJSON;

      const actions = install_actions?.map(ia => new InstallAction(ia)) || [];
      install.status = install_status;

      const allActions = getModels('install_actions');
      console.log('allActions len:', allActions.length);

      const previous_actions = install.getInstallActions();

      const nextActions = reject(allActions, a => previous_actions.includes(a));
      setModels('install_actions', nextActions.concat(actions));
      console.log('allActions len:', getModels('install_actions').length);

      install.setInstallActions(actions);
      const appState = store.getFullStore();

      appState.install_action_modal = {
        open: false,
        install: null,
        working: false,
      };

      store.forceUpdate('job_detail');
      store.forceUpdate('install_action_modal');
    },
    onError: () => {
      alert('an error occurred');
    },
    onDone: () => setModalState({ working: false }),
  };

  const request = new ApiRequest('/api/create_install_actions', 'POST', callbacks, data);
  request.perform();
}

const handleClose = () => {
  setModalState({ open: false, install: null, working: false });
};

const InstallActionModal = () => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const { open, install, working } = useStoreState();

  if (open) {
    if (!install) {
      throw new Error('no install set');
    }
  } else {
    return null;
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <div style={modalStyle} className={classes.paper}>
        <div className="px-4 my-2">
          <Typography variant="h6" className="d-flex">
            Select the actions you did for {renderShort(install)}
          </Typography>

          <InstallActionsModalSection install={install} />
        </div>

        <div className="d-flex justify-content-between align-items-center mx-4 my-3">
          <Button onClick={handleClose} variant="outlined" disabled={working}>
            Cancel
          </Button>

          <Button onClick={() => clickSubmit(install)} variant="outlined" disabled={working}>
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(InstallActionModal);
