import { ClassName } from '../types/types';
import { ModelJSON } from '../types/json_types';
import Building from './Building';
import Room from './Room';
import School from './School';
import User from './User';
import Dropoff from './Dropoff';
import Payscale from './Payscale';
import FridgeCleaning from './FridgeCleaning';
import Warehouse from './Warehouse';

abstract class ApplicationModel {
  id: number;
  created_at_ts: number;
  created_at: Date;

  constructor(attrs: ModelJSON) {
    this.id = attrs.id;
    this.created_at_ts = attrs.created_at_ts;
    this.created_at = new Date(attrs.created_at_ts);
  }

  isBuilding(): this is Building {
    return this.getClassName() === 'Building';
  }

  isRoom(): this is Room {
    return this.getClassName() === 'Room';
  }

  isSchool(): this is School {
    return this.getClassName() === 'School';
  }

  isUser(): this is User {
    return this.getClassName() === 'User';
  }

  isDropoff(): this is Dropoff {
    return this.getClassName() === 'Dropoff';
  }

  isPayscale(): this is Payscale {
    return this.getClassName() === 'Payscale';
  }

  isWarehouse(): this is Warehouse {
    return this.getClassName() === 'Warehouse';
  }

  isFridgeCleaning(): this is FridgeCleaning {
    return this.getClassName() === 'FridgeCleaning';
  }

  abstract getClassName(): ClassName;
}

export default ApplicationModel;
