import React from 'react';
import FullProfileForm from './FullProfileForm';

const ProfilePage = () => {
  return (
    <div className="mt-2 fit">
      <FullProfileForm />
    </div>
  );
};

export default ProfilePage;
