import ApplicationModel from './ApplicationModel';
import { DropoffJSON } from '../types/json_types';
import { ClassName } from '../types/types';
import { getModels } from '../constants';
import { findModelsByIdInclusion, findModelById } from '../app_util';
import User from './User';
import School from './School';
import Payscale from './Payscale';
import Warehouse from './Warehouse';

class Dropoff extends ApplicationModel {
  bl_count: number;
  mf_count: number;
  sr_count: number;
  ft_count: number;
  dft_count: number;
  bs_count: number;
  es_count: number;
  tv32_count: number;
  tv40_count: number;
  notes: string;
  building_ids: number[];
  worker_ids: number[];
  creator_id: number;
  creator: User;
  school_id: number;
  school: School;
  driver_id: number | null;
  driver: User | null;
  warehouse_id: number | null;
  warehouse: Warehouse | null;
  type: string;
  performed_at_ts: number;
  performed_at: Date;

  constructor(json: DropoffJSON) {
    super(json);

    this.bl_count = json.bl_count;
    this.mf_count = json.mf_count;
    this.sr_count = json.sr_count;
    this.ft_count = json.ft_count;
    this.dft_count = json.dft_count;
    this.bs_count = json.bs_count;
    this.es_count = json.es_count;
    this.tv32_count = json.tv32_count;
    this.tv40_count = json.tv40_count;
    this.notes = json.notes;
    this.building_ids = json.building_ids;
    this.worker_ids = json.worker_ids;
    this.creator_id = json.creator_id;
    this.creator = findModelById(getModels('users'), this.creator_id);
    this.school_id = json.school_id;
    this.school = findModelById(getModels('schools'), json.school_id);
    this.driver_id = json.driver_id;
    this.driver = json.driver_id ? findModelById(getModels('users'), json.driver_id) : null;
    this.warehouse_id = json.warehouse_id;
    this.warehouse = json.warehouse_id ? findModelById(getModels('warehouses'), json.warehouse_id) : null;
    this.type = json.type;
    this.performed_at_ts = json.performed_at_ts;
    this.performed_at = new Date(json.performed_at_ts);
  }

  getClassName() {
    const className: ClassName = 'Dropoff';
    return className;
  }

  getWorkers() {
    const users = getModels('users');
    return findModelsByIdInclusion(users, this.worker_ids);
  }

  getBuildings() {
    const buildings = getModels('buildings');
    return findModelsByIdInclusion(buildings, this.building_ids);
  }

  individualCents(payscale: Payscale) {
    return this.totalCents(payscale) / this.worker_ids.length;
  }

  loadedTruck(user: User) {
    return this.worker_ids.includes(user.id);
  }

  workedDropoff(user: User) {
    return this.driver_id === user.id || this.loadedTruck(user);
  }

  totalCents(payscale: Payscale) {
    return (
      this.bl_count * payscale.getCents('BL') +
      this.mf_count * payscale.getCents('MF') +
      this.sr_count * payscale.getCents('SR') +
      this.ft_count * payscale.getCents('FT') +
      this.dft_count * payscale.getCents('FT') +
      this.bs_count * payscale.getCents('BS') +
      this.es_count * payscale.getCents('ES') +
      this.tv32_count * payscale.getCents('TV') +
      this.tv40_count * payscale.getCents('TV')
    );
  }

  total() {
    return (
      this.bl_count +
      this.mf_count +
      this.sr_count +
      this.ft_count +
      this.dft_count +
      this.bs_count +
      this.es_count +
      this.tv32_count +
      this.tv40_count
    );
  }
}

export default Dropoff;
