import React from 'react';
import { getModels } from '../../constants';
import Typography from '@material-ui/core/Typography';
import BonusBuck from '../../models/BonusBuck';
import strftime from 'strftime';
import { andJoin, isPresent } from '../../util';
import Box from '@material-ui/core/Box';
import User from '../../models/User';

const renderBB = (bb: BonusBuck) => {
  const date = strftime('%-m/%-d/%y', bb.created_at);
  const users = andJoin(bb.getWorkers().map(u => u.username));
  const reason = isPresent(bb.reason) ? bb.reason : 'reasons';

  return (
    <Box key={`bb_${bb.id}`} className="job-row" style={{ minHeight: 50 }} display="flex" alignItems="center">
      {users} received ${bb.dollars} on {date} for {reason}
    </Box>
  );
};

interface UserWorkBonusesProps {
  user: User;
}

const UserWorkBonuses = (props: UserWorkBonusesProps) => {
  const { user } = props;
  const bonus_bucks = getModels('bonus_bucks').filter(bb => bb.worker_ids.includes(user.id));

  return (
    <div className="mt-3">
      <Typography variant="h6" gutterBottom className="d-flex justify-content-between align-items-center">
        Your Bonus Bucks ({bonus_bucks.length})
      </Typography>

      {bonus_bucks.map(renderBB)}
    </div>
  );
};

export default UserWorkBonuses;
