import React from 'react';

import { shorts, colors } from '../../constants';
import { InstallStats } from '../../stats_helper';
import School from '../../models/School';

function SchoolStatsTable(stats: InstallStats, school: School) {
  const key = `school_stats_${school.id}`;

  return (
    <div key={key} className="mt-2">
      <div>{school.name} stats</div>
      <table className="muhtable">
        <thead>
          <tr>
            {shorts.map(short => (
              <th key={`stats_th_${short}_${school.short}`}>{short}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr className="total">
            {shorts.map(short => (
              <td key={`stats_th_${short}_${school.short}_total`}>{stats[short].total}</td>
            ))}
          </tr>
          <tr className="text_pending_removal" style={{ color: colors.pending_removal }}>
            {shorts.map(short => (
              <td key={`stats_th_${short}_${school.short}_pr`}>{stats[short].pending_removal}</td>
            ))}
          </tr>
          <tr className="text_uninstalled" style={{ color: colors.uninstalled }}>
            {shorts.map(short => (
              <td key={`stats_th_${short}_${school.short}_uninstalled`}>{stats[short].uninstalled}</td>
            ))}
          </tr>
          <tr className="text_removed" style={{ color: colors.removed }}>
            {shorts.map(short => (
              <td key={`stats_th_${short}_${school.short}_removed`}>{stats[short].removed}</td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default SchoolStatsTable;
