import React from 'react';
import CenterAll from '../CenterAll';
import Room from '../../models/Room';
import { store, getModels, setModels } from '../../constants';
import ApiRequest, { ApiRequestCallbacks } from '../../ApiRequest';
import RoomLocking from '../../models/RoomLocking';
import { isErrorResponse, RoomLockingJSON } from '../../types/json_types';
import LockedSelect from './LockedSelect';
import reject from 'lodash/reject';

const { setState } = store.getHelpers('job_detail');

interface LockedSectionProps {
  locked: boolean | null;
  room: Room;
}

const onChangedLocked = (nextLocked: boolean | null, room: Room, locked: boolean | null) => {
  const previousLocked = locked;

  setState({ locked: nextLocked });

  const callbacks: ApiRequestCallbacks = {
    onSuccess: (json: unknown) => {
      const roomLocking = new RoomLocking(json as RoomLockingJSON);
      const room_lockings = reject(getModels('room_lockings'), rl => rl.room_id === room.id);
      room_lockings.unshift(roomLocking);
      setModels('room_lockings', room_lockings);
      room.locked = roomLocking.locked;
      setState({ locked: room.locked });
    },
    onError: (json: unknown) => {
      setState({ locked: previousLocked });
      if (isErrorResponse(json)) {
        alert(json.errors.join('. '));
      } else {
        alert('an error occurred while changing locked status');
      }
    },
  };

  const request = new ApiRequest('/api/room_lockings.json', 'POST', callbacks, {
    room_id: room.id,
    locked: nextLocked,
  });

  request.perform();
};

const LockedSection = (props: LockedSectionProps) => {
  const { locked, room } = props;

  return (
    <CenterAll className="mt-2">
      <LockedSelect locked={locked} onChange={nextLocked => onChangedLocked(nextLocked, room, locked)} />
    </CenterAll>
  );
};

export default React.memo(LockedSection);
