import Building from '../../models/Building';
import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { getModels } from '../../constants';
import sortBy from 'lodash/sortBy';

const renderOption = (building: Building) => {
  const key = `building_${building.id}_option`;

  return (
    <option value={building.id} key={key}>
      {building.name}
    </option>
  );
};

type BuildingSelectNullProps = {
  onChange: (building_id: number | null) => void;
  building_id: number | null;
  school_id: number;
  nullLabel: string;
};

let cnt = 0;

const BuildingSelectNull = (props: BuildingSelectNullProps) => {
  const id = `building-select-null-${cnt++}`;

  const handleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const value = e.target.value as string;
    const building_id = value === 'null' ? null : parseInt(value);

    props.onChange(building_id);
  };

  const { building_id, nullLabel, school_id } = props;

  const buildings = sortBy(
    getModels('buildings').filter(b => b.school_id === school_id),
    b => b.shown_name,
  );

  return (
    <FormControl variant="outlined">
      <InputLabel htmlFor={id}>Select Building</InputLabel>
      <Select id={id} native value={building_id || 'null'} onChange={handleChange}>
        <option>{nullLabel}</option>
        {buildings.map(renderOption)}
      </Select>
    </FormControl>
  );
};

export default BuildingSelectNull;
