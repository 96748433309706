import ApplicationModel from './ApplicationModel';
import { InstallActionJSON } from '../types/json_types';
import { ClassName, Activity } from '../types/types';
import Install from './Install';
import User from './User';
import { findModelById } from '../app_util';
import strftime from 'strftime';
import { andJoin, round } from '../util';
import { getModels } from '../constants';

class InstallAction extends ApplicationModel {
  set: boolean;
  creator_id: number;
  creator: User;
  splitter_ids: number[];
  splitters: User[];
  actor_id: number;
  actor: User;
  install_id: number;
  install: Install;
  type: 'uninstalled' | 'removed' | 'set_missing';
  money_type: 'uninstalled' | 'removed' | null;

  constructor(json: InstallActionJSON) {
    super(json);

    const users = getModels('users');
    const installs = getModels('installs');

    this.set = json.set;
    this.type = json.type;
    this.money_type = this.type === 'uninstalled' ? 'uninstalled' : this.type === 'removed' ? 'removed' : null;
    this.splitter_ids = json.splitter_ids;
    this.splitters = this.splitter_ids.map(id => findModelById(users, id));
    this.creator_id = json.creator_id;
    this.creator = findModelById(users, this.creator_id);
    this.actor_id = json.actor_id;
    this.actor = findModelById(users, this.actor_id);
    this.install_id = json.install_id;
    this.install = findModelById(installs, this.install_id);
  }

  getClassName() {
    const className: ClassName = 'InstallAction';
    return className;
  }

  toActivity(): Activity {
    return {
      id: this.id,
      txt: this.getText(),
      creator: this.creator,
      created_at: this.created_at,
      created_at_ts: this.created_at_ts,
      key: `install_action_${this.id}_activity`,
    };
  }

  individualCount() {
    return round(1 / (this.splitter_ids.length + 1));
  }

  touchedByUser(user: User) {
    return this.actor_id === user.id || this.splitter_ids.includes(user.id);
  }

  getText() {
    const creatorName = this.creator.username;
    const short = this.install.product.short;
    const dateStr = `on ${strftime('%b %e %Y @ %l:%M %p', this.created_at)}`;

    let splitterTxt = '';

    if (this.splitters.length) {
      const usernames = andJoin(this.splitters.map(s => s.username));
      splitterTxt = `+ ${usernames}`;
    }

    if (this.creator === this.actor) {
      return `${creatorName} ${splitterTxt} ${this.type} ${short} ${dateStr}`;
    } else {
      const actorName = this.actor.username;
      return `${creatorName} set that ${actorName} ${splitterTxt} ${this.type} ${short} ${dateStr}`;
    }
  }
}

export default InstallAction;
