import React, { useState } from 'react';
import { Formik } from 'formik';
import { TextField, Button, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import BrightnessIcon from '@material-ui/icons/SettingsBrightness';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { UserJSONResponse, isErrorResponse } from '../types/json_types';
import { store, router } from '../constants';
import CloseableAlert from './CloseableAlert';
import User from '../models/User';
import ApiRequest, { ApiRequestCallbacks } from '../ApiRequest';
import { toggleDarkmode } from '../app_util';

const { setState: setMainState } = store.getHelpers('main');

const clickGoLogin = () => {
  router.go('/login');
};

const SignupForm = () => {
  const [errors, setErrors] = useState<string[]>([]);

  const closeAlert = () => {
    setErrors([]);
  };

  return (
    <Formik
      initialValues={{ email: '', password_confirmation: '', password: '' }}
      onSubmit={(values, { setSubmitting }) => {
        const callbacks: ApiRequestCallbacks = {
          onSuccess: (json: unknown) => {
            const { currentUser } = json as UserJSONResponse;
            window.currentUser = new User(currentUser);
            setMainState({ currentUserId: currentUser.id });
          },
          onError: (json: unknown) => {
            if (isErrorResponse(json)) {
              setErrors(json.errors);
            } else {
              alert('an error occurred');
            }

            setSubmitting(false);
          },
        };

        const request = new ApiRequest('/users.json', 'POST', callbacks, { user: values });
        request.perform();
      }}
    >
      {props => {
        const { values, isSubmitting, handleSubmit, handleChange } = props;

        return (
          <div className="p-2">
            {errors.length ? (
              <CloseableAlert open={!!errors.length} closeAlert={closeAlert} variant="outlined" severity="error">
                <AlertTitle>The following errors occurred:</AlertTitle>
                <ul>
                  {errors.map((e, index) => (
                    <li key={`signup_err_idx_${index}`}>{e}</li>
                  ))}
                </ul>
              </CloseableAlert>
            ) : null}

            <Typography variant="h4">Sign Up</Typography>

            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column">
                <TextField
                  value={values.email}
                  onChange={handleChange}
                  name="email"
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  className="mt-2"
                />

                <TextField
                  value={values.password}
                  onChange={handleChange}
                  name="password"
                  label="Password"
                  type="password"
                  variant="outlined"
                  className="mt-2"
                />

                <TextField
                  value={values.password_confirmation}
                  onChange={handleChange}
                  name="password_confirmation"
                  label="Password Confirmation"
                  type="password"
                  variant="outlined"
                  className="mt-2"
                />

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  className="mt-2"
                  disabled={isSubmitting}
                  disableElevation
                >
                  sign up
                </Button>
              </Box>
            </form>

            <Box display="flex" flexDirection="column">
              <Typography className="mt-2" variant="h6" style={{ textAlign: 'center' }}>
                OR
              </Typography>

              <Button onClick={clickGoLogin} variant="outlined" color="secondary" size="large" className="mt-2">
                Go to login page
              </Button>

              <Typography className="mt-2" variant="h6" style={{ textAlign: 'center' }}>
                OR
              </Typography>

              <Button onClick={toggleDarkmode} variant="outlined" color="secondary" size="large" className="mt-2">
                <BrightnessIcon className="mr-2" />
                Toggle light/dark mode
              </Button>
            </Box>
          </div>
        );
      }}
    </Formik>
  );
};

export default SignupForm;
