import { store } from '../constants';
import { BarcodeCleaningModalState } from '../types/state_types';

export const setupZXing = () => {
  import('@zxing/library').then(ZXing => {
    const codeReader = new ZXing.BrowserBarcodeReader();

    let selectedDeviceId: string;

    codeReader.getVideoInputDevices().then(videoInputDevices => {
      selectedDeviceId = videoInputDevices.length > 1 ? videoInputDevices[1].deviceId : videoInputDevices[0].deviceId;

      store.setState('barcode_scanning_modal', {
        codeReader,
        selectedDeviceId,
      });
    });
  });
};

export const getBarcodeScanningModalState = (open: boolean): BarcodeCleaningModalState => {
  return {
    open,
    codeReader: null,
    selectedDeviceId: '',
    scanning: false,
    serialNumber: '',
  };
};
