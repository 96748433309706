import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { parseBoolStr } from '../../util';

export interface LockedSelectProps {
  locked: boolean | null;
  onChange: (locked: boolean | null) => void;
}

const LockedSelect = (props: LockedSelectProps) => {
  const handleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    props.onChange(parseBoolStr(e.target.value as string));
  };

  const val = `${props.locked}`;

  return (
    <div>
      <FormControl variant="outlined">
        <InputLabel htmlFor="locked-select">Locked Status</InputLabel>
        <Select id="locked-select" native value={val} onChange={handleChange}>
          <option value={'null'}>Unknown</option>
          <option value={'true'}>Locked</option>
          <option value={'false'}>Unlocked</option>
        </Select>
      </FormControl>
    </div>
  );
};

export default React.memo(LockedSelect);
