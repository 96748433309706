import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

export interface LockedSelectProps {
  type: string;
  onChange: (type: string) => void;
}

const DropoffTypeSelect = (props: LockedSelectProps) => {
  const handleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    props.onChange(e.target.value as string);
  };

  return (
    <div className="mt-2 mb-3">
      <FormControl variant="outlined">
        <InputLabel htmlFor="locked-select">Pickup or Dropoff?</InputLabel>
        <Select id="dropoff-type-select" native value={props.type} onChange={handleChange}>
          <option value={'pickup'}>Pickup</option>
          <option value={'dropoff'}>Dropoff</option>
        </Select>
      </FormControl>
    </div>
  );
};

export default React.memo(DropoffTypeSelect);
