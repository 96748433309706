import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { InstallStatus } from '../../types/types';
import { install_statuses } from '../../install_helpers';

const renderStatusOption = (status: InstallStatus) => {
  const key = `prod_status_option_${status}`;

  return (
    <option key={key} value={status}>
      {status}
    </option>
  );
};

export interface StatusSelectProps {
  statuses: InstallStatus[];
  onChange: (statuses: InstallStatus[]) => void;
  label: string;
}

const handleChangeMultiple = (
  event: React.ChangeEvent<{ value: unknown }>,
  onChange: (statuses: InstallStatus[]) => void,
) => {
  const { options } = event.target as HTMLSelectElement;
  const statuses: InstallStatus[] = [];
  for (let i = 0, l = options.length; i < l; i += 1) {
    if (options[i].selected) {
      statuses.push(options[i].value as InstallStatus);
    }
  }

  onChange(statuses);
};

export default function ProductStatusesSelect(props: StatusSelectProps) {
  const { label, onChange, statuses } = props;

  return (
    <div>
      <FormControl style={{ minWidth: 120 }}>
        <InputLabel shrink htmlFor="status-select">
          {label}
        </InputLabel>
        <Select id="status-select" multiple native value={statuses} onChange={e => handleChangeMultiple(e, onChange)}>
          {install_statuses.map(renderStatusOption)}
        </Select>
      </FormControl>
    </div>
  );
}
