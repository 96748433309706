import React from 'react';
import { store, getModels } from '../../constants';
import FridgeCleaning from '../../models/FridgeCleaning';
import { isPresent, spliceFromArray, andJoin } from '../../util';
import Button from '@material-ui/core/Button';
import {
  setFridgeCleaningModalState,
  getFridgeCleaningModalStateFromTemplate,
} from '../../helpers/fridge_cleaning_helpers';
import ApiRequest, { ApiRequestCallbacks } from '../../ApiRequest';
import { isErrorResponse } from '../../types/json_types';
import { router } from '../../setup/store';
import strftime from 'strftime';
import { getImageUrl } from '../../helpers/paperclip_helpers';

const { setState, useStoreState } = store.getHelpers('fridge_cleaning_detail');

const clickEdit = (fridge_cleaning: FridgeCleaning) => {
  setFridgeCleaningModalState(fridge_cleaning, true, window.App.filter || null);
};

const clickDestroy = (fridge_cleaning: FridgeCleaning) => {
  if (window.confirm('are you sure?')) {
    const callbacks: ApiRequestCallbacks = {
      onLoading: () => setState({ working: true }),
      onSuccess: () => {
        const allFridgeCleanings = getModels('fridge_cleanings');
        spliceFromArray(allFridgeCleanings, fridge_cleaning);

        router.go('/fridge_cleanings');
      },
      onError: (json: unknown) => {
        if (isErrorResponse(json)) {
          alert(andJoin(json.errors));
        } else {
          alert('some funky error occurred');
        }
      },
      onDone: () => setState({ working: false }),
    };

    const request = new ApiRequest(`/api/fridge_cleanings/${fridge_cleaning.id}.json`, 'DELETE', callbacks);
    request.perform();
  }
};

const renderImg = (id: number, image_file_name: string | null) => {
  if (!image_file_name) {
    return null;
  }

  const thumb = getImageUrl(id, 'fridge_cleanings', 'medium', image_file_name);
  const original = getImageUrl(id, 'fridge_cleanings', 'original', image_file_name);

  return (
    <a href={original} target="_blank" rel="noopener noreferrer">
      <img alt="" src={thumb} style={{ marginRight: 5 }} />
    </a>
  );
};

const clickNewFromTemplate = (fc: FridgeCleaning) => {
  const state = getFridgeCleaningModalStateFromTemplate(fc);
  store.setState('fridge_cleaning_modal', state);
};

const FridgeCleaningDetail = () => {
  const { fridge_cleaning, working } = useStoreState();

  if (!fridge_cleaning) {
    throw new Error('no fridge_cleaning dudetown');
  }

  const currentUser = window.currentUser;

  const date = strftime('%-m/%-d/%y ~ %l:%M %p', fridge_cleaning.performed_at);

  const { school, room_str } = fridge_cleaning;
  const building = fridge_cleaning.getBuilding();
  const warehouse = fridge_cleaning.getWarehouse();

  return (
    <div className="p-1 mt-2">
      {(currentUser.admin || fridge_cleaning.creator_id === currentUser.id) && (
        <div className="my-2 d-flex justify-content-between align-items-center">
          <Button onClick={() => clickEdit(fridge_cleaning)} size="small" variant="outlined">
            Edit
          </Button>
          <Button onClick={() => clickNewFromTemplate(fridge_cleaning)} size="small" variant="outlined">
            New from template
          </Button>
          <Button
            onClick={() => (working ? null : clickDestroy(fridge_cleaning))}
            size="small"
            variant="outlined"
            disabled={working}
          >
            Destroy
          </Button>
        </div>
      )}

      <div>
        <span className="fw-500 mr-2">Creator:</span>
        {fridge_cleaning.creator.username}
      </div>

      <div>
        <span className="fw-500 mr-2">Cleaner:</span>
        {fridge_cleaning.worker.username}
      </div>

      <div>
        <span className="fw-500 mr-2">Type:</span>
        {fridge_cleaning.type}
      </div>

      <div>
        <span className="fw-500 mr-2">Brand:</span>
        {fridge_cleaning.brand}
      </div>

      <div>
        <span className="fw-500 mr-2">Item:</span>
        {fridge_cleaning.product_type}
      </div>

      <div>
        <span className="fw-500 mr-2">Status:</span>
        {fridge_cleaning.status}
      </div>

      <div>
        <span className="fw-500 mr-2">Serial #:</span>
        {fridge_cleaning.serial_number}
      </div>

      <div>
        <span className="fw-500 mr-2">Performed on :</span>
        {date}
      </div>

      {!!school && (
        <div>
          <span className="fw-500 mr-2">School:</span>
          {fridge_cleaning.school?.name || null}
        </div>
      )}

      {!!building && (
        <div>
          <span className="fw-500 mr-2">Building:</span>
          {fridge_cleaning.getBuilding()?.shown_name || null}
        </div>
      )}

      {!!warehouse && (
        <div>
          <span className="fw-500 mr-2">Warehouse:</span>
          {fridge_cleaning.getWarehouse()?.name || null}
        </div>
      )}

      {isPresent(room_str) && (
        <div>
          <span className="fw-500 mr-2">Room:</span>
          {fridge_cleaning.room_str}
        </div>
      )}

      {isPresent(fridge_cleaning.notes) && (
        <div className="mt-3">
          <span className="fw-500 mr-2">Notes:</span>
          {fridge_cleaning.notes}
        </div>
      )}

      {renderImg(fridge_cleaning.id, fridge_cleaning.image_file_name)}
    </div>
  );
};

export default FridgeCleaningDetail;
