import { Store } from 'set-state-is-great';
import { AppState, RouteState } from './types/state_types';
import StateRouter from './StateRouter';
import { AllModels } from './types/types';
import { green, blueGrey, lightBlue, red, blue, deepPurple, orange } from '@material-ui/core/colors';
import Filter from './models/Filter';

export const data: Partial<AllModels> = {};
export let store: Store<AppState>;
export let router: StateRouter<RouteState>;

export function getAllData() {
  return data;
}

export function setModels<Key extends keyof AllModels>(key: Key, models: AllModels[Key]) {
  data[key] = models;
  return models;
}

export function getModels<Key extends keyof AllModels>(key: Key) {
  const models = data[key];

  if (models) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return models!;
  } else {
    throw new Error(`model ${key} missing`);
  }
}

export const colors = {
  primary: blueGrey[500],
  secondary: lightBlue[500],
  pending_removal: red[500],
  uninstalled: orange[500],
  removed: blue[500],
  set_missing: deepPurple[500],
  success: green[500],
};

export const shorts = ['BL', 'MF', 'SR', 'BS', 'ES', 'TV32', 'TV40', 'DFT', 'FT'];
export const short_shorts = ['BL', 'MF', 'SR', 'BS', 'ES', 'TV', 'FT'];

type StringMap = {
  [key: string]: string;
};

const shortShortMap: StringMap = {
  BL: 'BL',
  MF: 'MF',
  SR: 'SR',
  BS: 'BS',
  ES: 'ES',
  TV32: 'TV',
  TV40: 'TV',
  FT: 'FT',
  DFT: 'FT',
};

export const getShortShort = (short: string) => {
  const short_short = shortShortMap[short];

  if (!short_short) {
    throw new Error(`${short} couldnt be mapped to short_short`);
  }

  return short_short;
};

export const getStore = () => {
  return store;
};

export const setGlobals = (theStore: Store<AppState>, theRouter: StateRouter<RouteState>, filter: Filter | null) => {
  router = theRouter;
  store = theStore;

  window.App = {
    router: theRouter,
    store: theStore,
    filter: filter,
  };
};

export type Count =
  | 'bl_count'
  | 'mf_count'
  | 'sr_count'
  | 'ft_count'
  | 'dft_count'
  | 'bs_count'
  | 'es_count'
  | 'tv32_count'
  | 'tv40_count';

export const counts: Array<Count> = [
  'bl_count',
  'mf_count',
  'sr_count',
  'ft_count',
  'dft_count',
  'bs_count',
  'es_count',
  'tv32_count',
  'tv40_count',
];
