import React from 'react';
import StatsTabs from './StatsTabs';
import { store } from '../../constants';
import AllStats from './AllStats';
import FilteredStats from './FilteredStats';

const { useStoreState } = store.getHelpers('stats_page');

const StatsPage = () => {
  const { tab } = useStoreState();

  return (
    <div>
      <StatsTabs />
      {tab === 0 ? <FilteredStats /> : <AllStats />}
    </div>
  );
};

export default StatsPage;
