import React from 'react';

import { centsToDollarString } from '../../util';
import Payscale from '../../models/Payscale';
import { short_shorts, colors } from '../../constants';
import User from '../../models/User';

let key1 = 1;

const renderPayscaleTd = (short: string, payscale: Payscale) => {
  const key = 'payscale_' + key1++;
  const str = centsToDollarString(payscale.short_cents[short]);

  return <td key={key}>{str}</td>;
};

type PayscalesTableProps = {
  user: User;
};

function PayscalesTable(props: PayscalesTableProps) {
  const uninstalled_payscale = Payscale.find('uninstalled', props.user);
  const removed_payscale = Payscale.find('removed', props.user);

  return (
    <div>
      <div>
        <span className="ml-2">
          <b>prices:</b>
        </span>
        <span className="ml-2">total</span>
        <span className="ml-2 " style={{ color: colors.uninstalled }}>
          uninstall
        </span>
        <span className="ml-2" style={{ color: colors.removed }}>
          removal
        </span>
      </div>
      <table className="muhtable">
        <thead>
          <tr>
            {short_shorts.map(s => (
              <th key={key1++}>{s}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr className="text_uninstalled" style={{ color: colors.uninstalled }}>
            {short_shorts.map(s => {
              return renderPayscaleTd(s, uninstalled_payscale);
            })}
          </tr>
          <tr className="text_removed" style={{ color: colors.removed }}>
            {short_shorts.map(s => {
              return renderPayscaleTd(s, removed_payscale);
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default PayscalesTable;
