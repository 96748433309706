import React from 'react';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { store } from '../../constants';
import Box from '@material-ui/core/Box';
import { BrowserBarcodeReader } from '@zxing/library';
import { setupZXing, getBarcodeScanningModalState } from '../../helpers/barcode_helpers';
import TextField from '@material-ui/core/TextField';

const { getState, setState, useStoreState } = store.getHelpers('barcode_scanning_modal');

function getModalStyle() {
  return {
    zIndex: 99999,
    top: '0',
    left: '0',
    outline: '0',
    width: '100%',
    border: 'none',
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      position: 'absolute',
      width: '100%',
      height: '100%',
      overflow: 'scroll',
      backgroundColor: theme.palette.background.paper,
      padding: 5,
    },
  }),
);

const handleClose = () => {
  getState().codeReader?.reset();
  const state = getBarcodeScanningModalState(false);
  setState(state);
};

const clickReset = () => {
  getState().codeReader?.reset();
  setState({ scanning: false, serialNumber: '' });
};

const clickSave = () => {
  store.setState('fridge_cleaning_modal', { serial_number: getState().serialNumber });

  getState().codeReader?.reset();
  setState({ open: false, scanning: false });
};

const clickStart = (codeReader: BrowserBarcodeReader, selectedDeviceId: string) => {
  setState({ scanning: true });
  setTimeout(() => {
    codeReader
      .decodeOnceFromVideoDevice(selectedDeviceId, 'video')
      .then(result => {
        codeReader.reset();
        setState({ scanning: false, serialNumber: result.getText() });
      })
      .catch(err => {
        console.error(err);
      });
  }, 1);
};

const renderButtons = () => {
  const { codeReader, selectedDeviceId, scanning } = getState();
  if (codeReader) {
    return (
      <React.Fragment>
        <Button onClick={handleClose} variant="outlined" size="large">
          Cancel
        </Button>

        {scanning ? (
          <Button onClick={clickReset} variant="outlined" size="large">
            Reset
          </Button>
        ) : (
          <Button onClick={() => clickStart(codeReader, selectedDeviceId)} variant="outlined" size="large">
            Scan
          </Button>
        )}

        <Button onClick={clickSave} variant="outlined" size="large">
          Set #
        </Button>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Button variant="outlined" size="large" disabled>
          Cancel
        </Button>

        <Button variant="outlined" size="large" disabled>
          Scan
        </Button>

        <Button variant="outlined" size="large" disabled>
          Set
        </Button>
      </React.Fragment>
    );
  }
};

const renderVideo = (hasCodeReader: boolean, scanning: boolean) => {
  if (hasCodeReader) {
    if (scanning) {
      return (
        <Box display="flex" justifyContent="center">
          <video id="video"></video>
        </Box>
      );
    } else {
      return null;
    }
  } else {
    return <Typography>Loading...</Typography>;
  }
};

const BarcodeScanningModal = () => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const state = useStoreState();
  const { open, codeReader, scanning, serialNumber } = state;

  if (!codeReader) {
    setupZXing();
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <div style={modalStyle} className={classes.paper}>
        <Typography style={{ textAlign: 'center' }} variant="h6" gutterBottom>
          Scan/input barcode
        </Typography>
        <Box>{renderVideo(!!codeReader, scanning)}</Box>
        <div>
          <TextField
            value={serialNumber}
            onChange={e => setState({ serialNumber: e.target.value })}
            style={{ width: '95%' }}
            label="Serial Number"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            className="mt-2 mb-4"
          />

          <Box display="flex" justifyContent="space-between">
            {renderButtons()}
          </Box>
        </div>
      </div>
    </Modal>
  );
};

export default BarcodeScanningModal;
