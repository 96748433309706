import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';

import Modal from '@material-ui/core/Modal';
import { store, getModels, Count, counts } from '../../constants';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ApiRequest, { ApiRequestCallbacks } from '../../ApiRequest';
import { DropoffRespJSON, isErrorResponse } from '../../types/json_types';
import { findModelById } from '../../app_util';
import Dropoff from '../../models/Dropoff';
import { getDropoffModalState, setDropoffModalState } from '../../helpers/dropoff_helpers';
import ErrorAlert from '../ErrorAlert';
import { DropoffModalState } from '../../types/state_types';
import Box from '@material-ui/core/Box';
import BuildingsSelect from '../selects/BuildingsSelect';
import SchoolSelectMeh from '../job_list/SchoolSelectMeh';
import Divider from '@material-ui/core/Divider';
import { andJoin } from '../../util';
import toUpper from 'lodash/toUpper';
import DropoffTypeSelect from './DropoffTypeSelect';
import UserSelect from '../UserSelect';
import MultiUserSelect from '../MultiUserSelect';
import WarehouseSelect from './WarehouseSelect';
import DatePicker from '../DatePicker';
import strftime from 'strftime';

const { getState, setState, useStoreState } = store.getHelpers('dropoff_modal');

function getModalStyle() {
  return {
    width: '100%',
    top: '0',
    left: '0',
    bottom: '0',
    outline: '0',
    border: 'none',
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: 0,
    },
  }),
);

const clickUpdate = (state: DropoffModalState, school_id: number | null) => {
  if (!school_id) {
    throw new Error('no school_id');
  }

  if (state.working) {
    return;
  }

  const callbacks: ApiRequestCallbacks = {
    onLoading: () => setState({ working: true }),
    onSuccess: (resp: unknown) => {
      const json = resp as DropoffRespJSON;

      const allDropoffs = getModels('dropoffs');
      const dropoff = findModelById(allDropoffs, json.dropoff.id);

      const nextDropoff = new Dropoff(json.dropoff);

      Object.assign(dropoff, nextDropoff);

      const appState = store.getFullStore();
      appState.dropoff_modal = getDropoffModalState(null, false);

      store.forceUpdate('main');
    },
    onError: (json: unknown) => {
      if (isErrorResponse(json)) {
        setState({ errors: json.errors });
      } else {
        alert('some funky error occurred');
      }
    },
    onDone: () => setState({ working: false }),
  };

  const data = { ...state, errors: [], school_id };
  const request = new ApiRequest(`/api/dropoffs/${state.id}.json`, 'PATCH', callbacks, data);
  request.perform();
};

function clickSubmit(state: DropoffModalState, school_id: number | null) {
  if (!school_id) {
    throw new Error('no school_id');
  }

  if (state.working) {
    return;
  }

  const callbacks: ApiRequestCallbacks = {
    onLoading: () => setState({ working: true }),
    onSuccess: (resp: unknown) => {
      const json = resp as DropoffRespJSON;
      const dropoff = new Dropoff(json.dropoff);
      getModels('dropoffs').unshift(dropoff);
      const appState = store.getFullStore();

      appState.dropoff_modal = getDropoffModalState(null, false);

      store.forceUpdate('main');
    },
    onError: (json: unknown) => {
      if (isErrorResponse(json)) {
        setState({ errors: json.errors });
      } else {
        alert('some funky error occurred');
      }
    },
    onDone: () => setState({ working: false }),
  };

  const data = { ...state, errors: [], school_id };
  const request = new ApiRequest('/api/dropoffs.json', 'POST', callbacks, data);
  request.perform();
}

const handleClose = () => {
  setDropoffModalState(null, false);
};

const onChange = <Key extends keyof DropoffModalState>(attr: Key, value: DropoffModalState[Key]) => {
  setState({ [attr]: value });
};

const clearErrors = () => {
  store.setState('dropoff_modal', { errors: [] });
};

const handleChangeCount = (count: Count, num: number) => {
  num = num < 0 ? 0 : num;
  setState({ [count]: num });
};

const renderCount = (count: Count) => {
  const val = getState()[count];

  const short = toUpper(count.split('_')[0]);

  return (
    <div key={`td_${count}`} className="d-flex mt-3">
      <TextField
        id="standard-number"
        label={short}
        type="number"
        style={{ maxWidth: 100 }}
        variant="outlined"
        size="small"
        value={val || ''}
        onChange={e => handleChangeCount(count, parseInt(e.target.value))}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </div>
  );
};

const DropoffModal = () => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const state = useStoreState();

  const title = state.id ? 'Update truck loading' : 'Create truck loading';

  let dropoff: Dropoff | null = null;
  let school_id: number | null = null;

  if (state.id) {
    dropoff = findModelById(getModels('dropoffs'), state.id);
    school_id = dropoff.school_id;
  } else {
    if (window.App.filter?.school_id) {
      school_id = window.App.filter.school_id;
    }
  }

  if (!school_id) {
    return (
      <Modal open={state.open} onClose={handleClose}>
        <div style={modalStyle} className={classes.paper}>
          <Typography variant="h6" className="d-flex" style={{ justifyContent: 'center' }}>
            Select a school
          </Typography>
          <SchoolSelectMeh />;
        </div>
      </Modal>
    );
  }

  const allBuildings = getModels('buildings');
  const buildingNames = state.building_ids.map(id => findModelById(allBuildings, id).shown_name);

  const allUsers = getModels('users');
  const userNames = state.worker_ids.map(id => findModelById(allUsers, id).username);

  return (
    <Modal open={state.open} onClose={handleClose}>
      <div style={modalStyle} className={classes.paper}>
        <div>
          <Typography variant="h6" className="d-flex" style={{ justifyContent: 'center' }}>
            {title}
          </Typography>

          <ErrorAlert errors={state.errors} closeAlert={clearErrors} />
        </div>

        <Divider className="mb-2" />

        <Box flexGrow="1" style={{ overflow: 'scroll' }} className="pl-1">
          <DropoffTypeSelect type={state.type} onChange={(type: string) => setState({ type })} />

          <Box display="flex" justifyContent="between" alignItems="center">
            <BuildingsSelect
              label={'select buildings (optional)'}
              school_id={school_id}
              building_ids={state.building_ids}
              onChange={bids => onChange('building_ids', bids)}
            />

            {state.building_ids.length > 0 && <div style={{ maxWidth: '50%' }}>{andJoin(buildingNames)}</div>}
          </Box>

          <div className="my-3">
            <UserSelect
              label="Who's driving?"
              nullLabel="no driver"
              user_id={state.driver_id}
              onChange={(user_id: number | null) => setState({ driver_id: user_id })}
            />
          </div>

          <div className="mt-4">
            <div>performed on: {strftime('%b %e %Y', state.performed_at)}</div>
            <DatePicker
              label="change date"
              date={state.performed_at}
              onChange={(d: Date | null) => setState({ performed_at: d || new Date() })}
            />
          </div>

          <div className="my-3">
            <WarehouseSelect
              label="Select a warehouse (optional)"
              nullLabel="no warehouse"
              warehouse_id={state.warehouse_id}
              onChange={(warehouse_id: number | null) => setState({ warehouse_id: warehouse_id })}
            />
          </div>

          <div className="my-3 d-flex align-items-center">
            <MultiUserSelect
              user_ids={state.worker_ids}
              label="who loaded the truck?"
              onChange={(user_ids: number[]) => setState({ worker_ids: user_ids })}
            />

            <div style={{ maxWidth: '50%' }}>{andJoin(userNames)}</div>
          </div>

          <div>{counts.map(renderCount)}</div>

          <TextField
            style={{ width: '90%' }}
            className="my-3"
            label="notes"
            multiline
            rows="4"
            value={state.notes}
            onChange={e => onChange('notes', e.target.value)}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>

        <Divider className="mt-1" />

        <div className="d-flex justify-content-between align-items-center mx-4 my-3">
          <Button onClick={handleClose} variant="outlined" disabled={state.working}>
            Cancel
          </Button>

          {state.id ? (
            <Button onClick={() => clickUpdate(state, school_id)} variant="outlined" disabled={state.working}>
              Update
            </Button>
          ) : (
            <Button onClick={() => clickSubmit(state, school_id)} variant="outlined" disabled={state.working}>
              Submit
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default DropoffModal;
