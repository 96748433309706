import React from 'react';
import { router } from '../../constants';
import Box from '@material-ui/core/Box';
import { groupFridgeCleanings } from '../../helpers/fridge_grouping_helpers';
import FridgeCleaning from '../../models/FridgeCleaning';
import sortBy from 'lodash/sortBy';

const clickRow = (id: number) => {
  router.go(`/fridge_cleaning_group/${id}`);
};

const style = { minHeight: 40 };

let blah = 0;

const renderCleaningGroupRow = (fc: FridgeCleaning, arr: FridgeCleaning[]) => {
  const location = fc.getLocationText();
  const cnt = arr.length;
  const username = fc.worker.username;
  const day_month_str = fc.dayMonth();

  return (
    <div
      key={'cleaning_row_' + blah++}
      style={style}
      onClick={() => {
        clickRow(fc.id);
      }}
      className="dropoff-row list-group-item d-flex justify-content-between align-items-center p-1"
    >
      <div className="d-flex align-items-center">
        {username} on {day_month_str} at {location}: {cnt} tasks
      </div>
    </div>
  );
};

const FridgeCleaningList = () => {
  const groups = sortBy(groupFridgeCleanings(), group => -group[0].performed_at_ts);
  return (
    <Box className="fit">
      <div id="cleanings">{groups.map(group => renderCleaningGroupRow(group[0], group[1]))}</div>
    </Box>
  );
};

export default FridgeCleaningList;
