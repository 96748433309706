import React from 'react';
import { store, shorts as allShorts, getModels } from '../../constants';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { spliceFromArray, andJoin } from '../../util';
import SchoolSelect from '../filter_modal/SchoolSelect';
import { filterRooms } from '../../helpers/export_helpers';
import Room from '../../models/Room';
import { findModelById } from '../../app_util';
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
import Student from '../../models/Student';
import Typography from '@material-ui/core/Typography';
import sortBy from 'lodash/sortBy';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const { useStoreState, getState, setState } = store.getHelpers('export');

const style = {
  minWidth: 5,
  padding: '5px',
};

const clickButton = (short: string, selected: boolean) => {
  const shorts = getState().shorts;

  if (selected) {
    spliceFromArray(shorts, short);
  } else {
    shorts.push(short);
  }

  store.forceUpdate('export');
};

const renderShort = (short: string, selected: boolean) => {
  const key = `export_btn_${short}`;
  const variant = selected ? 'contained' : 'outlined';
  return (
    <Button
      key={key}
      variant={variant}
      size="small"
      style={style}
      className="mr-2"
      disableElevation
      onClick={() => clickButton(short, selected)}
    >
      {short}
    </Button>
  );
};

const clickField = (field: string, selected: boolean) => {
  const fields = getState().fields;

  if (selected) {
    spliceFromArray(fields, field);
  } else {
    fields.push(field);
  }

  store.forceUpdate('export');
};

const renderField = (field: string, selected: boolean) => {
  const key = `export_btn_${field}`;
  const variant = selected ? 'contained' : 'outlined';
  return (
    <Button
      key={key}
      variant={variant}
      size="small"
      style={style}
      className="mr-2"
      disableElevation
      onClick={() => clickField(field, selected)}
    >
      {field}
    </Button>
  );
};

// https://stackoverflow.com/a/2044793/548170
function selectElementContents(el: HTMLElement) {
  if (document.createRange && window.getSelection) {
    const range = document.createRange();
    const sel = window.getSelection();
    sel?.removeAllRanges();
    try {
      range.selectNodeContents(el);
      sel?.addRange(range);
    } catch (e) {
      range.selectNode(el);
      sel?.addRange(range);
    }
  }
}

const clickCopy = () => {
  const copyText = document.getElementById('export-select');

  if (!copyText) {
    throw new Error('selectable element not found');
  }

  selectElementContents(copyText);
};

let yeg = 0;

const allFields = ['area', 'student'];

const renderRow = (room: Room) => {
  const { shorts, fields } = getState();

  const showStudents = fields.includes('student');
  if (showStudents) {
    const products_and_students = compact(
      room.installs.map(i =>
        shorts.includes(i.product.short) ? ([i.product.short, i.student_id] as [string, number]) : null,
      ),
    );

    const products: string[] = products_and_students.map(ps => ps[0]);
    const allStudents = getModels('students');
    const students: Student[] = uniq(products_and_students.map(ps => ps[1])).map((student_id: number) =>
      findModelById(allStudents, student_id),
    );

    const studentNames = students.map(s => `${s.fullName()} - ${s.phone_number}`);

    return (
      <tr key={`yeg_${yeg++}`}>
        <td>{room.wingName()}</td>
        <td>{room.building.shown_name}</td>
        {fields.includes('area') && <td>{room.building.area}</td>}
        <td className="products">{products.join(', ')}</td>
        <td>{andJoin(studentNames)}</td>
      </tr>
    );
  } else {
    const products = compact(room.installs.map(i => (shorts.includes(i.product.short) ? i.product.short : null)));

    return (
      <tr key={`yeg_${yeg++}`}>
        <td>{room.wingName()}</td>
        <td>{room.building.shown_name}</td>
        {fields.includes('area') && <td>{room.building.area}</td>}
        <td className="products">{products.join(', ')}</td>
      </tr>
    );
  }
};

const renderCsvRow = (room: Room, showArea: boolean, showStudent: boolean) => {
  const { shorts } = getState();

  const wingName = room.wingName().replace(',', ' ');

  if (showStudent) {
    const products_and_students = compact(
      room.installs.map(i =>
        shorts.includes(i.product.short) ? ([i.product.short, i.student_id] as [string, number]) : null,
      ),
    );

    const products: string[] = products_and_students.map(ps => ps[0]);
    const allStudents = getModels('students');
    const students: Student[] = uniq(products_and_students.map(ps => ps[1])).map((student_id: number) =>
      findModelById(allStudents, student_id),
    );

    const studentNames = students.map(s => `${s.fullName()} - ${s.phone_number}`).join(' ++ ');

    const area = showArea ? room.building.area : null;
    const str = compact([wingName, room.building.shown_name, area, products.join('+'), studentNames]).join(',');

    return <div key={`yeg_${yeg++}`}>{str}</div>;
  } else {
    const products = compact(room.installs.map(i => (shorts.includes(i.product.short) ? i.product.short : null)));

    const area = showArea ? room.building.area : null;
    const str = compact([wingName, room.building.shown_name, area, products.join('+')]).join(',');

    return <div key={`yeg_${yeg++}`}>{str}</div>;
  }
};

const renderData = (export_type: 'table' | 'csv', fields: string[], rooms: Room[]) => {
  const showArea = fields.includes('area');
  const showStudent = fields.includes('student');
  if (export_type === 'table') {
    return (
      <table id="export-select">
        <thead>
          <tr>
            <th>room</th>
            <th>building</th>
            {showArea && <th>area</th>}
            <th className="products">products</th>
            {showStudent && <th>students</th>}
          </tr>
        </thead>
        <tbody>{rooms.map(renderRow)}</tbody>
      </table>
    );
  } else {
    const area = showArea ? 'area' : null;
    const student = showStudent ? 'students' : null;
    const top_str = compact(['room', 'building', area, 'products', student]).join(',');
    return (
      <div id="export-select">
        <div>{top_str}</div>
        {rooms.map(r => renderCsvRow(r, showArea, showStudent))}
      </div>
    );
  }
};

const Export = () => {
  const state = useStoreState();
  const { shorts, fields, export_type } = state;
  let school_id = state.school_id;

  if (!school_id) {
    school_id = window.App.filter?.school_id || getModels('schools')[0].id;
  }

  let rooms = filterRooms(school_id, shorts);

  rooms = sortBy(rooms, r => `${r.building.area}-${r.building.shown_name}`);

  return (
    <div className="p-3">
      <div className="my-2">for school:</div>
      <SchoolSelect school_id={school_id} onChange={school_id => setState({ school_id })} />
      <div className="mt-2">filter by these products:</div>
      <div className="mt-2">{allShorts.map(s => renderShort(s, shorts.includes(s)))}</div>
      <div className="mt-2">& show these fields:</div>
      <div className="mt-2">{allFields.map(f => renderField(f, fields.includes(f)))}</div>

      <div className="my-4">
        <FormControl variant="outlined">
          <InputLabel htmlFor="locked-select">Type</InputLabel>
          <Select
            id="export-type-select"
            native
            style={{ minWidth: 100 }}
            value={export_type}
            onChange={e => setState({ export_type: e.target.value === 'csv' ? 'csv' : 'table' })}
          >
            <option value={'table'}>Table</option>
            <option value={'csv'}>CSV</option>
          </Select>
        </FormControl>
      </div>

      <Button variant="outlined" color="secondary" startIcon={<FileCopyIcon />} className="mb-2" onClick={clickCopy}>
        select contents
      </Button>

      <Typography variant="h6">{rooms.length} Results </Typography>

      {renderData(export_type, fields, rooms)}
    </div>
  );
};

export default Export;
