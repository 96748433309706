import React from 'react';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { store, getModels } from '../../constants';
import { FilterModalState } from '../../types/state_types';
import Box from '@material-ui/core/Box';
import SchoolSelect from './SchoolSelect';
import Filter from '../../models/Filter';
import { FilterJSON } from '../../types/json_types';
import BuildingsSelect from '../selects/BuildingsSelect';
import { findModelById } from '../../app_util';
import { andJoin } from '../../util';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ProductsSelect from './ProductsSelect';
import ProductStatusesSelect from './ProductStatusesSelect';

const { getState, setState: setModalState, useStoreState } = store.getHelpers('filter_modal');

function getModalStyle() {
  return {
    zIndex: 99999,
    top: '0',
    left: '0',
    outline: '0',
    width: '100%',
    border: 'none',
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.background.paper,
      padding: 5,
    },
  }),
);

const handleReset = () => {
  const state: FilterModalState = Filter.emptyModalState();
  state.school_id = getModels('schools')[0].id;
  state.open = true;
  setModalState(state);
};

const handleClose = () => {
  const state: FilterModalState = Filter.emptyModalState();

  setModalState(state);
};

const changeShowAvailable = (e: React.ChangeEvent<HTMLInputElement>) => {
  setModalState({ only_show_available_jobs: e.target.checked });
};

const clickSave = () => {
  const state = getState();
  if (!state.school_id) {
    throw new Error(`school_id should be present here`);
  }

  const json: FilterJSON = {
    school_id: state.school_id,
    building_ids: state.building_ids,
    only_show_available_jobs: state.only_show_available_jobs,
    statuses: state.statuses,
    product_ids: state.product_ids,
  };

  const filter = new Filter(json);

  filter.saveToLocalStorage();

  store.getFullStore().filter_modal = Filter.emptyModalState();
  window.App.filter = filter;

  store.forceUpdate('main');
  store.forceUpdate('filter_modal');
};

const onChangeBuildingIds = (building_ids: number[]) => {
  setModalState({ building_ids });
};

const onChangeSchool = (school_id: number) => {
  setModalState({ school_id, building_ids: [] });
};

const FilterModal = () => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const state = useStoreState();
  const { open, statuses, building_ids, product_ids, only_show_available_jobs } = state;

  if (!open) {
    return null;
  }

  const school_id = state.school_id || getModels('schools')[0].id;

  const allBuildings = getModels('buildings');
  const buildingNames = building_ids.map(id => findModelById(allBuildings, id).shown_name);

  const allProducts = getModels('products');
  const productShorts = product_ids.map(id => findModelById(allProducts, id).short);

  return (
    <Modal open={open} onClose={handleClose}>
      <div style={modalStyle} className={classes.paper}>
        <Typography style={{ textAlign: 'center' }} variant="h6" gutterBottom>
          Set Your Filter
        </Typography>
        <Box flexGrow="1">
          <SchoolSelect school_id={school_id} onChange={onChangeSchool} />

          {school_id && (
            <Box className="mt-3" display="flex" justifyContent="space-between">
              <BuildingsSelect
                label="filter by building(s)"
                school_id={school_id}
                building_ids={building_ids}
                onChange={onChangeBuildingIds}
              />

              {building_ids.length > 0 && <div style={{ maxWidth: '50%' }}>{andJoin(buildingNames)}</div>}
            </Box>
          )}

          <Box className="mt-3 ml-1" display="flex" justifyContent="space-between" alignItems="center">
            <ProductsSelect
              label="Products"
              product_ids={state.product_ids}
              onChange={product_ids => setModalState({ product_ids })}
            />

            {product_ids.length > 0 && <div style={{ maxWidth: '50%' }}>{andJoin(productShorts, 'OR')}</div>}
          </Box>

          <Box className="mt-3 ml-1" display="flex" justifyContent="space-between" alignItems="center">
            <ProductStatusesSelect
              label="Product Statuses"
              statuses={statuses}
              onChange={statuses => setModalState({ statuses })}
            />

            {statuses.length > 0 && <div style={{ maxWidth: '50%' }}>{andJoin(statuses, 'OR')}</div>}
          </Box>

          <div className="mt-3 ml-1">
            <FormControlLabel
              control={
                <Checkbox
                  checked={only_show_available_jobs}
                  onChange={changeShowAvailable}
                  value="checkedB"
                  color="primary"
                />
              }
              label="Only show available jobs"
            />
          </div>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Button onClick={handleClose} variant="outlined" size="large">
            Cancel
          </Button>

          <Button onClick={handleReset} variant="outlined" size="large">
            Reset
          </Button>

          <Button onClick={clickSave} variant="outlined" size="large">
            Save
          </Button>
        </Box>
      </div>
    </Modal>
  );
};

export default React.memo(FilterModal);
