import { getModels } from '../constants';
import Room from '../models/Room';
import { doesIntersect } from '../util';
import Install from '../models/Install';

// export const roomFullname = (room: Room) => {};

const getRoomShorts = (room: Room) => {
  return room.installs.map(i => i.product.short);
};

interface Blah {
  [student_id: number]: string[];
}

export const installsGroupedByStudent = (room: Room, shorts: string[]) => {
  const grouped = new Map<number, string[]>();

  room.installs.forEach((i: Install) => {
    if (shorts.includes(i.product.short)) {
      if (!grouped.get(i.student_id)) {
        grouped.set(i.student_id, []);
      }

      grouped.get(i.student_id)?.push(i.product.short);
    }
  });

  return grouped;
};

export const filterRooms = (school_id: number, shorts: string[]) => {
  const rooms = getModels('rooms');

  const results: Room[] = [];

  for (let index = 0; index < rooms.length; index++) {
    const room = rooms[index];

    if (room.building.school_id === school_id) {
      const roomShorts = getRoomShorts(room);
      if (doesIntersect(roomShorts, shorts)) {
        results.push(room);
      }
    }
  }

  return results;
};
