import React from 'react';
import Button from '@material-ui/core/Button';
import { store, getModels } from '../../constants';
import ApiRequest, { ApiRequestCallbacks } from '../../ApiRequest';
import { isErrorResponse } from '../../types/json_types';
import { findModelById } from '../../app_util';
import red from '@material-ui/core/colors/red';
import { spliceFromArray, andJoin } from '../../util';
import FieldNote from '../../models/FieldNote';

const { setState } = store.getHelpers('field_note_modal');

const destroyFieldNote = (field_note_id: number, working: boolean) => {
  if (working) {
    return;
  }

  if (window.confirm('are you sure?')) {
    const callbacks: ApiRequestCallbacks = {
      onLoading: () => setState({ working: true }),
      onSuccess: () => {
        const allFieldNotes = getModels('field_notes');
        const field_note = findModelById(allFieldNotes, field_note_id);
        spliceFromArray(allFieldNotes, field_note);

        store.forceUpdate('main');
        setState(FieldNote.getModalState(null, false));
      },
      onError: (json: unknown) => {
        if (isErrorResponse(json)) {
          alert('Error:' + andJoin(json.errors));
        } else {
          alert('some funky error occurred');
        }
      },
      onDone: () => setState({ working: false }),
    };

    const request = new ApiRequest(`/api/field_notes/${field_note_id}.json`, 'DELETE', callbacks);
    request.perform();
  }
};

interface FieldNoteDestroyButtonProps {
  working: boolean;
  field_note_id: number;
}

const FieldNoteDestroyButton = (props: FieldNoteDestroyButtonProps) => {
  return (
    <Button
      style={{ color: red[900], borderColor: red[900] }}
      onClick={() => destroyFieldNote(props.field_note_id, props.working)}
      variant="outlined"
      disabled={props.working}
    >
      Destroy
    </Button>
  );
};

export default FieldNoteDestroyButton;
