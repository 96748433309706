import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { store } from '../../constants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

const { setState, useStoreState } = store.getHelpers('stats_page', ['tab']);

const changeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
  setState({ tab: newValue });
};

function StatsTabs() {
  const { tab } = useStoreState();

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs value={tab} onChange={changeTab} indicatorColor="primary" textColor="primary">
          <Tab label="Filtered stats" />
          <Tab label="All stats" />
        </Tabs>
      </AppBar>
    </div>
  );
}

export default React.memo(StatsTabs);
