import React from 'react';
import { store, router } from '../../constants';
import FridgeCleaning from '../../models/FridgeCleaning';
import strftime from 'strftime';
import { getFridgeCleaningsByGroup } from '../../helpers/fridge_grouping_helpers';
import { getImageUrl } from '../../helpers/paperclip_helpers';

const { useStoreState } = store.getHelpers('fridge_cleaning_group_detail');

const clickRow = (id: number) => {
  router.go(`/fridge_cleanings/${id}`);
};

let j = 0;

const renderImg = (id: number, image_file_name: string | null) => {
  if (!image_file_name) {
    return null;
  }

  const thumb = getImageUrl(id, 'fridge_cleanings', 'thumb', image_file_name);
  const original = getImageUrl(id, 'fridge_cleanings', 'original', image_file_name);

  return (
    <a href={original} target="_blank" rel="noopener noreferrer">
      <img alt="" width="50" src={thumb} style={{ marginRight: 5 }} />
    </a>
  );
};

const renderCleaning = (fc: FridgeCleaning) => {
  const date = strftime('%l:%M %p', fc.performed_at);
  const key = `keybrah${j++}`;
  return (
    <div
      key={key}
      className="list-group-item d-flex align-items-center"
      style={{ minHeight: 35 }}
      onClick={() => clickRow(fc.id)}
    >
      {renderImg(fc.id, fc.image_file_name)}
      {fc.type}ed {fc.product_type} & set to {fc.status} on {date}
    </div>
  );
};

const FridgeCleaningGroupDetail = () => {
  const { fridge_cleaning } = useStoreState();

  if (!fridge_cleaning) {
    throw new Error('no fridge_cleaning dudetown');
  }

  const date = strftime('%-m/%-d/%y', fridge_cleaning.performed_at);

  const fridge_cleanings = getFridgeCleaningsByGroup(fridge_cleaning);

  const school = fridge_cleaning.school;
  const building = fridge_cleaning.getBuilding();
  const warehouse = fridge_cleaning.getWarehouse();

  return (
    <div className="p-1 fit">
      <div>
        <span className="fw-500 mr-2">Cleaner:</span>
        {fridge_cleaning.worker.username}
      </div>

      <div>
        <span className="fw-500 mr-2">Performed on :</span>
        {date}
      </div>

      {!!school && (
        <div>
          <span className="fw-500 mr-2">School:</span>
          {fridge_cleaning.school?.name || null}
        </div>
      )}

      {!!building && (
        <div>
          <span className="fw-500 mr-2">Building:</span>
          {fridge_cleaning.getBuilding()?.shown_name || null}
        </div>
      )}

      {!!warehouse && (
        <div>
          <span className="fw-500 mr-2">Warehouse:</span>
          {fridge_cleaning.getWarehouse()?.name || null}
        </div>
      )}

      {fridge_cleanings.map(renderCleaning)}
    </div>
  );
};

export default FridgeCleaningGroupDetail;
