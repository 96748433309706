import ApplicationModel from './ApplicationModel';
import { UserJSON } from '../types/json_types';
import { ClassName } from '../types/types';
import { isPresent } from '../util';

class User extends ApplicationModel {
  email: string;
  username: string;
  first_name: string;
  last_name: string;
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  admin: boolean;
  role: string;
  ssn: string | null;
  phone: string | null;
  agreed_to_terms: boolean;
  active: boolean;

  constructor(json: UserJSON) {
    super(json);
    this.email = json.email;
    this.admin = json.role === 'admin';
    this.address1 = json.address1 || null;
    this.address2 = json.address2 || null;
    this.city = json.city || null;
    this.state = json.state || null;
    this.zip_code = json.zip_code || null;
    this.role = json.role;
    this.username = json.username || '';
    this.first_name = json.first_name;
    this.last_name = json.last_name;
    this.phone = json.phone;
    this.ssn = json.ssn;
    this.agreed_to_terms = json.agreed_to_terms;
    this.active = json.active;
  }

  getClassName() {
    const className: ClassName = 'User';
    return className;
  }

  fullName() {
    return `${this.first_name} ${this.last_name}`;
  }

  isAdmin() {
    return this.role === 'admin';
  }

  selectName() {
    return `${this.username} - ${this.fullName()}`;
  }

  canPassGo() {
    return this.agreed_to_terms && isPresent(this.username) && isPresent(this.first_name) && isPresent(this.last_name);
  }
}

export default User;
