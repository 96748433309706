import React from 'react';
import { store } from '../../constants';
import UserWorkTabs from './UserWorkTabs';
import EarningsPage from './EarningsPage';
import User from '../../models/User';
import Received from './Received';
import UserWorkBonuses from './UserWorkBonuses';

const { useStoreState } = store.getHelpers('user_work_page');

const getView = (user: User, tab: number, date: Date | null, earned_tab: number) => {
  switch (tab) {
    case 0:
      return <EarningsPage user={user} earned_tab={earned_tab} date={date} />;
    case 1:
      return <Received user={user} />;
    case 2:
      return <UserWorkBonuses user={user} />;
    default:
      return null;
  }
};

const UserWorkPage = () => {
  const { user, tab, date, earned_tab } = useStoreState();

  const { viewShown } = store.useStore('main', ['viewShown']);

  if (!user) {
    throw new Error(`user not set`);
  }

  React.useEffect(() => {
    let title: string;
    if (tab === 1) {
      title = `${user.username}'s Paychecks Received`;
    } else if (tab === 2) {
      title = `${user.username}'s Bonuses`;
    } else {
      title = `${user.username}'s Work`;
    }

    if (viewShown === 'UserWork') {
      store.setState('app_bar', { title });
    }
  }, [tab, user.username, viewShown]);

  return (
    <div>
      <UserWorkTabs />
      {getView(user, tab, date, earned_tab)}
    </div>
  );
};

export default UserWorkPage;
