import Ticket from '../models/Ticket';
import { TicketModalState, TicketResolutionModalState } from '../types/state_types';
import { store } from '../constants';
import Filter from '../models/Filter';
import { filterModelsByInclusion } from '../app_util';

export const getTicketModalState = (ticket: Ticket | null, open: boolean): TicketModalState => {
  return {
    id: ticket?.id || null,
    open: open,
    text: ticket?.text || '',
    working: false,
  };
};

export const setTicketModalState = (ticket: Ticket | null, open: boolean) => {
  const state = getTicketModalState(ticket, open);
  store.setState('ticket_modal', state);
};

export const getTicketResolutionModalState = (ticket: Ticket | null): TicketResolutionModalState => {
  return {
    ticket_id: ticket?.id || null,
    open: !!ticket,
    resolution_notes: ticket?.resolution_notes || '',
    working: false,
  };
};

export const setTicketResolutionModalState = (ticket: Ticket | null) => {
  const state = getTicketResolutionModalState(ticket);
  store.setState('ticket_resolution_modal', state);
};

export const filterTickets = (tickets: Ticket[], filter: Filter | null): Ticket[] => {
  if (!filter) {
    return tickets;
  }

  tickets = tickets.filter(t => t.getSchool().id === filter.school_id);

  if (filter.building_ids.length) {
    tickets = filterModelsByInclusion(tickets, 'building_id', filter.building_ids);
  }

  return tickets;
};
