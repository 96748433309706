import React from 'react';
import { router, getModels, store } from '../constants';
import Ticket from '../models/Ticket';
import strftime from 'strftime';
import { filterModelsByAttr, intersperseBuildings } from '../app_util';
import Building from '../models/Building';

const clickRow = (ticket: Ticket) => {
  router.go(`/jobs/${ticket.room_id}`);
};

const style = { minHeight: 60 };
const classes = 'list-group-item d-flex align-items-center pl-1';
const renderTicket = (ticket: Ticket) => {
  const key = `ticket-${ticket.id}`;
  const room = ticket.getRoom();

  const created_at_str = strftime('%b %e %l:%M %p', ticket.created_at);

  return (
    <div key={key} style={style} className={classes} onClick={() => clickRow(ticket)}>
      <div>
        <span className="fw-500">{room.wingName()}:</span>
        {ticket.text}
        <span> -- created {created_at_str}</span>
      </div>
    </div>
  );
};

const renderBuilding = (b: Building) => {
  const key = `na-building-${b.id}`;

  return (
    <div key={key} className="building list-group-item d-flex align-items-center p-2" style={style}>
      <span className="fw-500">
        {b.shown_name} - {b.school.name}
      </span>
    </div>
  );
};

const TicketsPage = () => {
  let tickets = filterModelsByAttr(getModels('tickets'), 'resolved_by_id', null);

  const filter = window.App.filter;

  const school_id = filter?.school_id;
  const building_ids = filter?.building_ids;

  if (school_id) {
    if (building_ids?.length) {
      tickets = tickets.filter(t => building_ids.includes(t.building_id) && t.getSchool().id === school_id);
    } else {
      tickets = tickets.filter(t => t.getSchool().id === school_id);
    }
  }

  const length = tickets.length;

  React.useEffect(() => {
    const title = `Needs Attention Tickets (${length})`;

    if (school_id) {
      let subtitle = 'filtering by school';

      if (building_ids?.length) {
        subtitle = 'filtering by school & buildings';
      }

      store.setState('app_bar', { title, subtitle });
    } else {
      store.setState('app_bar', { title });
    }
  }, [length, school_id, building_ids]);

  const tickets_w_buildings = intersperseBuildings(tickets, getModels('buildings'));

  return (
    <div className="fit">
      {tickets_w_buildings.map(item => (item.isBuilding() ? renderBuilding(item) : renderTicket(item)))}
    </div>
  );
};

export default TicketsPage;
