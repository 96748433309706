import React from 'react';
import Room from '../../models/Room';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import Install from '../../models/Install';
import { sortInstallsByShort } from '../../app_util';
import Student from '../../models/Student';
import { renderInstallButton } from '../../install_view_helpers';
import InstallActionModal from '../InstallActionModal/InstallActionModal';
import { store } from '../../constants';
import { getInstallActionModalState } from '../../install_helpers';
import { InstallActionModalState } from '../../types/state_types';
import sortBy from 'lodash/sortBy';
import { Activity } from '../../types/types';
import { getLockedIcon, getOccupiedIcon, getTicketIcon, getFieldNoteIcon } from '../../icon_helpers';
import OccupiedButton from './OccupiedButton';
import LockedSection from './LockedSection';
import TicketModal from './TicketModal';
import FieldNoteModal from './FieldNoteModal';
import FieldNote from '../../models/FieldNote';
import TicketsSection from './TicketsSection';
import { setTicketModalState } from '../../helpers/ticket_helpers';
import { getImageUrl } from '../../helpers/paperclip_helpers';

const clickInstall = (install: Install) => {
  store.getFullStore().install_actions = getInstallActionModalState(install);

  const state: InstallActionModalState = {
    open: true,
    working: false,
    install: install,
  };

  store.setState('install_action_modal', state);
};

const renderStudentRow = (room: Room, student: Student) => {
  const key = `student_row_${student.id}`;

  let installs = room.installs.filter(i => i.student_id === student.id);

  installs = sortInstallsByShort(installs);

  return (
    <div key={key} className="mt-4">
      <Box display="flex" justifyContent="space-between">
        <div>
          <div>products for {student.fullName()}:</div>
        </div>

        <div>
          <Link href={`tel:${student.phone_number}`} className="mx-3">
            <PhoneIcon />
          </Link>
          <Link href={`mailto:${student.email}`} className="mx-3">
            <EmailIcon />
          </Link>
        </div>
      </Box>

      <div className="d-flex flex-wrap">{installs.map(i => renderInstallButton(i, clickInstall))}</div>
    </div>
  );
};

const renderActions = (activities: Array<Activity>) => {
  if (activities.length) {
    return (
      <div className="mt-2">
        <Typography variant="h6">Activity ({activities.length})</Typography>
        <div className="pl-1">
          {activities.map(a => (
            <div key={a.key}>{a.txt}</div>
          ))}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const clickEditNote = (note: FieldNote) => {
  const state = FieldNote.getModalState(note, true);
  store.setState('field_note_modal', state);
};

const renderImg = (id: number, image_file_name: string | null) => {
  if (!image_file_name) {
    return null;
  }

  const thumb = getImageUrl(id, 'field_notes', 'thumb', image_file_name);
  const original = getImageUrl(id, 'field_notes', 'original', image_file_name);

  return (
    <a href={original} target="_blank" rel="noopener noreferrer">
      <img alt="" width="50" src={thumb} style={{ marginRight: 5 }} />
    </a>
  );
};

const renderFieldNote = (field_note: FieldNote) => {
  const currentUser = window.currentUser;

  return (
    <div key={`field_note-list-${field_note.id}`} className="d-flex mt-3 justify-content-between">
      <Box display="flex">
        {renderImg(field_note.id, field_note.image_file_name)}
        <div>{field_note.getText()}</div>
      </Box>

      <div>
        {(field_note.creator_id === currentUser.id || currentUser.isAdmin()) && (
          <Button onClick={() => clickEditNote(field_note)} size="small" variant="outlined">
            edit
          </Button>
        )}
      </div>
    </div>
  );
};

const renderFieldNotes = (field_notes: FieldNote[]) => {
  if (field_notes.length) {
    return (
      <div className="mt-2">
        <Typography variant="h6">Field Notes ({field_notes.length})</Typography>
        <div className="pl-1">{field_notes.map(renderFieldNote)}</div>
      </div>
    );
  } else {
    return null;
  }
};

const clickNeedsAttention = () => {
  setTicketModalState(null, true);
};

const clickAddFieldNote = () => {
  const state = FieldNote.getModalState(null, true);

  store.setState('field_note_modal', state);
};

const { useStoreState } = store.getHelpers('job_detail');

const JobDetail = () => {
  const { room, locked, occupied } = useStoreState();

  if (!room) {
    throw new Error('deeeef should have a room here, thx breh');
  }

  const activities = sortBy(room.getActivity(), a => a.created_at_ts);

  return (
    <div className="view-div">
      <Typography
        variant="h6"
        className="my-2 d-flex align-items-center justify-content-center"
        style={{ textAlign: 'center' }}
      >
        {room.showName()}
        {getLockedIcon(room)}
        {getOccupiedIcon(room)}
        {getTicketIcon(room)}
        {getFieldNoteIcon(room)}
      </Typography>

      <LockedSection locked={locked} room={room} />

      <Box display="flex" className="mt-3">
        <OccupiedButton occupied={occupied} room={room} />
        <Button variant="outlined" color="default" className="mx-2" onClick={clickNeedsAttention}>
          needs attention?
        </Button>
        <Button variant="outlined" color="default" onClick={clickAddFieldNote}>
          add a field note
        </Button>
      </Box>

      <div className="pl-2 mt-2 mb-3">{room.students.map(s => renderStudentRow(room, s))}</div>

      <TicketsSection tickets={room.getTickets()} />
      {renderFieldNotes(room.getFieldNotes())}
      {renderActions(activities)}

      <InstallActionModal />
      <TicketModal />
      <FieldNoteModal />
    </div>
  );
};

export default JobDetail;
