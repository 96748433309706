import ApplicationModel from './ApplicationModel';
import { PayscaleJSON } from '../types/json_types';
import { ClassName } from '../types/types';
import { getModels } from '../constants';
import User from './User';

export type PayscaleType = 'uninstalled' | 'removed' | 'dropoff' | 'pickup' | 'fridge_cleaning';
export type PayscaleGroup = 'basic' | 'brich';

export interface ShortCents {
  [short: string]: number;
}

class Payscale extends ApplicationModel {
  label: string;
  type: PayscaleType;
  group: PayscaleGroup;
  short_cents: ShortCents;

  constructor(json: PayscaleJSON) {
    super(json);

    this.label = json.label;
    this.type = json.type;
    this.group = json.group;
    this.short_cents = {
      BL: json.bl_cents,
      SR: json.sr_cents,
      MF: json.mf_cents,
      FT: json.ft_cents,
      BS: json.bs_cents,
      ES: json.es_cents,
      TV: json.tv_cents,
    };
  }

  getClassName() {
    const className: ClassName = 'Payscale';
    return className;
  }

  static find(type: PayscaleType, user: User) {
    const install_action = type === 'uninstalled' || type === 'removed';
    const group = install_action && user.id === 15 ? 'brich' : 'basic';
    const payscale = getModels('payscales').find(ps => ps.type === type && ps.group === group);

    if (!payscale) {
      throw new Error(`couldnt find payscale of type ${type} and group basic`);
    }

    return payscale;
  }

  getCents(short: string) {
    return this.short_cents[short];
  }
}

export default Payscale;
