import StateRouter from '../StateRouter';
import { RouteState, View } from '../types/state_types';
import { getStore, getModels } from '../constants';
import { findModelById } from '../app_util';
import { Model } from '../types/types';
import { getFullProfileFormState } from '../helpers/profile_helpers';

const homeRoute = (): RouteState => {
  return { title: 'Home', viewShown: 'Home' };
};

const exportRoute = (): RouteState => {
  return { title: 'Export Some Science', viewShown: 'Export' };
};

const loginRoute = (): RouteState => {
  return { title: 'Login', viewShown: 'Login' };
};

const signupRoute = (): RouteState => {
  return { title: 'Sign Up', viewShown: 'Signup' };
};

const jobsRoute = (): RouteState => {
  return { title: null, viewShown: 'Jobs' };
};

const bonusBucksRoute = (): RouteState => {
  return { title: 'Bonus Bucks', viewShown: 'BonusBucks' };
};

const yourWorkRoute = (): RouteState => {
  return { title: 'Your Work', viewShown: 'UserWork', object: window.currentUser };
};

const statsRoute = (): RouteState => {
  return { title: 'Stats', viewShown: 'Stats' };
};

const profileRoute = (): RouteState => {
  return { title: 'Update Your Profile', viewShown: 'Profile', object: window.currentUser };
};

const editProfileRoute = (user_id_str: string): RouteState => {
  const user = findModelById(getModels('users'), parseInt(user_id_str));

  return {
    title: `Edit ${user.username}'s profile`,
    object: user,
    drill_href: `/workers/${user.id}`,
    viewShown: 'Profile',
  };
};

const workersRoute = (): RouteState => {
  return { title: 'Worker brahs', viewShown: 'Workers' };
};

const payscalesRoute = (): RouteState => {
  return { title: 'Payscales', viewShown: 'Payscales' };
};

const payscaleRoute = (payscale_id_str: string): RouteState => {
  const payscale = findModelById(getModels('payscales'), parseInt(payscale_id_str));

  const title = `"${payscale.label}" payscale`;

  return { title: title, object: payscale, viewShown: 'PayscaleDetail', drill_href: '/payscales' };
};

const dropoffsRoute = (): RouteState => {
  return { title: 'Truck Load', viewShown: 'Dropoffs' };
};

const dropoffRoute = (dropoff_id_str: string): RouteState => {
  const dropoff_id = parseInt(dropoff_id_str);
  const dropoff = findModelById(getModels('dropoffs'), dropoff_id);
  const title = `Dropoff detail`;
  return { title, viewShown: 'DropoffDetail', object: dropoff, drill_href: '/dropoffs' };
};

const ticketsRoute = (): RouteState => {
  return { title: 'Needs Attention Tickets', viewShown: 'Tickets' };
};

const workerRoute = (worker_id_str: string): RouteState => {
  const worker_id = parseInt(worker_id_str);
  const user = findModelById(getModels('users'), worker_id);
  const title = `${user.username}'s work`;
  return { title, viewShown: 'UserWork', object: user };
};

const jobRoute = (room_id_str: string): RouteState => {
  const room_id = parseInt(room_id_str);
  const room = findModelById(getModels('rooms'), room_id);
  const title = room.building.school.name;

  return { title: title, viewShown: 'JobDetail', object: room, object_id: room_id, drill_href: '/jobs' };
};

const buildingRoute = (building_id_str: string): RouteState => {
  const building_id = parseInt(building_id_str);

  const building = findModelById(getModels('buildings'), building_id);
  const title = building.name;
  const subtitle = building.school.name;

  return {
    title,
    subtitle,
    viewShown: 'BuildingDetail',
    object: building,
    object_id: building.id,
    drill_href: '/jobs',
  };
};

const cleaningRoute = (): RouteState => {
  return { title: 'Clean/repairs', viewShown: 'Cleaning' };
};

const fridgeCleaningRoute = (fridge_cleaning_id_str: string): RouteState => {
  const fridge_cleaning_id = parseInt(fridge_cleaning_id_str);
  const fridge_cleaning = findModelById(getModels('fridge_cleanings'), fridge_cleaning_id);
  const title = `Clean/repair detail`;
  return {
    title,
    viewShown: 'FridgeCleaningDetail',
    object: fridge_cleaning,
    drill_href: `/fridge_cleaning_group/${fridge_cleaning_id}`,
  };
};

const fridgeCleaningGroupRoute = (fridge_cleaning_id_str: string): RouteState => {
  const fridge_cleaning_id = parseInt(fridge_cleaning_id_str);
  const fridge_cleaning = findModelById(getModels('fridge_cleanings'), fridge_cleaning_id);
  getStore().getFullStore().fridge_cleaning_group_detail.fridge_cleaning = fridge_cleaning;

  return { title: 'Clean/repair group', viewShown: 'FridgeCleaningGroupDetail', drill_href: '/fridge_cleanings' };
};

const routes = {
  '/': homeRoute,
  '/login': loginRoute,
  '/signup': signupRoute,
  '/jobs': jobsRoute,
  '/stats': statsRoute,
  '/jobs/:id': jobRoute,
  '/buildings/:id': buildingRoute,
  '/your_work': yourWorkRoute,
  '/bonus_bucks': bonusBucksRoute,
  '/profile': profileRoute,
  '/profiles/:id': editProfileRoute,
  '/fridge_cleanings': cleaningRoute,
  '/fridge_cleanings/:id': fridgeCleaningRoute,
  '/fridge_cleaning_group/:id': fridgeCleaningGroupRoute,
  '/payscales': payscalesRoute,
  '/payscales/:id': payscaleRoute,
  '/dropoffs': dropoffsRoute,
  '/dropoffs/:id': dropoffRoute,
  '/export': exportRoute,
  '/workers': workersRoute,
  '/workers/:id': workerRoute,
  '/needs_attention_tickets': ticketsRoute,
};

export const handleState = (viewShown: View, object?: Model) => {
  const store = getStore();

  switch (viewShown) {
    case 'JobDetail':
      if (object?.isRoom()) {
        store.getFullStore().job_detail = {
          room: object,
          locked: object.locked,
          occupied: object.occupied,
        };
      } else {
        throw new Error('should be room here');
      }
      break;
    case 'BuildingDetail':
      if (object?.isBuilding()) {
        store.getFullStore().building_detail = {
          building: object,
        };
      } else {
        throw new Error('should be building here');
      }
      break;
    case 'DropoffDetail':
      if (object?.isDropoff()) {
        store.getFullStore().dropoff_detail = {
          working: false,
          dropoff: object,
        };
      } else {
        throw new Error('should be dropoff here');
      }
      break;
    case 'FridgeCleaningDetail':
      if (object?.isFridgeCleaning()) {
        store.getFullStore().fridge_cleaning_detail = {
          working: false,
          fridge_cleaning: object,
        };
      } else {
        throw new Error('should be fridge cleaningf here');
      }
      break;
    case 'PayscaleDetail':
      if (object?.isPayscale()) {
        store.getFullStore().payscale_detail = {
          payscale: object,
        };
      } else {
        throw new Error('should be payscale here');
      }
      break;
    case 'UserWork':
      if (object?.isUser()) {
        store.getFullStore().user_work_page = {
          tab: 0,
          earned_tab: 0,
          user: object,
          date: new Date(),
        };
      } else {
        throw new Error('should be user here');
      }
      break;
    case 'Profile':
      if (object?.isUser()) {
        store.getFullStore().full_profile_form = getFullProfileFormState(object);
      } else {
        throw new Error('should be user here');
      }
      break;

    default:
      break;
  }
};

export const routeHandler = (state: RouteState | null) => {
  const store = getStore();

  if (state) {
    handleState(state.viewShown, state.object);
  }

  store.setState('app_bar', {
    title: state?.title,
    subtitle: state?.subtitle || null,
    subsubtitle: state?.subsubtitle || null,
    drill_href: state?.drill_href || null,
  });

  store.setState('main', {
    viewShown: state?.viewShown,
  });
};

const router = new StateRouter<RouteState>(routes, routeHandler);

export const handleRoute = (path: string) => {
  const state = router.stateFromPath(path);
  routeHandler(state);
};

export default router;
