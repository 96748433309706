import React from 'react';
import Box from '@material-ui/core/Box';
import { router, shorts, colors } from '../../constants';
import Building from '../../models/Building';
import { InstallStats, RoomTotals } from '../../stats_helper';

const clickBuilding = (building_id: number) => {
  router.go(`/buildings/${building_id}`);
};

let key1 = 99;

const renderSpans = (totals: RoomTotals, short: string) => {
  if (!totals) return null;

  const total = totals.total;
  if (!total) return null;

  const { pending_removal, uninstalled, removed } = totals;

  return (
    <span key={`span${(key1 += 1)}`}>
      <span className="mx-1">
        {short} ({total},<span style={{ color: colors.pending_removal }}>{pending_removal}</span>,
        <span style={{ color: colors.uninstalled }}>{uninstalled}</span>,
        <span style={{ color: colors.removed }}>{removed}</span>)
      </span>
    </span>
  );
};

const BuildingRow = (index: number, style: React.CSSProperties, building: Building, stats: InstallStats) => {
  return (
    <Box
      title={building.shown_name}
      className="job-row building-row pl-2"
      style={style}
      display="flex"
      onClick={() => clickBuilding(building.id)}
      alignItems="center"
    >
      {building.shown_name}

      <div style={{ fontSize: 13 }}>{shorts.map(s => renderSpans(stats[s], s))}</div>
    </Box>
  );
};

export default BuildingRow;
