import React from 'react';
import { store } from '../constants';
import { isPresent } from '../util';
import Link from '@material-ui/core/Link';

const { useStoreState } = store.getHelpers('building_detail');

const BuildingDetail = () => {
  const { building } = useStoreState();

  if (!building) {
    throw new Error('no building dudetown');
  }

  return (
    <div className="p-1">
      <p>{building.notes}</p>
      {isPresent(building.gmaps_link) && (
        <Link target="_blank" rel="noopener noreferrer" href={building.gmaps_link}>
          {building.gmaps_link}
        </Link>
      )}
    </div>
  );
};

export default BuildingDetail;
